import zod from 'zod';
import React, { useContext, useEffect } from 'react';
import { useAsyncFn } from 'react-use';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { Buffer } from 'buffer';
import type { TFunction } from 'i18next';

import axios from 'axios';
import Flex from '~/components/shared/shaping/Flex';
import { Form } from '~/components/shared/forms/Form';
import Input from '~/components/shared/forms/Input';
import Button from '~/components/shared/buttons/Button';
import { Text, Title } from '~/components/shared/typography';

import { SSOLoginContext } from './context';
import ResendOTPButton from './ResendOTPButton';
import { SSO_LOGIN_LINK } from '~/config/routes';

const otpFormSchema = (t: TFunction) =>
  zod.object({
    otp: zod.string().length(6, t('forms.validations.invalid_otp', { length: 6 })),
  });

type OTPFormFields = zod.infer<ReturnType<typeof otpFormSchema>>;

const OTPForm: FunctionComponent = () => {
  const { t } = useTranslation();
  const { slug } = useParams();
  const { email, requestData } = useContext(SSOLoginContext);
  const navigate = useNavigate();
  const form = useForm<OTPFormFields>({
    resolver: zodResolver(otpFormSchema(t)),
  });

  const { setError } = form;

  const [{ loading }, onSubmit] = useAsyncFn(async (fields: OTPFormFields) => {
    if (!requestData) {
      setError('otp', { message: t('errors.fail_to_parse_saml_request') });
      return;
    }
    try {
      const { data } = await axios.post(`/sso/${slug}/login`, {
        type: 'otp',
        email,
        otp: fields.otp,
        SAMLRequest: {
          issuer: requestData.issuer,
          requestId: requestData.id,
        },
      });

      const formElement = document.createElement('form');
      formElement.action = requestData.callbackURL;
      formElement.method = 'POST';
      formElement.style.display = 'none';

      const inputElement = document.createElement('input');
      inputElement.type = 'hidden';
      inputElement.name = 'SAMLResponse';
      inputElement.value = Buffer.from(data).toString('base64');

      formElement.appendChild(inputElement);
      document.body.appendChild(formElement);
      formElement.submit();
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const error = err?.response?.data;
        if (typeof error === 'string') {
          setError('otp', {
            message: error
              .split(';')
              .map(r =>
                t(`errors.validate_otp.${r.toLowerCase()}`, { defaultValue: t('errors.fail_to_send_otp_request') }),
              )
              .join(', '),
          });
          return;
        }
      }
      setError('otp', { message: t('errors.fail_to_validate_otp_request') });
    }
  });

  useEffect(() => {
    if (slug && !email) {
      navigate(SSO_LOGIN_LINK(slug));
    }
  }, [email, slug, navigate]);

  return (
    <Flex direction="column" className="pri-mt-2" gap={7}>
      <Flex direction="column" gap={1}>
        <Title level={4}>{t('forms.titles.otp')}</Title>
        <Text weight="light" variant="muted" noWrap={false}>
          {t('forms.subtitles.otp', { email })}
        </Text>
      </Flex>
      <Flex justify="between" align="center" className="pri-mt-1" gap={2}>
        <Text maxLength={30} weight="light">
          {email}
        </Text>
        <ResendOTPButton />
      </Flex>
      <Form form={form} onSubmit={form.handleSubmit(onSubmit)}>
        <Input
          name="otp"
          label={t('forms.labels.otp')}
          register={form.register}
          errors={form.formState.errors}
          required
        />
        <Flex className="pri-otp-form-login-button" gap={2} direction="column" fullWidth>
          <Button disabled={loading} type="submit" size="xl" fullWidth>
            {t('buttons.login')}
          </Button>
          {!!slug && (
            <Button size="xl" className="flex-1" fullWidth variant="dark-no-outline">
              <Link to={SSO_LOGIN_LINK(slug)}>
                <Text weight="light">{t('buttons.back')}</Text>
              </Link>
            </Button>
          )}
        </Flex>
      </Form>
    </Flex>
  );
};
export default OTPForm;
