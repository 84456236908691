import { useContext, useMemo } from 'react';

import { SearchContext } from './context';
import type { QueryParams } from '~/providers/search/useQueryParams';

export const useSearch = (): [QueryParams, (search: QueryParams) => void, boolean] => {
  const { search, setSearch } = useContext(SearchContext);

  const hasSearch = useMemo(() => Object.values(search).some(value => !!value), [search]);

  return [search, setSearch, hasSearch];
};
