import React, { useContext } from 'react';
import Loader from '~/components/shared/Loader';
import { Steps } from '~/components/shared/Stepper';
import { DevicePlatform } from '~/graphql/schema';
import { EmployeeEnrollmentStepperContext } from './context';
import ConfirmSerialstep from './_steps/ConfirmSerialStep/index';
import DownloadInstallerStep from './_steps/DownloadInstallerStep';
import SelectDeviceStep from './_steps/SelectDeviceStep';
import UpdateDeviceStep from './_steps/UpdateDeviceStep';

const EmployeeEnrollmentStepperContent: FunctionComponent<{ deviceId?: string | null }> = ({ deviceId }) => {
  const { contextData, toEnrollDevices, loading, setupCalled, persistUpdateStep } = useContext(
    EmployeeEnrollmentStepperContext,
  );

  const hasDevicesToEnroll = toEnrollDevices.length > 0;

  const shouldUpdatePlatform = contextData?.selectedDevice?.platform === DevicePlatform.Unknown;
  const hasSelectedDevice = !!contextData.selectedDevice;

  if ((deviceId && !contextData.selectedDevice) || (setupCalled && loading)) return <Loader />;

  return (
    <Steps>
      {hasDevicesToEnroll && !deviceId ? <SelectDeviceStep /> : undefined}
      {shouldUpdatePlatform || persistUpdateStep ? <UpdateDeviceStep /> : undefined}
      {hasSelectedDevice ? <ConfirmSerialstep /> : undefined}
      <DownloadInstallerStep />
    </Steps>
  );
};

export default EmployeeEnrollmentStepperContent;
