import React from 'react';

const TrashIcon: SvgComponent = ({ className, width = 24, color = 'black' }) => (
  <svg className={className} width={width} height={width} viewBox="0 0 32 32">
    <path d="M12 12H14V24H12V12ZM18 12H20V24H18V12Z" fill={color} />
    <path
      d="M4 6V8H6V28C6 28.5304 6.21071 29.0391 6.58579 29.4142C6.96086 29.7893 7.46957 30 8 30H24C24.5304 30 25.0391 29.7893 25.4142 29.4142C25.7893 29.0391 26 28.5304 26 28V8H28V6H4ZM8 28V8H24V28H8ZM12 2H20V4H12V2Z"
      fill={color}
    />
  </svg>
);

export default TrashIcon;
