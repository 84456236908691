import type { FC, ReactElement } from 'react';

import { useCompany } from '~/hooks/useCompany';

type HasProvisioningPlanProps = { children: ReactElement | null };

export const HasProvisioningPlan: FC<HasProvisioningPlanProps> = ({ children }) => {
  const company = useCompany();

  if (!company || !company.hasProvisioningPlan) {
    return null;
  }

  return children;
};
