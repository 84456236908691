import { useCallback, useEffect, useMemo } from 'react';
import { ok, refuse } from '@primo/operation-result';
import type { CreateOrUpdateCompanyMutation } from '~/graphql/schema';
import { useCreateOrUpdateCompanyMutation } from '~/graphql/schema';
import type { UseQueryOptions } from '~/hooks/queries/types';

type UseCreateOrUpdateCompanyVariables = {
  name: string;
  size?: number | null;
  eulaAccepted: boolean;
  activated: boolean;
};

const useMutationCreateOrUpdateCompany = (queryOptions: UseQueryOptions<UseCreateOrUpdateCompanyVariables> = {}) => {
  const serializeCreateOrUpdateCompanyData = useCallback((data: CreateOrUpdateCompanyMutation | undefined | null) => {
    if (data?.createOrUpdateCompany?.__typename === 'MutationCreateOrUpdateCompanySuccess') {
      return ok(data.createOrUpdateCompany.data);
    }
    if (data?.createOrUpdateCompany?.__typename === 'MutationCreateOrUpdateCompanyError')
      return refuse(data.createOrUpdateCompany.reasons);
    return refuse(['Unknown error']);
  }, []);

  const [
    mutation,
    { data: createOrUpdateCompanyData, loading: createOrUpdateCompanyLoading, called: createOrUpdateCompanyCalled },
  ] = useCreateOrUpdateCompanyMutation();

  const createOrUpdateCompanyResult = useMemo(
    () => serializeCreateOrUpdateCompanyData(createOrUpdateCompanyData),
    [serializeCreateOrUpdateCompanyData, createOrUpdateCompanyData],
  );

  const createOrUpdateCompany = useCallback(
    async (variables: UseCreateOrUpdateCompanyVariables) => {
      const result = await mutation({
        variables,
      });
      return serializeCreateOrUpdateCompanyData(result.data);
    },
    [mutation, serializeCreateOrUpdateCompanyData],
  );

  useEffect(() => {
    if (queryOptions.runOnInit && !createOrUpdateCompanyCalled) createOrUpdateCompany({ ...queryOptions });
  }, [createOrUpdateCompany, queryOptions, createOrUpdateCompanyCalled]);

  return useMemo(
    () => ({
      createOrUpdateCompany,
      createOrUpdateCompanyResult,
      createOrUpdateCompanyLoading,
      createOrUpdateCompanyCalled,
    }),
    [createOrUpdateCompany, createOrUpdateCompanyResult, createOrUpdateCompanyLoading, createOrUpdateCompanyCalled],
  );
};

export default useMutationCreateOrUpdateCompany;
