import React from 'react';

const TabletIcon: SvgComponent = ({ className, width = 24 }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M19 24V26H13V24H19Z" fill="black" />
    <path
      d="M25 30H7C6.46975 29.9994 5.9614 29.7885 5.58646 29.4135C5.21152 29.0386 5.00061 28.5302 5 28V4C5.00053 3.46973 5.21141 2.96133 5.58637 2.58637C5.96133 2.21141 6.46973 2.00053 7 2H25C25.5302 2.00061 26.0386 2.21152 26.4135 2.58646C26.7885 2.9614 26.9994 3.46975 27 4V28C26.9993 28.5302 26.7883 29.0385 26.4134 29.4134C26.0385 29.7883 25.5302 29.9993 25 30ZM7 4V28H25V4H7Z"
      fill="black"
    />
  </svg>
);

export default TabletIcon;
