import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { PillProps } from '~/components/shared/Pill';
import Pill from '~/components/shared/Pill';
import { EmployeeOffboardingStatus, EmployeeOnboardingStatus } from '~/graphql/schema';

type EmployeeStatusPillProps = {
  onboardingStatus?: EmployeeOnboardingStatus;
  offboardingStatus?: EmployeeOffboardingStatus;
  startDate?: Date | string | null;
  terminationDate?: Date | string | null;
};

const EmployeeStatusPill: FunctionComponent<EmployeeStatusPillProps> = ({
  className,
  onboardingStatus,
  offboardingStatus,
  terminationDate,
  startDate,
}) => {
  const { t } = useTranslation();

  const [color, status]: [PillProps['color'], string] = useMemo(() => {
    if (!onboardingStatus && !startDate && !offboardingStatus && !terminationDate) return ['blue', 'current'];

    const formattedStartDate = typeof startDate === 'string' ? new Date(startDate) : startDate;
    const formattedTerminationDate = typeof terminationDate === 'string' ? new Date(terminationDate) : terminationDate;

    const onboardingExists = !!onboardingStatus && !!formattedStartDate;

    const offboardingExists = !!offboardingStatus && !!formattedTerminationDate;
    const isOffboardingProcessed =
      offboardingExists &&
      [EmployeeOffboardingStatus.BeingProcessed, EmployeeOffboardingStatus.Closed].includes(offboardingStatus);
    const isOffboardingDatePassed =
      offboardingExists &&
      formattedTerminationDate <= new Date() &&
      (!onboardingExists || formattedTerminationDate >= formattedStartDate);

    const employeeHasLeft = isOffboardingProcessed || isOffboardingDatePassed;
    if (employeeHasLeft) return ['red', 'offboarding'];

    const isOnboardingProcessed =
      onboardingExists &&
      [EmployeeOnboardingStatus.BeingProcessed, EmployeeOnboardingStatus.Closed].includes(onboardingStatus);
    const isOnboardingDatePassed =
      onboardingExists && (!offboardingExists || formattedStartDate > formattedTerminationDate);

    const employeeHasBegun = isOnboardingProcessed && isOnboardingDatePassed;
    if (employeeHasBegun) return ['blue', 'current'];
    if (onboardingExists && !employeeHasBegun) return ['yellow', 'onboarding'];

    return ['blue', 'current'];
  }, [startDate, terminationDate, onboardingStatus, offboardingStatus]);

  return (
    <Pill className={className} color={color}>
      {t(`enums.employee_status.${status}`)}
    </Pill>
  );
};

export default EmployeeStatusPill;
