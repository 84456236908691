import React, { createContext, useCallback, useContext, useMemo, useState, useEffect } from 'react';
import { isOk } from '@primo/operation-result';
import { StepperContext } from '~/components/shared/Stepper';
import useQueryGetEmployeeDevice from '~/hooks/queries/devices/useQueryGetEmployeeDevice';
import { PrimoEmployeeContext } from '~/providers/primo/PrimoEmployeeProvider';
import type { EmployeeDevice, EnrollmentLinksResult } from '~/graphql/schema';
import { DeviceEnrollmentStatusFilter, DevicePlatform, DeviceSource } from '~/graphql/schema';
import useQueryGetEmployeeDevices from '~/hooks/queries/devices/useQueryGetEmployeeDevices';
import useMutationCreateDevice from '~/hooks/queries/devices/useMutationCreateDevice';
import type { CreateDeviceForEnrollmentProps, UpdateDeviceForEnrollmentProps } from './schema';
import useMutationUpdateDevice from '~/hooks/queries/devices/useMutationUpdateDevice';
import useQueryGetEnrollmentLinks from '~/hooks/queries/devices/useQueryGetEnrollmentLinks';

type ContextDataDevice = Partial<
  Pick<EmployeeDevice, 'id' | 'name' | 'type' | 'platform' | 'serialNumber' | 'enrollmentStatus'>
>;

export type EmployeeEnrollmentStepperContextData = {
  selectedDevice?: ContextDataDevice | null;
};

export type EmployeeEnrollmentStepperContextProps = {
  loading: boolean;
  setupCalled: boolean;
  refreshEnrollmentLinksLoading: boolean;
  createDeviceLoading?: boolean;
  enrolledDevices: EmployeeDevice[];
  toEnrollDevices: EmployeeDevice[];
  contextData: EmployeeEnrollmentStepperContextData;
  enrollmentLinks: EnrollmentLinksResult | null;
  persistUpdateStep: boolean;
  setPersistUpdateState: (value: boolean) => void;
  refreshEnrollmentLinks: () => Promise<void>;
  updateDeviceForEnrollment: (variables: UpdateDeviceForEnrollmentProps) => Promise<boolean>;
  createDeviceForEnrollment: (variables: CreateDeviceForEnrollmentProps) => Promise<
    | {
        readonly type: 'refusal';
        readonly reason: string[];
      }
    | {
        readonly type: 'success';
        readonly data: string;
      }
    | null
  >;
  setContextData: (contextData: EmployeeEnrollmentStepperContextData) => void;
  onFinish: (contextData?: EmployeeEnrollmentStepperContextData) => Promise<void>;
};

export const EmployeeEnrollmentStepperContext = createContext<EmployeeEnrollmentStepperContextProps>({
  loading: false,
  refreshEnrollmentLinksLoading: false,
  setupCalled: false,
  contextData: {},
  toEnrollDevices: [],
  enrolledDevices: [],
  enrollmentLinks: null,
  createDeviceLoading: false,
  persistUpdateStep: false,
  setPersistUpdateState: () => {},
  updateDeviceForEnrollment: async () => false,
  refreshEnrollmentLinks: async () => {},
  createDeviceForEnrollment: async () => null,
  setContextData: () => {},
  onFinish: async () => {},
});

export const EmployeeEnrollmentStepperProvider: FunctionComponent<{ deviceId?: string | null }> = ({
  children,
  deviceId,
}) => {
  const { company, employee } = useContext(PrimoEmployeeContext);
  const { toggleLoading, onClose } = useContext(StepperContext);
  const [persistUpdateStep, setPersistUpdateState] = useState<boolean>(false);

  const mdmPlatforms = company?.mdmPlatforms;

  const [enrollmentLinks, setEnrollmentLinks] = useState<EnrollmentLinksResult | null>(null);
  const [contextData, setContextData] = useState<EmployeeEnrollmentStepperContextData>({});

  const { getEmployeeDevice, deviceResult } = useQueryGetEmployeeDevice({
    fetchPolicy: 'no-cache',
  });

  const { getEnrollmentLinks, enrollmentLinksLoading, enrollmentLinksResult } = useQueryGetEnrollmentLinks({
    runOnInit: !!(company?.id || employee?.companyId),
    employeeId: employee?.id || '',
    companyId: company?.id || employee?.companyId || '',
    inventory: false,
  });

  const { createDevice, createDeviceLoading } = useMutationCreateDevice({
    additionalRefetchQueries: ['GetEmployeeDevices', 'GetEmployeeDevice'],
  });

  const { updateDevice } = useMutationUpdateDevice({
    additionalRefetchQueries: ['GetEmployeeDevices', 'GetEmployeeDevice'],
  });

  const { employeeDevicesResult, employeeDevicesLoading, employeeDevicesCalled } = useQueryGetEmployeeDevices({
    fetchPolicy: 'no-cache',
    runOnInit: true,
  });

  const loading = employeeDevicesLoading;
  const setupCalled = employeeDevicesCalled;

  useEffect(() => {
    if (company?.id && employee?.id) {
      getEnrollmentLinks({ companyId: company.id, employeeId: employee?.id, inventory: false });
    }
  }, [getEnrollmentLinks, company, employee]);

  useEffect(() => {
    if (deviceId) {
      getEmployeeDevice({ deviceId });
    }
  }, [getEmployeeDevice, deviceId]);

  useEffect(() => {
    if (isOk(deviceResult)) {
      setContextData({ selectedDevice: deviceResult.data });
    }
  }, [deviceResult]);

  useEffect(() => {
    if (isOk(enrollmentLinksResult)) {
      setEnrollmentLinks(enrollmentLinksResult.data);
    }
  }, [enrollmentLinksResult]);

  const updateDeviceForEnrollment = useCallback(
    async (variables: UpdateDeviceForEnrollmentProps) => {
      if (!contextData?.selectedDevice?.id) return false;

      const result = await updateDevice({
        deviceId: contextData.selectedDevice.id,
        ...variables,
      });

      return isOk(result);
    },
    [contextData.selectedDevice, updateDevice],
  );

  const refreshEnrollmentLinks = useCallback(async (): Promise<void> => {
    if (!company?.id || !employee?.id) return;

    await getEnrollmentLinks({ companyId: company.id, employeeId: employee?.id, inventory: false });
  }, [getEnrollmentLinks, employee, company]);

  const createDeviceForEnrollment = useCallback(
    async (variables: CreateDeviceForEnrollmentProps) => {
      if (!company?.id) return null;

      const result = await createDevice({ ...variables, source: DeviceSource.CockpitEmployee, companyId: company?.id });

      return result;
    },
    [createDevice, company],
  );

  const devices = useMemo(() => {
    if (isOk(employeeDevicesResult)) {
      return employeeDevicesResult.data.list;
    }

    return [];
  }, [employeeDevicesResult]);

  const enrolledDevices = useMemo(
    () => devices.filter(device => device.enrollmentStatus === DeviceEnrollmentStatusFilter.Enrolled),
    [devices],
  );

  const toEnrollDevices = useMemo(
    () =>
      devices.filter(
        device =>
          (device.enrollmentStatus &&
            [DeviceEnrollmentStatusFilter.NonEnrolled, DeviceEnrollmentStatusFilter.InProgress].includes(
              device.enrollmentStatus,
            ) &&
            mdmPlatforms?.includes(device.platform)) ||
          device.platform === DevicePlatform.Unknown,
      ),
    [devices, mdmPlatforms],
  );

  const onFinish = useCallback(async () => {
    toggleLoading();

    onClose();
  }, [onClose, toggleLoading]);

  const value = useMemo(
    () => ({
      loading,
      contextData,
      refreshEnrollmentLinksLoading: enrollmentLinksLoading,
      enrolledDevices,
      toEnrollDevices,
      setupCalled,
      createDeviceLoading,
      enrollmentLinks,
      persistUpdateStep,
      setPersistUpdateState,
      setContextData,
      updateDeviceForEnrollment,
      refreshEnrollmentLinks,
      onFinish,
      createDeviceForEnrollment,
    }),
    [
      loading,
      setupCalled,
      toEnrollDevices,
      enrolledDevices,
      contextData,
      createDeviceLoading,
      enrollmentLinksLoading,
      enrollmentLinks,
      persistUpdateStep,
      setPersistUpdateState,
      setContextData,
      onFinish,
      createDeviceForEnrollment,
      updateDeviceForEnrollment,
      refreshEnrollmentLinks,
    ],
  );

  return (
    <EmployeeEnrollmentStepperContext.Provider value={value}>{children}</EmployeeEnrollmentStepperContext.Provider>
  );
};
