const fixedOrder = ['screen_size', 'processor', 'ram', 'storage'];

type Property = {
  key: string;
  [p: string]: string | Maybe<string>;
};

export function sortProperties<P extends Property>(properties: P[], maxDisplay = 4): P[] {
  const sortedProperties = new Set<P>();

  fixedOrder.forEach(property => {
    const found = properties.find(p => p.key === property);
    if (found) {
      sortedProperties.add(found);
    }
  });

  properties.forEach(property => {
    if (!sortedProperties.has(property)) {
      sortedProperties.add(property);
    }
  });

  return [...sortedProperties].slice(0, maxDisplay);
}
