import type { FC, ReactElement } from 'react';

import type { Device, DevicePlatform } from '~/graphql/schema';

type MatchPlatformProps = {
  children: ReactElement;
  platforms: DevicePlatform[];
  device: Pick<Device, 'platform'>;
  exclude?: true;
};

export const MatchPlatform: FC<MatchPlatformProps> = ({ children, exclude = false, device, platforms }) => {
  if (exclude === platforms.includes(device.platform)) {
    return null;
  }
  return children;
};
