import cn from 'classnames';
import type { ReactNode } from 'react';
import React, { useMemo, useState } from 'react';
import type { FieldError, FieldErrors, FieldValues, Path } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { CSSObjectWithLabel } from 'react-select';
import ReactSelect from 'react-select';
import type { InputActionMeta } from 'react-select/dist/declarations/src/types';

import Message from '~/components/shared/Message';
import Flex from '~/components/shared/shaping/Flex';
import { Text } from '~/components/shared/typography';

import type { ControlledProps, Option, UncontrolledProps, Value } from './types';
import { useHandleOnChange } from './useHandleOnChange';
import { useHandleOnKeyDown } from './useHandleOnKeyDown';

import SelectedOptionsPill from '~/components/shared/forms/Select/SelectedOptionPill';
import { useComputeValidOptions } from '~/components/shared/forms/Select/useComputeValidOptions';
import './index.scss';

// TODO check if we can remove maybe here
export type SelectProps<FV extends FieldValues, Val extends Value> = (
  | Maybe<ControlledProps<FV, Val>>
  | Maybe<UncontrolledProps<Val>>
) & {
  label?: string;
  options: Val[];
  getOptionLabel?: (option: Val) => string;
  getOptionValue?: (option?: Val) => string | undefined;
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
  defaultValue?: Val | null;
  isInvalid?: boolean;
  isSearchable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  menuIsOpen?: boolean;
  helperText?: string | ReactNode;
  onFocus?: () => void;
  onBlur?: () => void;
  placeholder?: string;
  className?: string;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  isMulti?: boolean;
  maxOptions?: number;
  acceptCustomValues?: boolean;
  name: Path<FV>;
  error?: FieldError;
  errors?: FieldErrors;
  small?: boolean;
  dark?: boolean;
  labelHelper?: string;
};

const Select = <FV extends FieldValues, Val extends Value>({
  label,
  isInvalid,
  onInputChange,
  onChange,
  options,
  getOptionLabel,
  getOptionValue,
  defaultValue,
  isDisabled = false,
  isSearchable = true,
  className,
  menuPlacement = 'auto',
  isMulti = false,
  acceptCustomValues = false,
  helperText = null,
  name,
  error,
  errors,
  placeholder,
  maxOptions,
  small,
  dark,
  labelHelper,
  ...props
}: SelectProps<FV, Val>) => {
  const { t } = useTranslation();
  const [customOptions, setCustomOptions] = useState<Option<Val>[]>([]);
  const [selectedOption, setSelectedOption] = useState<Val | null>(null);

  const message = (error?.message || errors?.[name]?.message)?.toString() || '';

  const validOptions = useComputeValidOptions(customOptions, options, selectedOption, getOptionLabel);
  const handleOnChange = useHandleOnChange(
    customOptions,
    setCustomOptions,
    setSelectedOption,
    getOptionValue,
    onChange,
    maxOptions,
  );
  const handleOnKeyDown = useHandleOnKeyDown(customOptions, setCustomOptions, acceptCustomValues);

  const computedClassName = cn(className, 'pri-select', {
    'is-invalid': isInvalid,
    'pri-small': small,
    'pri-dark': dark,
  });

  const selectComputedProps = useMemo(
    () => ({
      onInputChange,
      placeholder,
      isSearchable,
      defaultValue: validOptions.find(opt => opt.value === defaultValue),
      classNamePrefix: 'pri-select',
      options: validOptions,
      isDisabled,
      styles: {
        menuPortal: (base: CSSObjectWithLabel) => ({ ...base, zIndex: 9999 }),
      },
      menuPortalTarget: document.body,
      closeMenuOnScroll: (e: Event) => {
        const { classList } = e.target as HTMLElement;
        return !classList.contains('pri-select__menu-list');
      },
      hideSelectedOptions: false,
      isMulti,
      controlShouldRenderValue: !isMulti,
    }),
    [defaultValue, isDisabled, placeholder, isSearchable, validOptions, onInputChange, isMulti],
  );

  return (
    <Flex direction="column" gap={2} className={computedClassName}>
      <Flex direction="row" gap={1} align="baseline">
        {label && <Text weight="light">{label}</Text>}
        {label && labelHelper && (
          <Text weight="light" italic size="sm">
            ({labelHelper})
          </Text>
        )}
      </Flex>
      {'control' in props ? (
        <Controller
          control={props.control}
          name={name}
          render={({ field: { value, onChange: controllerOnChange, ref, ...rest } }) => {
            const selectedOptions = Array.isArray(value)
              ? validOptions.filter(opt => value.includes(getOptionValue?.(opt.value) || opt.value))
              : validOptions.find(opt => (getOptionValue?.(opt.value) || opt.value) === value);
            return (
              <>
                <Flex direction="column">
                  <ReactSelect
                    onFocus={e => e.stopPropagation()}
                    {...selectComputedProps}
                    {...rest}
                    onChange={e => handleOnChange(controllerOnChange, e)}
                    onKeyDown={e => handleOnKeyDown(controllerOnChange, e, selectedOptions)}
                    defaultValue={selectedOptions || validOptions[0]}
                    value={selectedOptions || value || null}
                    ref={ref}
                    menuPosition={menuPlacement !== 'auto' ? 'absolute' : 'fixed'}
                    menuPlacement={menuPlacement}
                    noOptionsMessage={() =>
                      acceptCustomValues ? t('forms.labels.press_enter') : t('forms.labels.no_option')
                    }
                  />
                  {typeof helperText === 'string' ? <Text weight="light">{helperText}</Text> : helperText}
                </Flex>
                <SelectedOptionsPill
                  selectedOptions={selectedOptions}
                  customOptions={customOptions}
                  setCustomOptions={setCustomOptions}
                  onChange={controllerOnChange}
                />
                {message && <Message variant="danger" text={message} className="pri-px-3" />}
              </>
            );
          }}
        />
      ) : (
        <>
          <ReactSelect {...selectComputedProps} menuPosition="fixed" />
          {message && <Message variant="danger" text={message} className="pri-px-3" />}
        </>
      )}
    </Flex>
  );
};

export default Select;
