import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import google from '~/assets/google_g.svg';
import { PrimoAdminContext } from '~/providers/primo/PrimoAdminProvider';
import type { Connection } from '~/pages/Login/context';
import { LoginContext } from '~/pages/Login/context';
import Button from '~/components/shared/buttons/Button';
import Flex from '~/components/shared/shaping/Flex';
import { Text } from '~/components/shared/typography';

type GoogleLoginPartProps = {
  connection: Connection;
};

const GoogleLoginPart: FunctionComponent<GoogleLoginPartProps> = ({ connection }) => {
  const { t } = useTranslation();
  const { organizationId, invitation, redirectUri } = useContext(LoginContext);
  const { webAuth } = useContext(PrimoAdminContext);
  const [params] = useSearchParams();

  const onLogin = useCallback(() => {
    webAuth.authorize({
      organization: organizationId,
      connection: connection.name,
      redirectUri: `${redirectUri}?${params.toString()}`,
      invitation,
    });
  }, [connection, organizationId, invitation, redirectUri, params, webAuth]);

  return (
    <Flex>
      <Button onClick={onLogin} className="pri-flex-1" variant="dark-outline" size="xl" fullWidth>
        <img className="pri-mr-3" src={google} alt="Google" height="16" />
        <Text>{invitation ? t('buttons.socials.google_sign_up') : t('buttons.socials.google')}</Text>
      </Button>
    </Flex>
  );
};

export default GoogleLoginPart;
