import { ApolloClient, ApolloProvider, from, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import React, { createContext, useMemo, useState } from 'react';

import type { GraphQLFormattedError } from 'graphql';
import { API_PATH } from '~/config/env';
import { useTokenValidationMutation } from '~/graphql/schema';

export type ApolloAppContextProps = {
  setToken: (token: string | null) => void;
  hasAuthenticatedClient: boolean;
  graphQlErrors: ReadonlyArray<GraphQLFormattedError> | undefined;
};

export const ApolloAppContext = createContext<ApolloAppContextProps>({
  setToken: () => undefined,
  hasAuthenticatedClient: false,
  graphQlErrors: undefined,
});

const getClient = (
  headers?: Record<string, string> | undefined,
  setGraphQlErrors?: (errors: ReadonlyArray<GraphQLFormattedError> | undefined) => void,
) => {
  const link = new HttpLink({ uri: API_PATH, headers });
  const errorLink = onError(({ graphQLErrors, operation }) => {
    /**
     * include `useTokenValidationMutation` in the condition to change the `operationName` if mutation changed
     * this is required to avoid infinite loop if TokenValidation failed (because of token validation graphql fails)
     */
    if (operation.operationName !== 'TokenValidation' && !!useTokenValidationMutation) {
      setGraphQlErrors?.(graphQLErrors);
    }
  });
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([errorLink, link]),
  });

  return client;
};

const ApolloAppProvider: FunctionComponent = ({ children }) => {
  const [graphQlErrors, setGraphQlErrors] = useState<ReadonlyArray<GraphQLFormattedError> | undefined>();
  const [token, setToken] = useState<string | null>(null);

  /** Default apollo client without authorization headers */
  const unauthenticatedClient = useMemo(() => getClient(), []);

  /** Create an apollo client with authorization headers if token is provided */
  const authenticatedClient = useMemo(() => {
    if (token) {
      return getClient({ authorization: `Bearer ${token}` }, setGraphQlErrors);
    }
    return undefined;
  }, [token, setGraphQlErrors]);

  const client = useMemo(
    () => authenticatedClient || unauthenticatedClient,
    [unauthenticatedClient, authenticatedClient],
  );
  const value = useMemo(
    () => ({
      setToken,
      hasAuthenticatedClient: !!authenticatedClient,
      graphQlErrors,
    }),
    [authenticatedClient, graphQlErrors, setToken],
  );

  return (
    <ApolloAppContext.Provider value={value}>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </ApolloAppContext.Provider>
  );
};

export default ApolloAppProvider;
