import moment from 'moment';
import * as zod from 'zod';
import type { TFunction } from 'i18next';

export const schema = (t: TFunction, terminationDate?: Date | null, domainsToValidate?: string[]) =>
  zod.object({
    firstName: zod.string().min(1, t('forms.validations.required')),
    lastName: zod.string().min(1, t('forms.validations.required')),
    email: zod
      .string()
      .min(1, t('forms.validations.required'))
      .email(t('forms.validations.invalid_email'))
      .refine(
        email => (domainsToValidate?.length ? !!domainsToValidate?.some(domain => email.endsWith(domain)) : true),
        {
          message: t('forms.validations.invalid_email_domain'),
        },
      ),
    personalEmail: zod.string().email(t('forms.validations.invalid_email')).optional().or(zod.literal('')),
    phone: zod.string().nullish(),
    picture: zod.string().optional(),
    startDate: zod
      .string()
      .min(1, t('forms.validations.required'))
      .refine(
        startDateToRefine => (startDateToRefine ? moment(startDateToRefine).isValid() : true),
        t('forms.validations.invalid_date_format'),
      )
      .refine(
        startDateToRefine =>
          !terminationDate || moment(startDateToRefine).isAfter(moment(terminationDate).add(1, 'day')),
        t('forms.validations.new_contract_start_date', {
          terminationDate: moment(terminationDate).format('DD/MM/YYYY'),
        }),
      ),
    automaticEnrollment: zod.boolean().optional(),
    sendToPersonalEmail: zod.boolean().optional(),
  });

export type Fields = zod.infer<ReturnType<typeof schema>>;
