import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import IntegrationCard from '~/components/featured/cards/IntegrationCard';
import Flex from '~/components/shared/shaping/Flex';
import { Step, StepperContext } from '~/components/shared/Stepper';
import { Text, Title } from '~/components/shared/typography';

import AddBackupEmailModal from '~/features/modals/AddBackupEmail';
import type { CompanyExternalIntegration } from '~/graphql/schema';
import { useResolver } from '~/hooks/queries/useResolver';
import { OnboardingStepperContext } from '../context';

const ChooseAppsStep: FunctionComponent<{ companyId: string }> = ({ companyId }) => {
  const { t } = useTranslation();
  const { contextData, setContextData, onFinish } = useContext(OnboardingStepperContext);
  const [showAddRequireBackupEmail, setShowAddRequireBackupEmail] = useState<string | null>(null);
  const { setFooterProps } = useContext(StepperContext);
  const form = useForm({
    defaultValues: { apps: contextData.apps },
  });

  const { result: companyExternalIntegrations } = useResolver('useGetCompanyExternalIntegrationsLazyQuery', {
    runOnInit: true,
    variables: {
      enabledOnly: true,
      companyId,
    },
  });

  const apps = form.watch('apps');

  const totalPrice = useMemo(
    () => contextData.equipments?.catalog.reduce((acc, { item, quantity }) => acc + item.priceNoVAT * quantity, 0) || 0,
    [contextData.equipments?.catalog],
  );

  const onNext = useCallback(async () => {
    const newContextData = { ...contextData, apps };
    setContextData(newContextData);
    await onFinish(newContextData);
    return false;
  }, [contextData, apps, setContextData, onFinish]);

  const onIntegrationCardClick = useCallback(
    (integration: CompanyExternalIntegration) => {
      if (!integration.externalIntegration) {
        return;
      }
      const appsSet = new Set(apps || []);
      const { name } = integration.externalIntegration;

      if (appsSet.has(name)) {
        appsSet.delete(name);
      } else {
        if (integration.requireBackupEmail) {
          setShowAddRequireBackupEmail(integration.id);
          return;
        }
        appsSet.add(name);
      }
      form.setValue('apps', Array.from(appsSet));
    },
    [apps, form, setShowAddRequireBackupEmail],
  );

  const onAddBackupEmail = useCallback(
    async (backupEmail: string) => {
      const integration = (companyExternalIntegrations?.getCompanyExternalIntegrations || []).find(
        ({ id }) => id === showAddRequireBackupEmail,
      );
      if (!integration || !integration.externalIntegration?.name) return;
      const newContextData = { ...contextData, employee: { ...contextData.employee, personalEmail: backupEmail } };
      setContextData(newContextData);
      form.setValue('apps', (form.getValues('apps') || []).concat(integration.externalIntegration.name));
      setShowAddRequireBackupEmail(null);
    },
    [companyExternalIntegrations, contextData, setContextData, form, showAddRequireBackupEmail],
  );

  useEffect(() => {
    setFooterProps({
      onNext,
      nextLabel:
        totalPrice > 0 ? t('steppers.onboarding.confirm_and_place_order') : t('steppers.onboarding.confirm_onboarding'),
    });
  }, [totalPrice, setFooterProps, onNext, t]);

  return (
    <Step size="lg">
      <Flex className="pri-mb-9" direction="column" gap={2}>
        <Title level={2}>
          {t('steppers.onboarding.connect_employee_to_your_apps', { firstName: contextData.employee?.firstName ?? '' })}
        </Title>
        <Text weight="light">{t('steppers.onboarding.connect_employee_to_your_apps_description')}</Text>
      </Flex>
      <Flex fullWidth direction="row" wrap="wrap" gap={4}>
        {companyExternalIntegrations?.getCompanyExternalIntegrations?.map(integration =>
          integration.externalIntegration ? (
            <IntegrationCard
              key={integration.externalIntegration.name}
              selected={(apps || []).includes(integration.externalIntegration.name)}
              checkbox={{
                name: 'apps',
                value: integration.externalIntegration.name,
                register: form.register,
              }}
              companyExternalIntegration={integration}
              onClick={e => {
                e.preventDefault();
                onIntegrationCardClick(integration);
              }}
            />
          ) : null,
        )}
      </Flex>
      {showAddRequireBackupEmail && (
        <AddBackupEmailModal
          onClose={() => setShowAddRequireBackupEmail(null)}
          onConfirm={onAddBackupEmail}
          employeeFirstName={contextData.employee?.firstName || ''}
          defaultValue={contextData.employee?.personalEmail}
        />
      )}
    </Step>
  );
};

export default ChooseAppsStep;
