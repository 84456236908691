import React, { useContext, useMemo } from 'react';
import { PrimoAdminContext } from '~/providers/primo/PrimoAdminProvider';
import AdminNavSection from '~/features/admin/AdminNavSection';
import { NavPanel } from '~/features/layout/NavPanels/admin';
import Bloc from '~/components/shared/shaping/Bloc';
import Flex from '~/components/shared/shaping/Flex';
import InitialSetupStepper from '~/features/steppers/InitialSetupStepper';

import './index.scss';

const PrimoLayout: FunctionComponent = ({ children }) => {
  const { employee, companyInitialSetup, isLoading } = useContext(PrimoAdminContext);

  const isCompanyFullySetup = useMemo(
    () => !companyInitialSetup || Object.values(companyInitialSetup).every(value => value),
    [companyInitialSetup],
  );

  const isInternalEmployee = useMemo(() => employee?.isInternalEmployee, [employee]);
  const isEulaAccepted = useMemo(() => !!companyInitialSetup?.eulaAccepted, [companyInitialSetup]);

  if (isLoading) return null;

  return isCompanyFullySetup || isInternalEmployee || isEulaAccepted ? (
    <>
      <Flex direction="row" justify="start" align="stretch" fullHeight>
        <NavPanel />
        <Bloc backgroundColor="white" className="pri-main-section pri-py-9 pri-px-11 pri-w-100 pri-vh-100" scrollable>
          {children}
        </Bloc>
      </Flex>
      <AdminNavSection />
    </>
  ) : (
    <InitialSetupStepper onClose={() => {}} />
  );
};
export default PrimoLayout;
