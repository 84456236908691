import { useCallback, useEffect, useMemo } from 'react';
import { ok, refuse } from '@primo/operation-result';
import type { Address, UpdateEmployeeMutation } from '~/graphql/schema';
import { useUpdateEmployeeMutation } from '~/graphql/schema';
import type { UseQueryOptions } from '~/hooks/queries/types';

type UseMutationUpdateEmployeeVariables = {
  employeeId: string;
  address: Address;
};

const useMutationUpdateEmployee = (queryOptions: UseQueryOptions<UseMutationUpdateEmployeeVariables> = {}) => {
  const serializeOffboardingData = useCallback((data: UpdateEmployeeMutation | undefined | null) => {
    if (data?.updateEmployee?.__typename === 'MutationUpdateEmployeeSuccess') {
      return ok(data.updateEmployee.data);
    }
    if (data?.updateEmployee?.__typename === 'MutationUpdateEmployeeError') return refuse(data.updateEmployee.reasons);
    return refuse(['Unknown error']);
  }, []);

  const [mutation, { data: updateEmployeeData, loading: updateEmployeeLoading, called: updateEmployeeCalled }] =
    useUpdateEmployeeMutation({
      refetchQueries: ['GetEmployeePage', 'GetViewEmployees'],
    });

  const updateEmployeeResult = useMemo(
    () => serializeOffboardingData(updateEmployeeData),
    [serializeOffboardingData, updateEmployeeData],
  );

  const updateEmployee = useCallback(
    async (variables: UseMutationUpdateEmployeeVariables) => {
      const result = await mutation({
        variables: {
          employeeId: variables.employeeId,
          data: {
            address: variables.address,
          },
        },
      });
      return serializeOffboardingData(result.data);
    },
    [mutation, serializeOffboardingData],
  );

  useEffect(() => {
    if (queryOptions.runOnInit && !updateEmployeeCalled) updateEmployee({ ...queryOptions });
  }, [updateEmployee, queryOptions, updateEmployeeCalled]);

  return useMemo(
    () => ({
      updateEmployee,
      updateEmployeeResult,
      updateEmployeeLoading,
      updateEmployeeCalled,
    }),
    [updateEmployee, updateEmployeeResult, updateEmployeeLoading, updateEmployeeCalled],
  );
};

export default useMutationUpdateEmployee;
