import cn from 'classnames';
import type { ReactElement } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import { ClassPrefix, TabList, TabPanel, TabPanels, Tabs as CarbonTabs } from '@carbon/react';

import type { TabProps } from './Tab';
import Bloc from '../shaping/Bloc';
import { Truthy } from '~/utils/truthy';

import { DEFAULT_SEARCH } from '~/providers/search/context';
import { useSearch } from '~/providers/search/useSearch';

import './style.scss';

export * from './Tab';

type Child = ReactElement<TabProps> | null;

export type TabsProps = {
  children: Child | Child[];
  onChange?: (tab: string) => void;
  disableURLSearchUpdate?: true;
  fullWidth?: true;
};

const Tabs: FunctionComponent<TabsProps> = ({ className, children, disableURLSearchUpdate, fullWidth, onChange }) => {
  const [search, setSearch] = useSearch();
  const clearedChildren = (Array.isArray(children) ? (children as Child[]) : [children]).filter(Truthy);
  const childrenKeys = clearedChildren.map(child => child.props.tabKey);
  const childDefaultIndex = useMemo(() => {
    const index = childrenKeys.findIndex(k => k === search.tab);
    return index === -1 ? 0 : index;
  }, [childrenKeys, search]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(childDefaultIndex);

  const onSelectTab = useCallback(
    ({ selectedIndex }: { selectedIndex: number }) => {
      const tab = clearedChildren[selectedIndex].props;
      if (tab.onClick) return;
      setSelectedTabIndex(selectedIndex);
      onChange?.(tab.tabKey);
      if (!disableURLSearchUpdate) {
        setSearch({
          ...DEFAULT_SEARCH,
          tab: tab.tabKey,
        });
      }
    },
    [clearedChildren, disableURLSearchUpdate, onChange, setSearch],
  );

  return (
    <ClassPrefix prefix="carbon">
      <Bloc className={cn('pri-tabs', className)}>
        <CarbonTabs selectedIndex={selectedTabIndex} onChange={onSelectTab}>
          <TabList aria-label="pri-tab-list" className={cn('pri-tab-list', { '--full-width': fullWidth })}>
            {clearedChildren.map((child, index) => (
              <child.type
                {...child.props}
                selected={index === selectedTabIndex}
                key={`tab-${index.toString()}`}
                className={cn('pri-tab', child.props.className)}
              />
            ))}
          </TabList>
          <TabPanels>
            {clearedChildren.map((child, index) => (
              <TabPanel key={`tab-content-${index.toString()}`} className="pri-tab-content">
                <ClassPrefix prefix="cds">{child.props.children}</ClassPrefix>
              </TabPanel>
            ))}
          </TabPanels>
        </CarbonTabs>
      </Bloc>
    </ClassPrefix>
  );
};

export default Tabs;
