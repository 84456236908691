import React from 'react';

import Bloc from '~/components/shared/shaping/Bloc';
import type { ModalProps } from '~/components/shared/Modal';
import Modal from '~/components/shared/Modal';

import './index.scss';

type SignupCguModalProps = Pick<ModalProps, 'onClose'> & { onButtonConfirm: () => void };

export const SignupCguModal = ({ onClose, onButtonConfirm }: SignupCguModalProps) => (
  <Modal
    className="cgu-modal-custom"
    onConfirm={onButtonConfirm}
    title="PRIMO TERMS AND CONDITIONS OF SERVICES"
    onClose={onClose}
    footer={{ cancelButton: false, confirmButton: true }}
  >
    <ModalBody />
  </Modal>
);

const ModalBody = () => (
  <Bloc className="cgu-modal-content">
    <br />
    <p>Last update: 11/05/2023</p>
    <br />
    <p>
      These Terms and Conditions of Services form an agreement (the &ldquo;Agreement&rdquo;) entered between the Client
      and Clutch, a French&nbsp;soci&eacute;t&eacute; par actions simplifi&eacute;e&nbsp;with a share capital of 14
      081,50&euro;, whose registered office is located at 14 rue Beffroy, 92200 Neuilly-sur-Seine, France, registered
      before the Trade and Company Register of Nanterre under number 919 404 301 (&ldquo;Primo&rdquo;). Client and Primo
      are hereafter individually referred to as a &ldquo;Party&rdquo; and collectively as the &ldquo;Parties&rdquo;.
    </p>
    <br />
    <p>
      Primo develops and markets, through its online platform, IT asset management services in the form of devices
      procurement for business use, purchase-related services and device management security services for remote
      administration purposes. Client wishes to subscribe to the services to place devices and related services orders
      and/or security services orders, which Primo agrees subject to the terms and conditions of the Agreement.
    </p>
    <br />
    <p>
      The Services are available exclusively for professionals with reasonable IT skills to use the Platform. By
      agreeing to this Agreement, you acknowledge having read the Agreement, being a professional and being empowered to
      represent and bind the Client. If you do not or cannot be bound by this Agreement you shall not agree to this
      Agreement nor sign up to use the Platform.
    </p>
    <ol>
      <li>
        <h2>DEFINITIONS</h2>
      </li>
    </ol>
    <ul>
      <li>
        <p>
          &ldquo;Accessory&rdquo; means a Device-related accessory such as screen, mouse, keyboard, bag or other items
          available from the Catalog as identified by a brand and specifications set on the Platform.
        </p>
      </li>
      <li>
        <p>
          &ldquo;Admin&rdquo; means an employee of Client duly empowered to create and access the Client Account for use
          of the Services on Client&apos;s behalf.&nbsp;
        </p>
      </li>
      <li>
        <p>
          &ldquo;Admin ID&rdquo; means the login and personal password of an Admin necessary to sign up and log into the
          Client Account.
        </p>
      </li>
      <li>
        <p>
          &ldquo;User ID&rdquo; means the login and personal password of a User necessary to access and use the
          Platform.
        </p>
      </li>
      <li>
        <p>&ldquo;API&rdquo; means a program interface to synchronize Client&rsquo;s data to provide the Services.</p>
      </li>
      <li>
        <p>
          &ldquo;Catalog&rdquo; means a selection of Devices and Accessories available for purchase on the Platform
          through Orders.
        </p>
      </li>
      <li>
        <p>&ldquo;Client&rdquo; means the legal entity identified during sign-up to the Services.</p>
      </li>
      <li>
        <p>
          &ldquo;Client Account&rdquo; means the personal account provided to Client to access and use the Services.
        </p>
      </li>
      <li>
        <p>
          &ldquo;Client Data&rdquo; means all data and information processed by Primo regarding the Admin, Users,
          Devices and Accessories in use or in stock and associated logs to provide the Services.
        </p>
      </li>
      <li>
        <p>
          &ldquo;Device&rdquo; means a laptop, tablet, mobile phone or other electronic devices available from the
          Catalog as identified by a brand and specifications set on the Platform.
        </p>
      </li>
      <li>
        <p>
          &ldquo;Documentation&rdquo; means the description of the features of the Services and, more generally, any
          other information relating to the use of the Services available on the Platform.&nbsp;
        </p>
      </li>
      <li>
        <p>
          &ldquo;Fee&rdquo; means, as applicable, the price due for Deploy and/or for the Subscription to Configure
          services.
        </p>
      </li>
      <li>
        <p>
          &ldquo;Deploy&rdquo; means the features associated to the purchase or retrieval of Device and Accessories as
          well as inventory services.
        </p>
      </li>
      <li>
        <p>
          &ldquo;Configure&rdquo; means the features associated to remote administration and control of Devices as
          available on the Platform from a Subscription.&nbsp;
        </p>
      </li>
      <li>
        <p>&ldquo;Offboarding&rdquo; means the departure of a User as employee of Client.</p>
      </li>
      <li>
        <p>
          &ldquo;Office Hours&rdquo; means the time and day, as set on the Platform, during which Primo is available to
          provide support to Client.&nbsp;
        </p>
      </li>
      <li>
        <p>&ldquo;Onboarding&rdquo; means the arrival of a User as employee of Client.</p>
      </li>
      <li>
        <p>
          &ldquo;Order&rdquo; means the document issued by Primo describing the ordering placed by Client on the
          Platform.&nbsp;
        </p>
      </li>
      <li>
        <p>&ldquo;Personal Data&rdquo; means any personal data within the meaning of applicable law.</p>
      </li>
      <li>
        <p>
          &ldquo;Platform&rdquo; means the online platform owned and operated by Primo for the provision of Services.
        </p>
      </li>
      <li>
        <p>
          &ldquo;Services&rdquo; means together the Platform and the API being made available by Primo, the provision of
          Deploy and/or Configure services.&nbsp;
        </p>
      </li>
      <li>
        <p>
          &ldquo;Software&rdquo; means any proprietary software developed by Primo for the provision of the Platform and
          the Services.&nbsp;
        </p>
      </li>
      <li>
        <p>
          &ldquo;Subscription&rdquo; means the subscription by Client to allow the provision of Configure services for
          its internal needs in accordance with the provisions set in the Agreement. The Subscription is per Device of a
          User and includes a license to Client for use of Software and Third Party Software related to Configure as
          well as the related maintenance.&nbsp;
        </p>
      </li>
      <li>
        <p>
          &ldquo;Third Party Software&rdquo; means the Hexnode proprietary software used for the provision of
          &nbsp;Configure services.
        </p>
      </li>
      <li>
        <p>
          &ldquo;User&rdquo; means any individual employee (whether former, future or existing) of Client and who
          benefits from the Deploy and/or Configure services available to or purchased by Client.
        </p>
      </li>
      <li>
        <p>
          &ldquo;Warranty Extension&rdquo; means a contractual warranty extension for a Device pursuant to an agreement
          entered between the Device manufacturer or related entity and Client.&nbsp;
        </p>
      </li>
    </ul>
    <ol start={2}>
      <li>
        <h2>PURPOSE</h2>
      </li>
    </ol>
    <p>
      The purpose of the Agreement is to set the conditions under which Primo grants to Client the right to access the
      Platform and use the Services for Client&rsquo;s internal needs.&nbsp;
    </p>
    <ol start={3}>
      <li>
        <h2>TERM</h2>
      </li>
    </ol>
    <p>
      This Agreement will become effective on the date of signing up to the Platform, provided that the Client Account
      is successfully verified by Primo, and will remain in force for an indefinite term, unless terminated in
      accordance with this Agreement.
    </p>
    <ol start={4}>
      <li>
        <h2>ORDERING</h2>
      </li>
    </ol>
    <p>
      Services related to procurement or retrieval of Devices and Accessories as part of the Deploy services and/or
      Subscription for Configure services require the execution by Client of one or several Orders which each
      constitutes a separate agreement that incorporates all the provisions of the Agreement. Each Order is firm and
      definitive with no right of cancellation from Client. Client represents that an Order can be issued by an Admin or
      a User as duly authorized on behalf of Client. Procurement Order is a sale agreement for the transfer of ownership
      of the Device and Accessories upon delivery provided that full payment was received by Primo, to the exception of
      any Warranty Extension purchase which is a service agreement provided by the Device manufacturer or related
      entity. Retrieval Order is a service agreement for the services described in the Order. Configure Order is a
      service agreement for a Subscription for an indefinite term, unless terminated in accordance with this
      Agreement.&nbsp;
    </p>
    <br />
    <ol start={5}>
      <li>
        <h2>FINANCIAL TERMS</h2>
        <ol>
          <li>
            <p>
              Fees. The right to use the Platform and Services is granted to the Client subject to compliance with the
              Agreement and payment of the applicable Fees. The Fees are stipulated on the Platform and are
              non-refundable. The Fees include VAT and the WEEE eco tax on electronic waste where applicable. Shipping
              fees for procurement, as indicated on the Platform, may be due and payable by Client according to the rate
              in force at the time of placing the Order. The Fees are valid at the time of placing the Order, Primo
              reserves the right to revise, at any time, the Fees. Where the Fees of a Subscription Order are revised,
              Primo will send a prior notice to the Client by any means (including email and/or on the Platform) and
              such price revision will be applicable upon the effective date of the revision set by Primo for any
              current or future Order unless the Client provides termination notice for the Subscription Order before
              the entry into force of the price revision.&nbsp;
            </p>
          </li>
          <li>
            <p>
              Payment terms. Primo will invoice the Client for the applicable Fees upon Ordering. The Fees are payable
              entirely upfront, except for Subscription in which case Fees are payable for each calendar month in
              advance, by payment card through the use of third-party payment means. The Client acknowledges and agrees
              that the invoices for the payment of the Fees for the Services are sent by e-mail and accessible from the
              Client Account. The Client warrants the accuracy of the payment card data filled for the payment of the
              Fees and undertakes to update them regularly.&nbsp;
            </p>
          </li>
          <li>
            <p>
              Late payment. In case of rejected payment, the Client will be informed by e-mail and must proceed with the
              regularization of the payment using payment means agreed by Primo no later than within twenty-four (24)
              hours from the notification. Moreover, Primo may claim, as of right and without any prior formality,
              payment by Client of late payment interests at the interest rate applied by the European Central Bank to
              its most recent refinancing operation increased by ten (10) points of percentage as well as a lump sum
              collection fee of forty (40) euros per invoice, notwithstanding the right to claim refund of the total
              collection costs incurred by Primo upon presentation of supporting documents. Without prejudice to the
              above, in case of rejected payment by the Client which is not remedied on time, Primo reserves the right
              to suspend the provision of the relevant Services and the Platform as applicable and/or terminate the
              Agreement in accordance with the terms below. Primo&rsquo;s right to suspend and/or terminate the
              Agreement is without prejudice to its right to claim damages.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <h2>RIGHT OF USE</h2>
        <ol>
          <li>
            <p>
              Platform. In consideration of Client&rsquo;s compliance with the Agreement, Primo grants to Client, a
              personal, non-exclusive, non-transferable, and non-sublicensable license allowing Admin and Users (where
              applicable) to access and use the Platform for the internal needs of Client. Client is not authorized to
              use the Services for the purpose of providing Deploy and/or Configure services to any affiliated entity of
              Client and each affiliated entity must enter its own agreement with Primo.
            </p>
          </li>
          <li>
            <p>
              Configure services. In consideration of Client&rsquo;s compliance with the Agreement and payment of the
              Subscription Fees, Primo grants to Client, a personal, non-exclusive, non-transferable, and
              non-sublicensable license to use the Software provided by Primo for the internal needs of the Client of
              securing its devices (whether being a Device procured by Primo or not). The Client acknowledges and agrees
              that the license and support to the Third-Party Software is purchased by Client through Primo but is
              licensed and supported by Hexnode to Client pursuant to Hexnode&rsquo;s then current license and support
              agreement as identified in the Order or updated from time to time.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <h2>ACCESS TO THE SERVICES</h2>
        <ol>
          <li>
            <p>
              Connection to the Platform.&nbsp;Access to and use of the Platform requires an internet connection, at the
              Client&rsquo;s expense, and prior registration of the Client on the Platform by creating a Client Account.
              Such Client Account requires to provide information relating to Client and the Admin as well a valid
              professional e-mail for login and a password. When the Client wishes to allow Users to access and use the
              Platform, Admin is authorized to create accounts for Users with User IDs associated to the Client Account.
              The Client is responsible for ensuring that the Client Account and the Admin ID together with the User IDs
              remain confidential and are not communicated to third parties. Client remains liable for any use of the
              Platform from the Admin ID or User ID.
            </p>
          </li>
          <li>
            <p>
              API connection. Deploy and Configure services require Client to use the HR API to connect its HR system to
              the Platform for purposes of Onboarding and Offboarding Users. HR API are third party tools used by Primo
              to retrieve Client&rsquo;s data by reading requests.
            </p>
          </li>
          <li>
            <p>
              Restriction of rights.&nbsp;The Client is only authorized to use the Platform under the terms and
              conditions expressly set in the Agreement. Primo reserves all other rights. The Client undertakes not to
              (i) copy, make available and/or distribute in whole or in part in any way whatsoever the Platform and the
              Services to third parties other than the Admin or authorized Users; (ii) access and/or use the Platform
              without paying the applicable Fees; (iii) assign, sell, lease, lend, sub-license, distribute, outsource or
              transfer the rights that have been granted to it under the Agreement; (iv) adapt, modify, including for
              correction purposes, or translate the Platform; (v) disassemble, decompile the Platform, perform reverse
              engineering, or otherwise attempt to discover or reconstitute its source code, except as specifically
              authorized by applicable law; (vi) alter, destroy or delete notices relating to intellectual property
              rights or any other reference to Primo&rsquo;s ownership appearing on the Platform, as well as in the
              Documentation; (vii) distribute all or part of a software or service created in whole or in part with the
              Platform; (viii) use the Platform for the purposes of benchmarking, or communicate to third parties the
              results of performance tests obtained from using the Platform.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <h2>INTELLECTUAL PROPERTY</h2>
      </li>
    </ol>
    <p>
      Primo and its licensors will remain the sole owner of all of the material and intellectual property rights
      attached to the Services. &nbsp;The Agreement does not include any transfer of property to Client over the
      Platform and Software except the temporary right of use granted hereto. The Client undertakes not to infringe on
      the property rights of Primo and its licensors.
    </p>
    <ol start={9}>
      <li>
        <h2>PERSONAL DATA</h2>
      </li>
    </ol>
    <p>
      Primo processes Personal Data in accordance with the conditions set in Primo&rsquo;s Data Protection Agreement
      accessible on the Platform, which is incorporated into the Agreement by reference.
    </p>
    <ol start={10}>
      <li>
        <h2>DEPLOY OFFERINGS</h2>
        <ol>
          <li>
            <p>
              Client Account.&nbsp;Primo provides a royalty-free Client Account to Client to detect and manage Users
              from Onboarding to Offboarding, place Orders for procurement or retrieval services and check
              Client&rsquo;s inventory of Devices and Accessories.
            </p>
          </li>
          <li>
            <p>
              Procurement services.&nbsp;Primo provides a Catalog from which Client can purchase Device and/or
              Accessories pursuant to an Order and payment of the Fees. Procurement does not include any licensed
              software (except the operating system of the Device) nor the initializing of the Device for use by the
              User.&nbsp;
            </p>
          </li>
          <li>
            <p>
              Extended warranty.&nbsp;Client can purchase from Primo a Warranty Extension for a Device (along with the
              purchase of a Device) by issuing an Order and paying the Fees. Primo acts as a distributor of the Warranty
              Extension which is an agreement entered between Client and the Device manufacturer or related entity
              (other than Primo) providing the extended warranty services; such warranty terms must be enforced by
              Client directly against said contracting entity and not Primo. Warranty Extension can be purchased by
              Client at the time of procurement of the Device or within ninety (90) days after ordering the purchase of
              Devices from Primo.
            </p>
          </li>
          <li>
            <p>
              Retrieval services.&nbsp;Primo provides retrieval services for Ordering by Client which include shipping
              back the Devices and Accessories from User to Client&rsquo;s premises or to Primo for cleaning services as
              further described on the Platform. All Fees and shipping cost due by Client are indicated during placement
              of the Order.
            </p>
          </li>
          <li>
            <p>
              Options available.&nbsp;If Client does not want to re-use the Devices or Accessories to be retrieved from
              User or within its inventory, Client can order Primo to (i) sell the Devices or Accessories on any
              secondary market for use by third parties, (ii) give the Devices or Accessories to a User for personal
              use, or (iii) recycle the Devices or Accessories. In case of sale by Primo on behalf of Client as per (i),
              Client will be credited the amount recovered by Primo from the sale (less any fees and taxes paid by Primo
              as well as Primo&rsquo;s commission as part of resale on the secondary market) which will be offset with
              Fees due from future Orders for Services placed on the Platform. Credits not used by Client by the end of
              the Agreement will not be subject to a refund by Primo. In case of provision to the User as per (ii),
              Client will be solely responsible for giving appropriate instructions to the User on terms and conditions
              for personal use.
            </p>
          </li>
          <li>
            <p>
              Shipping, delivery and acceptance.&nbsp;Shipping fees are as indicated during placement of the Order for
              delivery to Mainland France only (including Corsica) according to the address set by Client on the Order,
              any shipping to another destination will require specific terms and fees for shipment to be agreed upon
              between Client and Primo. Primo will provide a date for delivery of the Order which is estimative and
              subject to full payment of the Fees and shipping fees by Client to Primo. Client (or User on behalf of
              Client) must notify in writing to Primo any non-conformity or product missing with a copy of any
              reservations made to the shipping carrier within twenty-four (24) hours from delivery. Delivery will be
              deemed to occur once the recipient (Client or User on behalf of Client) takes physical possession or
              control of the goods ordered. Acceptance will occur if within the above-mentioned 24-hours deadline no
              claim has been notified to Primo.&nbsp;
            </p>
          </li>
        </ol>
      </li>
      <li>
        <h2>CONFIGURE SERVICES</h2>
        <ol>
          <li>
            <p>
              Prior setup by Primo.&nbsp;Procurement of Configure services is available for purchase along with any
              Client&rsquo;s devices, including Devices purchased by Client from Primo. Configure services require
              Primo&rsquo;s or User&rsquo;s installation and use of the Software and Third Party Software on behalf of
              Client, which Client acknowledges and agrees to.
            </p>
          </li>
          <li>
            <p>
              Tracking.&nbsp;Upon installation, Configure services allow Client to track from its Client Account all
              Devices actively enrolled in Configure. Tracking includes access to the status of the Device (active /
              inactive), as well as activity logs with respect to a Device.
            </p>
          </li>
          <li>
            <p>
              Security measures.&nbsp;Configure services allow Client through Admin actions to take remote security
              measures with respect to a Device as further described and available on the Platform.
            </p>
          </li>
          <li>
            <p>
              Standard support.&nbsp;Primo will use its best commercial efforts to answer Client&apos;s questions
              regarding the use of the Configure services sent by e-mail (
              <a href="mailto:support@getprimo.com">support@getprimo.com</a>) or chat during Office Hours. As part of
              such assistance, Primo may upon request and when required route any question or error affecting the
              Configure services to Hexnode on behalf of Client, being understood that Primo&rsquo;s liability cannot be
              sought for any issue related to the Third Party Software provided by Hexnode.&nbsp;
            </p>
          </li>
          <li>
            <p>
              Additional support packages.&nbsp;Primo provides upon request from the Client and on a case-by-case basis
              additional support packages, the scope of which will be described along with the Fees due on the Platform
              and/or in a specific Order issued by Primo.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <h2>PLATFORM</h2>
        <ol>
          <li>
            <p>
              Hosting.&nbsp;Primo will host the Platform and ensures the general remote accessibility of the Platform
              except during maintenance periods or in case of a force majeure event. The Client acknowledges being
              informed of the hazards inherent to the Internet network and the consequences it may have on the
              availability and accessibility of the Platform. Primo will in no event be held liable for the temporary
              inaccessibility of the Platform due to the Client&rsquo;s hardware or a failure of the internet network.
            </p>
          </li>
          <li>
            <p>
              Storage of Client Data.&nbsp;Client shall be responsible to ensure the storage and archiving, where
              applicable, of the incoming data sent to Primo relating to Users and any output data processed by the
              Platform. Primo will not be liable in any way whatsoever for any loss or destruction of Client Data.
            </p>
          </li>
          <li>
            <p>
              Maintenance. Primo reserves the right at any time to make any updates and/or changes to the Platform. Such
              maintenance will be performed at Primo&apos;s discretion. Primo will ensure that maintenance does not
              materially downgrade the functionality of the Platform.
            </p>
          </li>
          <li>
            <p>
              Support. Primo will use its best commercial efforts to answer Client&apos;s general questions regarding
              use of the Platform by chat during Office Hours. The support provided by Primo does not include the
              provision of assistance services to the Client, such as for the installation or configuration of the
              Devices or Accessories or remote management of Devices and their applications.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <h2>WARRANTIES&nbsp;</h2>
        <ol>
          <li>
            <p>
              The Client acknowledges and agrees that the Platform is provided on an &ldquo;as is&rdquo; basis, and
              without any warranty from Primo. Unless provided otherwise in this Agreement and to the extent permitted
              by applicable law, Primo expressly disclaims any warranty of conformity, infringement, hidden defect,
              compatibility and of fitness for Client&rsquo;s particular purposes with respect to the Platform and
              Services. Primo does not warrant that the Platform or Software will be error free or that they will
              operate without interruption. The Client expressly agrees to assume all risks inherent to the access to
              and use of the Platform, Software and its results.
            </p>
          </li>
          <li>
            <p>
              With respect to the procurement of Devices and/or Accessories, Client benefit from a commercial warranty
              provided by the relevant manufacturer under terms and conditions set in the documentation accompanying the
              Device or Accessory purchased. Such warranty shall be enforced by Client directly against the relevant
              entity identified in the documentation.&nbsp;
            </p>
          </li>
          <li>
            <p>
              Client warrants that Client Data do not (i) violate applicable laws; (ii) contain any virus or malicious
              code that could harm or corrupt the Platform, and/or (iii) constitute an unlawful processing of Personal
              Data. Client undertakes, at its expense, to defend and hold Primo harmless from any action or claim made
              by a third party (including without limitation claim of infringement, unfair competition, free riding,
              Personal Data breach) concerning the use of Client Data by Primo under the Agreement. The Client shall
              indemnify Primo for all expenses (including lawyers&rsquo; fees), losses and damages that Primo may incur,
              together with the amount of any settlement and/or compensation for which Primo may be held liable.&nbsp;
            </p>
          </li>
        </ol>
      </li>
      <li>
        <h2>LIABILITY</h2>
      </li>
    </ol>
    <p>Client is solely responsible to ensure that the Client Data and its hardware are suitably protected.&nbsp;</p>
    <p>
      Primo disclaims all liability for the inaccuracy, loss or alteration of Client Data or in case of indirect damage,
      including without limitation, loss of income, loss of profits or loss of goodwill resulting from the use of the
      Platform and the Services.
    </p>
    <p>
      Primo is bound by an obligation of means to make the Platform available and provide the Services. To the extent
      permitted by applicable law, the liability of Primo in the aggregate is limited to the amount of the Fees due and
      paid by the Client under the Agreement for the Order giving rise to a claim, being understood that for Configure
      services such Fees are limited to the Fees paid during the last six (6) months for the Order giving rise to a
      claim.
    </p>
    <ol start={15}>
      <li>
        <h2>TERMINATION</h2>
        <ol>
          <li>
            <p>
              Termination for convenience of the Agreement.&nbsp;Each Party may terminate the Agreement at any time,
              subject to a prior written notice sent by registered letter to the other Party no later than thirty (30)
              calendar days before the effective date of termination of the Agreement.&nbsp;
            </p>
          </li>
        </ol>
      </li>
    </ol>
    <p>
      Termination of the Agreement for convenience shall not affect the performance of current Order(s) which remain in
      full force and effect until their current term and governed by the terms of the Agreement. However, as from the
      effective date of termination of the Agreement Client will no longer be entitled to place any new Order on the
      Platform.&nbsp;
    </p>
    <ol start={2}>
      <li>
        <p>
          Termination for convenience of a Subscription Order. Each Party may terminate a current Subscription Order at
          any time, subject to a prior written notice sent by registered letter to the other Party of no less than
          thirty (30) calendar days before the effective date of termination of the Subscription Order. Where
          applicable, Primo will refund any prepaid Fees for a Subscription Order on a pro-rata basis for the unused
          Configure services following the effective date of termination.
        </p>
      </li>
      <li>
        <p>
          Termination for cause.&nbsp;Each Party can terminate as of right the Agreement and/or a relevant Order in case
          of breach of the other Party of any provisions of the Agreement (such as articles 5, 6, 7.3, 8, 13 and 16).
          Termination will occur upon prior formal notice of thirty (30) calendar days, sent by any means (including
          e-mail), which remained uncured by the breaching Party or without prior notice when the emergency requires it
          (such as in case of infringing use of the Platform by the Client). Termination is made without prejudice to
          other rights or remedies available to the non-breaching Party.
        </p>
      </li>
    </ol>
    <p>
      Termination of an Order for cause shall not affect the performance of other current and valid Order(s) which
      remain in full force and effect until their current term. Termination of the Agreement for cause triggers
      termination of all Orders as of right; all rights granted to the Client in relation to the use the Services will
      immediately terminate and the Client must immediately cease and have ceased all use of the Services.
    </p>
    <ol start={4}>
      <li>
        <p>
          Consequences of termination.&nbsp;Termination shall not affect any rights and obligations of the Parties that
          have accrued up to such termination. The provisions of the Agreement that are meant to remain into effect
          (such as articles 5, 8, 13, 14, 16 and 18) shall survive termination.
        </p>
      </li>
    </ol>
    <ol start={16}>
      <li>
        <h2>CONFIDENTIALITY</h2>
      </li>
    </ol>
    <p>
      Each Party undertakes, with regards to information of a technical (including without limitation the Platform, the
      API and the Software), financial, business or otherwise sensitive nature (the &ldquo;Confidential
      Information&rdquo;) of the other Party that it may have had knowledge of prior to or during the performance of the
      Agreement, to (i) keep it strictly confidential and not to copy, communicate or make it available to third parties
      except to their employees duly authorized, and (ii) not to use it, directly or indirectly, for a purpose other
      than the performance of this Agreement. Upon termination of the Agreement, each Party undertakes to return or
      destroy the other Party&rsquo;s Confidential Information in its possession or under its control.
    </p>
    <ol start={17}>
      <li>
        <h2>MISCELLANEOUS</h2>
        <ol>
          <li>
            <p>
              The Parties are and will remain throughout the term of the Agreement independent contracting Parties. The
              Agreement does not constitute, in any way, an agency agreement, a partnership, a working agreement or a
              joint venture between the Parties.
            </p>
          </li>
          <li>
            <p>
              The Agreement together with the relevant Order(s) constitute the entire agreement between the Client and
              Primo relating to its subject matter, it supersedes all prior agreements or declarations, whether made in
              writing or orally.&nbsp;
            </p>
          </li>
          <li>
            <p>
              Primo reserves the right to modify the Agreement and/or the conditions of a Subscription Order at any time
              subject to prior notice to the Client by any means. The modifications will become effective at the
              effective date indicated by Primo, it being understood that the Agreement and/or the Subscription Order as
              modified cancels and replaces the existing Agreement and/or the Subscription Order (the modified Agreement
              then becomes the &ldquo;Agreement&rdquo; and the modified Subscription Order then becomes the
              &ldquo;Subscription Order&rdquo;). If the Client does not agree to the modified Agreement and/or
              Subscription Order, it must terminate this Agreement and/or the Subscription Order in accordance with
              article 15 above.
            </p>
          </li>
          <li>
            <p>
              Failure of either Party to enforce against the other Party any right it holds under the Agreement shall
              not be deemed to be a waiver from that Party. Any waiver shall be in writing signed by an authorized
              representative of the relevant Party.
            </p>
          </li>
          <li>
            <p>
              Neither Party may be held responsible for a breach of its obligations under the Agreement in the case of
              force majeure as defined by applicable law and interpreted by the French jurisdictions, including without
              limitation any governmental decision of any kind, a total or partial strike whether internal or external
              to the company, a fire, a natural disaster, an act of terrorism, a pandemic, a state of war, a total or
              partial interruption or a blockage of the postal, telecommunication or electricity networks, an act of
              computer hacking.
            </p>
          </li>
          <li>
            <p>
              The Client expressly authorizes Primo to use and reproduce, for business reference purposes,
              Client&rsquo;s name, trading name and/or trademark and make reference to the contractual relationship with
              the Client on all promotional vectors used by Primo.&nbsp;
            </p>
          </li>
          <li>
            <p>
              The Client expressly acknowledges and agrees that Primo reserves the right to assign or to transfer, in
              whole or in part, any of its rights or obligations under the Agreement to any third party. The assignment
              will release for the future Primo from its obligations and liabilities under the Agreement.
            </p>
          </li>
          <li>
            <p>
              Primo reserves the right to delegate all or part of its obligations to any third-party service providers
              of its choice.
            </p>
          </li>
          <li>
            <p>
              In the event of substantial change in relation to the Client (such as internal reorganization, merger,
              change of control, asset transfer or other transfer by operation of law) involving a competitor of Primo,
              Client shall notify Primo no later than thirty (30) days before the expected change. Primo reserves the
              right to terminate the Agreement as of right upon written notice within thirty (30) days from the receipt
              of the notification or at any time if Client fails to notify such change.
            </p>
          </li>
          <li>
            <p>
              If any provision of the Agreement is determined to be void, inapplicable or unenforceable by virtue of law
              or by any final court ruling, the remainder of the Agreement shall remain in full force and effect.
            </p>
          </li>
          <li>
            <p>
              In the event of dispute, the Parties expressly agree, as a convention of proof, that the information
              collected by Primo concerning the use of the Platform and the Services will constitute valid evidence and
              will be admissible and enforceable between the Parties.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <h2>APPLICABLE LAW AND JURISDICTION&nbsp;</h2>
      </li>
    </ol>
    <p>
      The Agreement is governed by French law. In the event of a dispute between the Parties relating to the Agreement
      that cannot be solved amicably, the Parties agree to submit to the exclusive jurisdiction of the courts of Paris,
      notwithstanding plurality of defendants or warranty claims, including urgent or interim proceedings, whether they
      are summary proceedings or application proceedings.
    </p>
  </Bloc>
);

export default SignupCguModal;
