import { useCallback, useEffect, useMemo } from 'react';
import { ok, refuse } from '@primo/operation-result';
import type { GetEmployeeDevicesQuery } from '~/graphql/schema';
import { useGetEmployeeDevicesLazyQuery } from '~/graphql/schema';
import type { UseQueryOptions } from '~/hooks/queries/types';

type UseGetEmployeeDevicesVariables = {
  limit?: number;
  page?: number;
};

const useQueryGetEmployeeDevices = (queryOptions: UseQueryOptions<UseGetEmployeeDevicesVariables> = {}) => {
  const serializeDeviceData = useCallback((data: GetEmployeeDevicesQuery | undefined) => {
    if (data?.getEmployeeDevices?.__typename === 'QueryGetEmployeeDevicesSuccess') {
      return ok(data.getEmployeeDevices.data);
    }
    if (data?.getEmployeeDevices?.__typename === 'QueryGetEmployeeDevicesError')
      return refuse(data.getEmployeeDevices.reasons);
    return refuse(['Unknown error']);
  }, []);

  const [query, { data: deviceData, loading: employeeDevicesLoading, called: employeeDevicesCalled }] =
    useGetEmployeeDevicesLazyQuery();

  const employeeDevicesResult = useMemo(() => serializeDeviceData(deviceData), [serializeDeviceData, deviceData]);

  const getEmployeeDevices = useCallback(
    async (variables: UseGetEmployeeDevicesVariables) => {
      const result = await query({ variables });
      return serializeDeviceData(result.data);
    },
    [query, serializeDeviceData],
  );

  useEffect(() => {
    if (queryOptions.runOnInit && !employeeDevicesCalled) getEmployeeDevices({ ...queryOptions });
  }, [getEmployeeDevices, queryOptions, employeeDevicesCalled]);

  return useMemo(
    () => ({
      getEmployeeDevices,
      employeeDevicesResult,
      employeeDevicesLoading,
      employeeDevicesCalled,
    }),
    [getEmployeeDevices, employeeDevicesResult, employeeDevicesLoading, employeeDevicesCalled],
  );
};

export default useQueryGetEmployeeDevices;
