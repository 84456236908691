import React, { useContext, useEffect } from 'react';
import { useLocalStorage } from 'react-use';
import Loader from '~/components/shared/Loader';
import { useSamlLoginUrl } from '~/hooks/useSamlLogin';

import { PrimoEmployeeContext } from '~/providers/primo/PrimoEmployeeProvider';

/**
 * Will redirect to <children> or "Service provider / IDP flow" page based on Primo IDP auth session
 * @param children -
 * @constructor
 */
const EmployeeAuthenticatedGuard: FunctionComponent = ({ children = null }) => {
  const samlLoginUrl = useSamlLoginUrl();
  const { isAuthenticated, isLoading } = useContext(PrimoEmployeeContext);
  const [, setShowEmployeeEnrollmentStepper] = useLocalStorage('enrollment', false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const hasEnrollmentParams = !!queryParams.get('enrollment');
    if (hasEnrollmentParams) {
      setShowEmployeeEnrollmentStepper(hasEnrollmentParams);
    }

    if (isAuthenticated) {
      return;
    }

    if (!isAuthenticated && !isLoading) {
      window.location.href = samlLoginUrl;
    }
  }, [isAuthenticated, isLoading, samlLoginUrl, setShowEmployeeEnrollmentStepper]);

  if (isLoading) return <Loader />;

  if (!isAuthenticated) {
    return null;
  }
  return <div>{children}</div>;
};

export default EmployeeAuthenticatedGuard;
