import type { TFunction } from 'i18next';
import * as zod from 'zod';

export const selfSignupCompanyDetailsSchema = (t: TFunction) =>
  zod.object({
    companyName: zod
      .string()
      .min(1, t('forms.validations.invalid_value'))
      .max(40, t('forms.validations.invalid_value')),
    companySize: zod
      .string({ invalid_type_error: t('forms.validations.invalid_value') })
      .transform(Number)
      .refine(value => value > 0, t('forms.validations.invalid_value')),
    employeeFirstName: zod.string().min(1, t('forms.validations.invalid_value')),
    employeeLastName: zod.string().min(1, t('forms.validations.invalid_value')),
    employeeJob: zod.string().min(1, t('forms.validations.invalid_value')),
    cguAgreement: zod.boolean().refine(value => value, {
      message: t('forms.validations.cgu_not_accepted'),
    }),
  });

export type SelfSignupCompanyDetailsFields = zod.infer<ReturnType<typeof selfSignupCompanyDetailsSchema>>;
