import { useCallback } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { useLocalStorage } from 'react-use';
import analytics from '~/analytics';

/** Remove local access token and logout */
const useLogout = (callback?: (returnTo?: string) => void) => {
  const [, , removeAccessToken] = useLocalStorage<string>('accessToken');

  const logout = useCallback(
    async (to?: string) => {
      await analytics?.reset();
      datadogRum.clearUser();
      datadogLogs.clearUser();
      removeAccessToken();
      window.Intercom?.('shutdown');
      callback?.(to);
    },
    [removeAccessToken, callback],
  );

  return { logout };
};

export default useLogout;
