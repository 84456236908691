import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Message from '~/components/shared/Message';

import { SSOLoginContext } from './context';

const Errors: FunctionComponent = () => {
  const { t } = useTranslation();
  const { sendOTPRequestError, getChannelsError, requestData } = useContext(SSOLoginContext);

  if (!requestData) {
    return (
      <Message variant="danger" className="pri-pt-5 pri-px-5">
        {t('errors.fail_to_parse_saml_request')}
      </Message>
    );
  }
  return sendOTPRequestError || getChannelsError ? (
    <Message variant="danger" className="pri-pt-5 pri-px-5">
      {sendOTPRequestError?.message || getChannelsError?.message}
    </Message>
  ) : null;
};
export default Errors;
