import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import React, { lazy, Suspense, useEffect } from 'react';
import analytics from '~/analytics';
import {
  SSO_LOGIN_ROUTE,
  LOGOUT_ROUTE,
  LOGIN_ROUTE,
  SIGNUP_ROUTE,
  LOGIN_CALLBACK_ROUTE,
  SIGNUP_CALLBACK_ROUTE,
  DASHBOARD_ROUTE,
  CALLBACK_OAUTH_SERVICE_PROVIDER_ROUTE,
  EQUIPMENTS_ROUTE,
  EQUIPMENTS_DEVICE_DETAILS_ROUTE,
  EQUIPMENTS_ACCESSORY_DETAILS_ROUTE,
  EQUIPMENTS_DEVICE_EMPLOYEE_DETAILS_ROUTE,
  EQUIPMENTS_ACCESSORY_EMPLOYEE_DETAILS_ROUTE,
  APPS_ROUTE,
  APPS_DETAILS_ROUTE,
  ORDERS_ROUTE,
  ORDER_DETAILS_ROUTE,
  ORDERS_EMPLOYEE_ROUTE,
  SHIPMENTS_ROUTE,
  SHIPMENT_DETAILS_ROUTE,
  EMPLOYEES_ROUTE,
  EMPLOYEE_DETAILS_ROUTE,
  EMPLOYEES_EQUIPMENT_DEVICE_DETAILS_ROUTE,
  EMPLOYEES_EQUIPMENT_ACCESSORY_DETAILS_ROUTE,
  CATALOG_ROUTE,
  SETTINGS_ROUTE,
  PROFILES_ROUTE,
  PROFILE_DETAILS_ROUTE,
} from '~/config/routes';
import AdminAuthenticatedGuard from '~/features/guards/AdminAuthenticatedGuard';
import PrimoLayout from '~/features/layout/PrimoLayout';
import LoginPage from '~/pages/Login';
import LoginCallbackPage from '~/pages/Login/Callback';
import LogoutPage from '~/pages/Logout';
import SignUpPage from '~/pages/Signup';
import SignupCallbackPage from '~/pages/Signup/part/SocialSignup/Callback';
import SSOLoginPage from '~/pages/SSOLogin';
import { SteppersProvider } from '~/providers/steppers/AdminStepperProvider/index';
import Beta from '~/components/shared/Beta';

const AccessoryDrawer = lazy(() => import('~/features/drawers/AccessoryDrawer'));
const AppsPage = lazy(() => import('~/pages/Apps'));
const AppDrawer = lazy(() => import('~/features/drawers/AppDrawer'));
const CatalogPage = lazy(() => import('~/pages/Catalog'));
const OAuthServiceProviderPage = lazy(() => import('~/pages/Callback/OAuthServiceProvider'));
const DashboardPage = lazy(() => import('~/pages/Dashboard'));
const DeviceDrawer = lazy(() => import('~/features/drawers/DeviceDrawer'));
const EmployeeDrawer = lazy(() => import('~/features/drawers/EmployeeDrawer'));
const EmployeesPage = lazy(() => import('~/pages/Employees'));
const EmployeesPageV2 = lazy(() => import('~/pages/EmployeesV2'));
const EquipmentsPage = lazy(() => import('~/pages/Equipments'));
const OrderDrawer = lazy(() => import('~/features/drawers/OrderDrawer'));
const OrdersPageV2 = lazy(() => import('~/pages/Orders'));
const SettingsPageV2 = lazy(() => import('~/pages/Settings'));
const ShipmentDrawer = lazy(() => import('~/features/drawers/ShipmentDrawer'));
const ShipmentsPage = lazy(() => import('~/pages/Shipments'));
const ProfilesPage = lazy(() => import('~/pages/Profiles'));
const ProfilesDetails = lazy(() => import('~/pages/Profiles/_parts/ProfileDetails'));

export const AdminRouter = () => {
  const location = useLocation();

  useEffect(() => {
    analytics?.page();
  }, [location]);

  return (
    <Routes>
      <Route path={`${SSO_LOGIN_ROUTE()}/*`} element={<SSOLoginPage />} />
      <Route path={LOGOUT_ROUTE()} element={<LogoutPage />} />
      <Route path={`${LOGIN_ROUTE()}/*`} element={<LoginPage />} />
      <Route path={`${SIGNUP_ROUTE()}/*`} element={<SignUpPage />} />
      <Route path={LOGIN_CALLBACK_ROUTE()} element={<LoginCallbackPage />} />
      <Route path={SIGNUP_CALLBACK_ROUTE()} element={<SignupCallbackPage />} />
      <Route
        path="/*"
        element={
          <AdminAuthenticatedGuard>
            <SteppersProvider>
              <PrimoLayout>
                <Suspense fallback={null}>
                  <Routes>
                    <Route index element={<Navigate to={DASHBOARD_ROUTE()} />} />
                    <Route path={CALLBACK_OAUTH_SERVICE_PROVIDER_ROUTE()} element={<OAuthServiceProviderPage />} />
                    <Route path={DASHBOARD_ROUTE()} element={<DashboardPage />} />
                    <Route path={EQUIPMENTS_ROUTE()} element={<EquipmentsPage />}>
                      <Route path={EQUIPMENTS_DEVICE_DETAILS_ROUTE()} element={<DeviceDrawer />} />
                      <Route path={EQUIPMENTS_ACCESSORY_DETAILS_ROUTE()} element={<AccessoryDrawer />} />
                      <Route path={EQUIPMENTS_DEVICE_EMPLOYEE_DETAILS_ROUTE()} element={<EmployeeDrawer />} />
                      <Route path={EQUIPMENTS_ACCESSORY_EMPLOYEE_DETAILS_ROUTE()} element={<EmployeeDrawer />} />
                    </Route>
                    <Route path={APPS_ROUTE()} element={<AppsPage />}>
                      <Route path={APPS_DETAILS_ROUTE()} element={<AppDrawer />} />
                    </Route>
                    <Route path={ORDERS_ROUTE()} element={<OrdersPageV2 />}>
                      <Route path={ORDER_DETAILS_ROUTE()} element={<OrderDrawer />} />
                      <Route path={ORDERS_EMPLOYEE_ROUTE()} element={<EmployeeDrawer />} />
                    </Route>
                    <Route path={SHIPMENTS_ROUTE()} element={<ShipmentsPage />}>
                      <Route path={SHIPMENT_DETAILS_ROUTE()} element={<ShipmentDrawer />} />
                    </Route>
                    <Route
                      path={EMPLOYEES_ROUTE()}
                      element={
                        <>
                          <Beta not>
                            <EmployeesPage />
                          </Beta>
                          <Beta>
                            <EmployeesPageV2 />
                          </Beta>
                        </>
                      }
                    >
                      <Route path={EMPLOYEE_DETAILS_ROUTE()} element={<EmployeeDrawer />} />
                      <Route path={EMPLOYEES_EQUIPMENT_DEVICE_DETAILS_ROUTE()} element={<DeviceDrawer />} />
                      <Route path={EMPLOYEES_EQUIPMENT_ACCESSORY_DETAILS_ROUTE()} element={<AccessoryDrawer />} />
                    </Route>
                    <Route path={CATALOG_ROUTE()} element={<CatalogPage />} />
                    <Route path={SETTINGS_ROUTE()} element={<SettingsPageV2 />} />
                    <Route path={PROFILES_ROUTE()} element={<ProfilesPage />}>
                      <Route path={PROFILE_DETAILS_ROUTE()} element={<ProfilesDetails />} />
                    </Route>
                  </Routes>
                </Suspense>
              </PrimoLayout>
            </SteppersProvider>
          </AdminAuthenticatedGuard>
        }
      />
    </Routes>
  );
};
