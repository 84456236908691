import React from 'react';

const MobileIcon: SvgComponent = ({ className, width = 24 }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.375 2.75H5.625C5.32673 2.7503 5.04075 2.86892 4.82983 3.07983C4.61892 3.29075 4.5003 3.57673 4.5 3.875V16.25C4.50034 16.5483 4.61898 16.8343 4.82988 17.0452C5.04079 17.2561 5.32674 17.3747 5.625 17.375H12.375C12.6733 17.3746 12.9592 17.256 13.1701 17.0451C13.381 16.8342 13.4996 16.5483 13.5 16.25V3.875C13.4997 3.57674 13.381 3.29079 13.1701 3.07988C12.9592 2.86898 12.6733 2.75034 12.375 2.75ZM12.375 3.875V3.3125H5.625V3.875H12.375ZM5.625 16.25V3.875H12.375V16.25H5.625Z"
      fill="#1E1E1E"
    />
    <circle cx="9" cy="14.5625" r="0.562502" fill="#3C3C3B" />
  </svg>
);

export default MobileIcon;
