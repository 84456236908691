import React from 'react';
import { Route, Routes } from 'react-router-dom';

import CountrySelector from '~/components/shared/forms/CountrySelector';
import { LOGIN_REQUEST_RESET_PASSWORD_ROUTE } from '~/config/routes';
import { LoginProvider } from '~/pages/Login/context';
import LoginFlowPart from '~/pages/Login/parts/LoginFlowPart';
import ResetPasswordRequestPage from '~/pages/Login/ResetPasswordRequest';

import Flex from '~/components/shared/shaping/Flex';

import LoginBackground from '~/features/layout/LoginBackgound';

const LoginPage: FunctionComponent = () => (
  <LoginProvider>
    <LoginBackground>
      <Routes>
        <Route
          index
          element={
            <>
              <LoginFlowPart />
              <Flex className="pri-mt-4" direction="row" justify="center">
                <CountrySelector />
              </Flex>
            </>
          }
        />
        <Route path={LOGIN_REQUEST_RESET_PASSWORD_ROUTE()} element={<ResetPasswordRequestPage />} />
      </Routes>
    </LoginBackground>
  </LoginProvider>
);
export default LoginPage;
