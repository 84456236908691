import React from 'react';
import { EmployeeEnrollmentStepperProvider } from './context';
import EmployeeEnrollmentStepperContent from './stepper';
import { Stepper } from '~/components/shared/Stepper';

export type EmployeeEnrollmentStepperProps = {
  deviceId?: string;
  onClose: () => void;
};

const EmployeeEnrollmentStepper: FunctionComponent<EmployeeEnrollmentStepperProps> = ({ onClose, deviceId }) => (
  <Stepper onClose={onClose}>
    <EmployeeEnrollmentStepperProvider deviceId={deviceId}>
      <EmployeeEnrollmentStepperContent deviceId={deviceId} />
    </EmployeeEnrollmentStepperProvider>
  </Stepper>
);

export default EmployeeEnrollmentStepper;
