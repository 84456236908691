import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import { isRefusal } from '@primo/operation-result';
import type { TFunction } from 'i18next';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import zod from 'zod';
import { Form } from '~/components/shared/forms/Form';
import Input from '~/components/shared/forms/Input';
import Modal from '~/components/shared/Modal';
import Flex from '~/components/shared/shaping/Flex';
import { Text } from '~/components/shared/typography';
import useQueryCheckExistingPersonalEmail from '~/hooks/queries/employees/useQueryCheckExistingPersonalEmail';
import { useCompany } from '~/hooks/useCompany';

interface AddBackupEmailModalProps {
  onConfirm: (backupEmail: string) => Promise<void>;
  onClose: () => void;
  employeeFirstName: string;
  defaultValue?: string;
}

const schema = (t: TFunction) =>
  zod.object({
    backupEmail: zod
      .string({ required_error: t('forms.validations.required') })
      .min(1, t('forms.validations.required')),
  });

export const AddBackupEmailModal = ({
  onClose,
  onConfirm,
  employeeFirstName,
  defaultValue,
}: AddBackupEmailModalProps) => {
  const { t } = useTranslation();
  const form = useForm({ defaultValues: { backupEmail: defaultValue }, resolver: zodResolver(schema(t)) });
  const company = useCompany();
  const { checkExistingPersonalEmail, existingPersonalEmailLoading } = useQueryCheckExistingPersonalEmail();
  const { watch } = form;
  const backupEmail = watch('backupEmail');
  const {
    register,
    formState: { errors },
  } = form;

  const onConfirmClick = useCallback(async () => {
    const result = form.trigger();
    if (!result || !backupEmail) return;

    const personalEmailExists = await checkExistingPersonalEmail({
      personalEmail: backupEmail,
      companyId: company?.id || '',
    });

    if (isRefusal(personalEmailExists) || personalEmailExists.data) {
      toast.error(<>{t('errors.backup_email_already_exists')}</>);
      return;
    }

    onConfirm(backupEmail);
  }, [form, backupEmail, checkExistingPersonalEmail, company?.id, onConfirm, t]);

  return (
    <Form form={form}>
      <Modal
        title={t('modals.titles.need_backup_email', { firstName: employeeFirstName })}
        onConfirm={onConfirmClick}
        onClose={onClose}
        footer={{
          cancelButton: true,
          confirmButton: true,
          confirmButtonLoading: existingPersonalEmailLoading,
        }}
      >
        <Flex direction="column" gap={4}>
          <Text weight="light">{t('modals.texts.need_backup_email')}</Text>
          <Input
            label={t('forms.labels.backup_email')}
            name="backupEmail"
            register={register}
            errors={errors}
            required
          />
        </Flex>
      </Modal>
    </Form>
  );
};

export default AddBackupEmailModal;
