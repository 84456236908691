import moment from 'moment';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const DEFAULT_LANGUAGE = 'en';

const i18nFormat = {
  en: {
    date: 'YYYY/MM/DD',
    human_date: 'DD MMMM YYYY',
    human_datetime_with_weekday: 'dddd DD MMM [at] h:mma',
  },
  fr: {
    date: 'DD/MM/YYYY',
    human_date: 'DD MMMM YYYY',
    human_datetime_with_weekday: 'dddd DD MMM [à] HH:mm',
  },
};

export type FormatDateProps = {
  date: Date;
  format: keyof (typeof i18nFormat)[typeof DEFAULT_LANGUAGE];
};

export const useFormat = (format: keyof (typeof i18nFormat)[typeof DEFAULT_LANGUAGE]) => {
  const {
    i18n: { language },
  } = useTranslation();
  const lng = Object.keys(i18nFormat).includes(language) ? language : DEFAULT_LANGUAGE;

  return i18nFormat[lng as keyof typeof i18nFormat][format];
};

const FormatDate: FunctionComponent<FormatDateProps> = ({ className, date, format }) => {
  const dateFormat = useFormat(format);
  const formatted = useMemo(() => moment.utc(date).format(dateFormat), [date, dateFormat]);

  return <span className={className}>{formatted}</span>;
};

export const formatDate = ({ date, format }: FormatDateProps) => {
  const language = JSON.parse(localStorage.getItem('language') || '"en"');
  const momentFormat = i18nFormat[language as keyof typeof i18nFormat]?.[format];

  return moment.utc(date).locale(language).format(momentFormat);
};

export default FormatDate;
