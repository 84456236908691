import React from 'react';

import cn from 'classnames';
import Flex from '~/components/shared/shaping/Flex';
import getDefaultPicture from './getDefaultPicture';

import './style.scss';

type EquipmentPictureProps = {
  children?: never;
  equipment: {
    picture?: string | null;
    type?: string | null;
    name?: string | null;
  };
};

const EquipmentPicture: FunctionComponent<EquipmentPictureProps> = ({ className, equipment }) => {
  const defaultPicture = getDefaultPicture(equipment.type || 'placeholder');

  return (
    <Flex className={cn(className, 'pri-equipment-picture')}>
      <img
        width="100%"
        src={equipment.picture || defaultPicture}
        alt={equipment.name || '-'}
        onError={e => {
          if (e.currentTarget.src !== defaultPicture) e.currentTarget.setAttribute('src', defaultPicture);
        }}
      />
    </Flex>
  );
};

export default EquipmentPicture;
