import type { ReactNode } from 'react';
import React from 'react';
import cn from 'classnames';
import Flex from '~/components/shared/shaping/Flex';

export interface StepperStepProps {
  size?: 'sm' | 'lg';
  children: ReactNode;
}

const StepperStep: React.FC<StepperStepProps> = ({ children, size }) => (
  <Flex className={cn('pri-step', `pri-step-${size}`)} direction="column">
    {children}
  </Flex>
);

export default StepperStep;
