import * as zod from 'zod';
import type { TFunction } from 'i18next';
import { DevicePlatform, DeviceType } from '~/graphql/schema';

export const schema = (t: TFunction) =>
  zod.object({
    name: zod.string().min(1, t('forms.validations.required')),
    type: zod.nativeEnum(DeviceType, {
      invalid_type_error: t('forms.validations.invalid_value'),
    }),
    platform: zod.nativeEnum(DevicePlatform, {
      invalid_type_error: t('forms.validations.invalid_value'),
    }),
  });

export type Fields = zod.infer<ReturnType<typeof schema>>;
