import { useForm } from 'react-hook-form';
import { useContext } from 'react';
import { OnboardingStepperContext } from '~/features/steppers/OnboardingStepper/context';

export default () => {
  const { contextData } = useContext(OnboardingStepperContext);

  return useForm({
    defaultValues: {
      from: 'catalog',
      selection: {
        inventory: {
          ...(contextData.equipments?.inventory || []).reduce(
            (acc, { item, quantity }) => ({ ...acc, [item.id]: quantity }),
            {},
          ),
        },
        catalog: {
          ...(contextData.equipments?.catalog || []).reduce(
            (acc, { item, quantity }) => ({ ...acc, [item.id]: quantity }),
            {},
          ),
        },
      },
    },
  });
};
