import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { Step } from '~/components/shared/Stepper';
import { StepperContext } from '~/components/shared/Stepper/_components/StepperContext';
import { PrimoAdminContext } from '~/providers/primo/PrimoAdminProvider';
import type { Address, AddressItem } from '~/graphql/schema';
import ChooseAddress from '~/features/steppers/OnboardingStepper/_steps/ChooseShippingAndBillingStep/_parts/ChooseAddress';
import ChooseBillingProfile from '~/features/steppers/OnboardingStepper/_steps/ChooseShippingAndBillingStep/_parts/ChooseBillingProfile';
import AddComment from '~/features/steppers/OnboardingStepper/_steps/ChooseShippingAndBillingStep/_parts/AddComment';
import Flex from '~/components/shared/shaping/Flex';
import { OnboardingStepperContext } from '~/features/steppers/OnboardingStepper/context';

import './style.scss';
import { Title } from '~/components/shared/typography';

export type ChooseShippingAndBillingStepFields = {
  addressChoice: 'home' | 'office' | 'new' | null;
  billingProfileId: string | null;
  companyComment?: string;
};

const ChooseShippingAndBillingStep: FunctionComponent = () => {
  const { t } = useTranslation();
  const { contextData, setContextData, onFinish } = useContext(OnboardingStepperContext);
  const { company, billingProfiles } = useContext(PrimoAdminContext);
  const [companyAddresses, setCompanyAddresses] = useState<AddressItem[] | null>(company?.addresses || null);
  const [employeeAddress, setEmployeeAddress] = useState<Address | null>(null);
  const [newAddress, setNewAddress] = useState<AddressItem | null>(null);
  const { setFooterProps } = useContext(StepperContext);
  const form = useForm<ChooseShippingAndBillingStepFields>({
    defaultValues: { addressChoice: null, billingProfileId: null, companyComment: '' },
  });
  const { watch } = form;
  const choice = watch('addressChoice');
  const currentBillingProfileId = watch('billingProfileId');

  const totalPrice = useMemo(
    () => contextData.equipments?.catalog.reduce((acc, { item, quantity }) => acc + item.priceNoVAT * quantity, 0) || 0,
    [contextData.equipments?.catalog],
  );

  const onNext = useCallback(async () => {
    if (!choice) {
      toast.error(<>{t('errors.no_address_chosen')}</>);
      return false;
    }

    if (!currentBillingProfileId) {
      toast.error(<>{t('errors.no_billing_profile')}</>);
      return false;
    }

    const billingProfileChosen = billingProfiles.find(({ id }) => id === currentBillingProfileId);

    if (!billingProfileChosen) {
      toast.error(<>{t('errors.chosen_billing_profile_invalid')}</>);
      return false;
    }

    const addressMapping = {
      home: employeeAddress,
      new: newAddress,
      ...companyAddresses?.reduce(
        (acc, address) => ({ ...acc, [`office-${address.id}`]: address }),
        {} as Record<string, AddressItem>,
      ),
    };

    const chosenAddress = addressMapping[choice as keyof typeof addressMapping];

    if (!chosenAddress) {
      toast.error(<>{t('errors.chosen_address_invalid')}</>);
      return false;
    }

    const newContextData = {
      ...contextData,
      address: {
        choice: choice as keyof typeof addressMapping,
        location: chosenAddress,
      },
      billingProfileId: billingProfileChosen.id,
      companyComment: form.getValues('companyComment'),
    };

    setContextData(newContextData);
    if (!company?.hasProvisioning) {
      await onFinish(newContextData);

      return false;
    }
    return true;
  }, [
    choice,
    companyAddresses,
    contextData,
    employeeAddress,
    form,
    newAddress,
    billingProfiles,
    currentBillingProfileId,
    company,
    t,
    onFinish,
    setContextData,
  ]);

  const nextLabel = useMemo(() => {
    if (company?.hasProvisioning) {
      return t('buttons.next');
    }
    return totalPrice > 0
      ? t('steppers.onboarding.confirm_and_place_order')
      : t('steppers.onboarding.confirm_onboarding');
  }, [t, company, totalPrice]);

  useEffect(() => {
    setFooterProps({
      onNext,
      nextLabel,
    });
  }, [setFooterProps, onNext, nextLabel]);

  if (!contextData.equipments?.catalog.length) return null;

  return (
    <Step size="lg">
      <Title className="pri-mb-9" level={2}>
        {t('steppers.onboarding.complete_shipping_and_billing')}
      </Title>
      <Flex direction="column" className="pri-order-stepper-shipping">
        <ChooseAddress
          companyAddresses={companyAddresses || []}
          employeeAddress={employeeAddress}
          newAddress={newAddress}
          setCompanyAddresses={setCompanyAddresses}
          setEmployeeAddress={setEmployeeAddress}
          setNewAddress={setNewAddress}
          form={form}
        />
        <ChooseBillingProfile form={form} />
        <AddComment form={form} />
      </Flex>
    </Step>
  );
};

export default ChooseShippingAndBillingStep;
