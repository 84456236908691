import React, { useContext, useEffect, useRef } from 'react';
import Flex from '~/components/shared/shaping/Flex';

import { StepperContext } from './StepperContext';

const StepperRecap: FunctionComponent = ({ children }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { currentStep } = useContext(StepperContext);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [currentStep]);

  return (
    <Flex className="pri-recap" direction="column" gap={7}>
      {children}
      <div ref={ref} />
    </Flex>
  );
};

export default StepperRecap;
