import type { ElementType } from 'react';
import React from 'react';
import { ClassPrefix, Tooltip as CarbonTooltip } from '@carbon/react';
import type { TooltipProps as CarbonTooltipProps } from '@carbon/react/lib/components/Tooltip/Tooltip';
import cn from 'classnames';

import './index.scss';

export type TooltipProps = Pick<CarbonTooltipProps<ElementType>, 'align' | 'label'> & { enabled?: boolean };

const Tooltip: FunctionComponent<TooltipProps> = ({
  children,
  className,
  align = 'bottom',
  enabled = true,
  ...props
}) =>
  enabled ? (
    <ClassPrefix prefix="carbon">
      <CarbonTooltip className={cn('pri-tooltip', className)} align={align} {...props}>
        <button className="trigger-button" type="button">
          {children}
        </button>
      </CarbonTooltip>
    </ClassPrefix>
  ) : (
    (children as JSX.Element)
  );

export default Tooltip;
