import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '~/components/shared/Loader';

import { EMPLOYEE_SESSION_BASE_ROUTE_LINK } from '~/config/routes';
import { PrimoEmployeeContext } from '~/providers/primo/PrimoEmployeeProvider';

const EmployeeLoginCallbackPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const [hash] = useState(window.location.hash);
  const { slug } = useParams();
  const { t } = useTranslation();
  const {
    isAuthenticated,
    setAccessToken,
    setEmployeeConnectionError,
    employeeConnectionError,
    removeEmployeeConnectionError,
  } = useContext(PrimoEmployeeContext);

  useEffect(() => {
    if (employeeConnectionError) {
      const toastError = toast.error(<span>{t(employeeConnectionError, employeeConnectionError)}</span>, {
        toastId: 'employeeConnectionError',
      });
      toast.onChange(e => {
        if (e.id === toastError && e.status === 'removed') removeEmployeeConnectionError();
      });
    }
  }, [employeeConnectionError, removeEmployeeConnectionError, t]);

  useEffect(() => {
    if (isAuthenticated && slug) {
      navigate(EMPLOYEE_SESSION_BASE_ROUTE_LINK(slug), { replace: true });
    }
  }, [isAuthenticated, navigate, slug]);

  useEffect(() => {
    if (isAuthenticated) {
      return;
    }

    if (hash.match(/^#token/)) {
      const params = new URLSearchParams(hash.slice(1));
      const accessToken = params.get('token');
      if (accessToken) {
        setAccessToken(accessToken);
        return;
      }
    }

    if (hash.match(/^#error/)) {
      const params = new URLSearchParams(hash.slice(1));
      const error = params.get('error');

      setEmployeeConnectionError(error ? `errors.login_employee_page.${error}` : 'errors.login.unable_to_login');
    }
  }, [isAuthenticated, hash, setEmployeeConnectionError, setAccessToken, navigate, slug]);

  return <Loader />;
};

export default EmployeeLoginCallbackPage;
