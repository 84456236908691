import React from 'react';

const RocketIcon: SvgComponent = ({ className, width = 20 }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.55493 14.5576L9.55329 9.55926L10.4372 10.4431L5.43882 15.4415L4.55493 14.5576Z" fill="black" />
    <path
      d="M10.6249 18.75C10.5457 18.7502 10.4673 18.7354 10.3936 18.7063C10.2912 18.6654 10.2015 18.5983 10.1334 18.5116C10.0654 18.4248 10.0215 18.3217 10.0061 18.2125L9.38114 13.8375L10.6311 13.6625L11.0999 16.9563L13.1249 15.325V10.625C13.1244 10.5428 13.1402 10.4612 13.1713 10.3851C13.2024 10.3089 13.2482 10.2397 13.3061 10.1813L15.8499 7.63751C16.374 7.11692 16.7897 6.49755 17.073 5.81525C17.3562 5.13295 17.5013 4.40126 17.4999 3.66251V2.50001H16.3374C15.5986 2.4986 14.8669 2.64371 14.1846 2.92693C13.5023 3.21015 12.883 3.62586 12.3624 4.15001L9.81864 6.69376C9.76024 6.75168 9.69098 6.79751 9.61483 6.82862C9.53868 6.85972 9.45714 6.87548 9.37489 6.87501H4.67489L3.04364 8.91251L6.33739 9.38126L6.16239 10.6313L1.78739 10.0063C1.67823 9.99095 1.57506 9.94704 1.48834 9.87898C1.40163 9.81093 1.33446 9.72115 1.29364 9.61876C1.25231 9.51565 1.23922 9.40339 1.25569 9.29354C1.27217 9.18368 1.31763 9.08021 1.38739 8.99376L3.88739 5.86876C3.94496 5.79395 4.01874 5.73316 4.10317 5.69094C4.1876 5.64873 4.2805 5.62618 4.37489 5.62501H9.11864L11.4749 3.26251C12.1119 2.62195 12.8697 2.11422 13.7044 1.76875C14.5391 1.42329 15.434 1.24697 16.3374 1.25001H17.4999C17.8314 1.25001 18.1494 1.3817 18.3838 1.61612C18.6182 1.85055 18.7499 2.16849 18.7499 2.50001V3.66251C18.7529 4.56586 18.5766 5.46083 18.2311 6.29553C17.8857 7.13022 17.3779 7.88803 16.7374 8.52501L14.3749 10.8813V15.625C14.3745 15.7189 14.3529 15.8115 14.3118 15.8959C14.2706 15.9803 14.211 16.0543 14.1374 16.1125L11.0124 18.6125C10.9024 18.7007 10.7659 18.7492 10.6249 18.75Z"
      fill="black"
    />
  </svg>
);

export default RocketIcon;
