import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Step } from '~/components/shared/Stepper';
import { EmployeeEnrollmentStepperContext } from '../../context';
import { StepperContext } from '~/components/shared/Stepper/_components/StepperContext';
import CardLink from '~/components/featured/cards/CardLink';
import MilestoneIcon from '~/components/icons/milestone';
import Flex from '~/components/shared/shaping/Flex';
import { Title } from '~/components/shared/typography';
import EnrollmentCardList from '~/components/featured/cards/EnrollmentCardList';
import { useNavigatorPlatorm } from '~/hooks/useNavigatorPlatform';
import Loader from '~/components/shared/Loader';
import Notice from '~/components/shared/Notice';
import { InstallerCard } from './_part/InstallerCard';
import InfoIcon from '~/components/icons/info';

const DownloadInstallerStep: FunctionComponent = () => {
  const { onFinish, contextData, enrollmentLinks, refreshEnrollmentLinks, refreshEnrollmentLinksLoading } = useContext(
    EmployeeEnrollmentStepperContext,
  );
  const { setFooterProps } = useContext(StepperContext);
  const { t } = useTranslation();
  const navigatorPlaform = useNavigatorPlatorm();

  const { selectedDevice } = contextData;

  const enrollmentLinksForPlatform = useMemo(
    () => enrollmentLinks?.links.find(link => link.platform === selectedDevice?.platform),
    [enrollmentLinks, selectedDevice],
  );
  const navigatorPlatformDifferentFromSelectedPlatform =
    selectedDevice?.platform && navigatorPlaform !== selectedDevice?.platform;

  const downloadLinksAvailable =
    (!!selectedDevice && !!enrollmentLinksForPlatform?.urls[0]) ||
    (!selectedDevice && enrollmentLinks?.links?.flatMap(link => link.urls).every(urlItem => !!urlItem));

  const onNext = useCallback(async () => {
    await onFinish();

    return true;
  }, [onFinish]);

  useEffect(() => {
    refreshEnrollmentLinks();

    setFooterProps({
      onNext,
      disabledNextButton: !downloadLinksAvailable,
      nextLabel: t(
        `steppers.enrollment_stepper.next_button_label_${!downloadLinksAvailable ? 'complete_enrollment' : 'quit'}`,
      ),
    });
  }, [setFooterProps, onNext, refreshEnrollmentLinks, downloadLinksAvailable, t]);

  return (
    <Step size="lg">
      <Flex direction="column" gap={7} fullWidth align="start" justify="center">
        <Title>{t('titles.enrollment_stepper.download_installer.title')}</Title>
        <Flex className="pri-mt-2" direction="column" gap={7} fullWidth>
          {!selectedDevice ? (
            <EnrollmentCardList
              loading={refreshEnrollmentLinksLoading}
              packageRequestedAt={enrollmentLinks?.lastBuildRequestedAt}
              averageBuildTime={enrollmentLinks?.averageBuildTimeInMinutes}
              items={enrollmentLinks?.links || []}
              onRefreshLinksEvent={async () => {
                await refreshEnrollmentLinks();
              }}
            />
          ) : (
            <>
              {navigatorPlatformDifferentFromSelectedPlatform && (
                <Notice
                  className="pri-mb-2"
                  icon={<InfoIcon />}
                  variant="plain-info"
                  title={t('notices.enrollment_stepper.platform_mismatch_title', {
                    platform: selectedDevice?.platform,
                  })}
                  text={t('notices.enrollment_stepper.platform_mismatch_text')}
                />
              )}
              <Flex direction="row" gap={7}>
                {!enrollmentLinksForPlatform && refreshEnrollmentLinksLoading && <Loader spacing="md" />}
                {enrollmentLinksForPlatform?.urls.map(item => (
                  <InstallerCard
                    key={item.type}
                    platform={enrollmentLinksForPlatform.platform}
                    item={item}
                    loading={refreshEnrollmentLinksLoading}
                    packageRequestedAt={enrollmentLinks?.lastBuildRequestedAt}
                    averageBuildTime={enrollmentLinks?.averageBuildTimeInMinutes}
                    onRefreshLinksEvent={async () => {
                      await refreshEnrollmentLinks();
                    }}
                  />
                ))}
              </Flex>
            </>
          )}
          <Flex className="pri-mt-2" direction="column" gap={6} fullWidth>
            <CardLink
              icon={<MilestoneIcon />}
              text={t('texts.enrollment_stepper.download_installer.how_to_links.enroll')}
              onClick={() => {
                window.open(
                  'https://support.getprimo.com/fr/articles/8911815-fr-enregistrez-votre-ordinateur-dans-primo-fleetdm',
                  '_blank',
                );
              }}
            />
          </Flex>
        </Flex>
      </Flex>
    </Step>
  );
};

export default DownloadInstallerStep;
