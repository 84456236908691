import React from 'react';

const WipeIcon: SvgComponent = ({ className, width = 24, color = 'black' }) => (
  <svg className={className} width={width} height={width} viewBox="0 0 26 26">
    <path d="M21.125 16.25H16.25V14.625H21.125V16.25Z" fill={color} />
    <path d="M24.375 22.75H19.5V21.125H24.375V22.75Z" fill={color} />
    <path d="M22.75 19.5H17.875V17.875H22.75V19.5Z" fill={color} />
    <path
      d="M13.8151 16.25C13.8093 15.5644 13.6264 14.892 13.2841 14.2979C12.9418 13.7038 12.4517 13.2084 11.8615 12.8595L17.8752 2.4375L16.4689 1.625L10.3135 12.2899C9.55795 12.121 8.77209 12.1443 8.02785 12.3576C7.28361 12.571 6.60474 12.9675 6.05334 13.511C3.01166 16.445 3.24721 23.3042 3.25834 23.595C3.26674 23.8048 3.35603 24.0032 3.50751 24.1486C3.65898 24.294 3.86087 24.3752 4.07084 24.375H16.2512C16.4217 24.375 16.5879 24.3213 16.7263 24.2216C16.8646 24.1219 16.9681 23.9812 17.022 23.8194C17.0759 23.6577 17.0776 23.483 17.0267 23.3202C16.9759 23.1575 16.8751 23.0148 16.7387 22.9125C13.8627 20.7544 13.8151 16.2936 13.8151 16.25ZM9.69334 13.8101C10.3436 13.8173 10.9662 14.0748 11.4314 14.5293C11.8966 14.9837 12.1687 15.6 12.191 16.25C12.191 16.281 12.1926 16.419 12.2047 16.6309L7.41143 14.4992C7.72715 14.2389 8.09146 14.0439 8.4832 13.9256C8.87495 13.8073 9.2863 13.7681 9.69334 13.8101ZM12.5529 22.75C11.9036 22.0887 11.4898 21.2322 11.3752 20.3125H9.75021C9.80848 21.1773 10.0787 22.0144 10.537 22.75H8.73061C8.39644 21.6956 8.19311 20.604 8.12521 19.5H6.50021C6.55267 20.6007 6.73406 21.6915 7.04069 22.75H4.87521C4.9004 21.2579 5.11084 17.9627 6.33991 15.8009L12.4605 18.5228C12.7548 20.0497 13.3821 21.493 14.2976 22.75H12.5529Z"
      fill={color}
    />
  </svg>
);

export default WipeIcon;
