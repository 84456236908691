import type { ReactElement } from 'react';
import React from 'react';

import Flex from '~/components/shared/shaping/Flex';
import { Text, Title } from '~/components/shared/typography';
import Message from '~/components/shared/Message';

export type MessageProps = {
  title: string;
  text: string;
  icon?: ReactElement<SvgComponentProps>;
  variant?:
    | 'info'
    | 'success'
    | 'warning'
    | 'danger'
    | 'plain-info'
    | 'plain-success'
    | 'plain-warning'
    | 'plain-danger';
};

const Notice: FunctionComponent<MessageProps> = ({ title, text, variant, icon, className }) => (
  <Message variant={variant} className={className}>
    <Flex direction="column" gap={2}>
      <Flex direction="row" gap={2} className="pri-flex-align-center">
        {icon}
        <Title level={5}>{title}</Title>
      </Flex>
      <Text variant="muted" weight="light" size="sm">
        {text}
      </Text>
    </Flex>
  </Message>
);

export default Notice;
