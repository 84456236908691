import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import type { Option, Value } from './types';

export const useHandleOnChange = <Val extends Value>(
  customOptions: Option<Val>[],
  setCustomOptions: (o: Option<Val>[]) => void,
  setSelectedOption: (o: Val) => void,
  getOptionValue?: (option?: Val) => string | undefined,
  onChange?: (o?: Readonly<null | Val | Val[]>) => void,
  maxOptions?: number,
) => {
  const { t } = useTranslation();
  return useCallback(
    (controllerOnChange: (val: string | Val | Val[]) => void, value: Readonly<null | Option<Val> | Option<Val>[]>) => {
      const { value: newValue } = value as { value: Val };
      setSelectedOption(newValue);
      onChange?.(newValue);

      if (Array.isArray(value)) {
        if (maxOptions && value.length > maxOptions) {
          toast.error(<>{t('errors.max_options', { max: maxOptions })}</>);
          return null;
        }

        setCustomOptions(customOptions.filter(opt => value.map(item => item.value).includes(opt.value)));
        return controllerOnChange(value.map(item => getOptionValue?.(item.value) || item.value));
      }

      return controllerOnChange(getOptionValue?.(newValue) || newValue);
    },
    [setSelectedOption, onChange, getOptionValue, maxOptions, setCustomOptions, customOptions, t],
  );
};
