import React, { useMemo } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Flex from '~/components/shared/shaping/Flex';
import Tile from '~/components/shared/Tile';
import { Title } from '~/components/shared/typography';

import type { DevicePlatform, EnrollmentUrlItem } from '~/graphql/schema';

import './index.scss';

import DevicePlatformIconV2 from '~/components/featured/display/devices/DevicePlatformIcon';
import Button from '~/components/shared/buttons/Button';
import Loader from '~/components/shared/Loader';
import ThrottledButton from '~/components/shared/buttons/ThrottledButton';

type InstallerCardProps = {
  platform: DevicePlatform;
  item: EnrollmentUrlItem;
  packageRequestedAt: Date | null;
  averageBuildTime: number | undefined;
  loading?: boolean;
  onRefreshLinksEvent: () => void;
};

export const InstallerCard: FunctionComponent<InstallerCardProps> = ({
  className,
  item: { url, type: packageType },
  packageRequestedAt,
  loading,
  platform,
  averageBuildTime,
  onRefreshLinksEvent,
}) => {
  const { t } = useTranslation();
  const packageShouldBeReadyAt = packageRequestedAt
    ? moment(packageRequestedAt).utc().add(averageBuildTime, 'minutes').toDate()
    : null;
  const isPastBuildTime = useMemo(() => moment().utc().isSameOrAfter(packageShouldBeReadyAt), [packageShouldBeReadyAt]);

  const handleDownloadLink = (link: string | null | undefined) => {
    if (!link) return;
    window.open(link);
  };
  return (
    <Tile className={cn('pri-installer-main-card', className)}>
      <Flex direction="column" gap={4} align="center" justify="center" fullWidth>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Flex direction="column" gap={2} align="center">
              <DevicePlatformIconV2 className="pri-installer-main-icon" platform={platform} width={24} />
              <Title level={5}>
                {t(
                  `titles.enrollment_stepper.download_installer.${platform.toLowerCase()}${
                    packageType ? `_${packageType.toLowerCase()}` : ''
                  }`,
                )}
              </Title>
              {!url && !isPastBuildTime && (
                <ThrottledButton
                  variant="dark-outline"
                  size="lg"
                  timerStartedAt={packageRequestedAt ?? undefined}
                  onFinish={() => {
                    onRefreshLinksEvent();
                  }}
                  timer={moment(packageShouldBeReadyAt).utc().diff(moment.now(), 'seconds')}
                  text={t('buttons.enrollment_stepper.building')}
                />
              )}
            </Flex>
            {!url && isPastBuildTime && (
              <Button variant="primary" size="lg" onClick={() => onRefreshLinksEvent()}>
                {t('buttons.enrollment_stepper.request')}
              </Button>
            )}

            {url && (
              <Button variant="primary" size="lg" onClick={() => handleDownloadLink(url)}>
                {t('buttons.enrollment_stepper.download')}
              </Button>
            )}
          </>
        )}
      </Flex>
    </Tile>
  );
};
