import React from 'react';
import { useTranslation } from 'react-i18next';
import type { UseFormReturn } from 'react-hook-form/dist/types';
import { Text } from '~/components/shared/typography';
import Flex from '~/components/shared/shaping/Flex';
import Input from '~/components/shared/forms/Input';
import type { ChooseShippingAndBillingStepFields } from '~/features/steppers/OrderStepper/_steps/ChooseShippingAndBillingStep';

type AddCommentProps = {
  form: UseFormReturn<ChooseShippingAndBillingStepFields>;
};

const AddComment: FunctionComponent<AddCommentProps> = ({ form }) => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = form;

  return (
    <Flex direction="column">
      <Text className="pri-mb-3 pri-mt-9" size="lg" weight="medium">
        {t('steppers.onboarding.anything_to_add')}
      </Text>
      <Input name="companyComment" label={t('forms.labels.comment')} register={register} errors={errors} />
    </Flex>
  );
};

export default AddComment;
