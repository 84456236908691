import { useEffect, useState } from 'react';
import type { RefObject } from 'react';

export const useScrollHeight = (collapsableRef: RefObject<HTMLDivElement>) => {
  const [scrollHeight, setScrollHeight] = useState<number | undefined>();

  useEffect(() => {
    // We are using observer since cards can be hidden (like Card in hidden Tab)
    // Observers are used to update the scrollHeight when the card is shown
    const observer = new ResizeObserver(() => {
      setScrollHeight(collapsableRef?.current?.scrollHeight);
    });

    if (collapsableRef.current) {
      observer.observe(collapsableRef.current);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [collapsableRef]);

  return scrollHeight;
};
