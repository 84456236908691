import React, { useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import type { Control, FieldValues, Path } from 'react-hook-form';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import type { SearchEmployeesForSelectQuery } from '~/graphql/schema';
import { useSearchEmployeesForSelectLazyQuery } from '~/graphql/schema';
import { Truthy } from '~/utils/truthy';
import Select from '~/components/shared/forms/Select';
import Bloc from '~/components/shared/shaping/Bloc';
import Flex from '~/components/shared/shaping/Flex';

type PartialEmployee = { fullName?: string; id?: string | null };

type EmployeeSelectProps<T extends FieldValues> = {
  className?: string;
  name: Path<T>;
  control: Control<T>;
  companyId: string;
  onEmployeesLoad?: (employees: PartialEmployee[]) => void;
  showEmptyOption?: boolean;
  showNotArrived?: boolean;
  defaultValue?: PartialEmployee | null;
  errorMessage?: string | undefined;
  label?: string | null;
};

const getEmployeeData = (data: SearchEmployeesForSelectQuery | undefined) => {
  if (data?.getEmployees?.__typename === 'QueryGetEmployeesSuccess') {
    return {
      employees: data.getEmployees.data.list
        .map(item => ({ id: item.employee?.id || '', fullName: item.employee?.fullName || '' }))
        .filter(Truthy),
      error: null,
    };
  }
  if (data?.getEmployees?.__typename === 'QueryGetEmployeesError') {
    return { employees: undefined, error: data.getEmployees.reasons.join('; ') };
  }
  return { employees: undefined, error: null };
};

const EmployeeSelectV2 = <T extends FieldValues>({
  label,
  companyId,
  showEmptyOption,
  errorMessage,
  onEmployeesLoad,
  className,
  showNotArrived = false,
  ...props
}: EmployeeSelectProps<T>) => {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const [search, setSearch] = useState('');
  const [getEmployees, { data, loading }] = useSearchEmployeesForSelectLazyQuery({ fetchPolicy: 'no-cache' });

  const options = useMemo(() => {
    const { employees = [] } = getEmployeeData(data);
    onEmployeesLoad?.(employees);
    const employeesOptions = new Map<string, PartialEmployee>(employees.map(employee => [employee.id, employee]));

    return showEmptyOption ? [{ id: '', fullName: '-' }, ...employeesOptions.values()] : [...employeesOptions.values()];
  }, [data, showEmptyOption, onEmployeesLoad]);

  useEffect(() => {
    getEmployees({ variables: { search, companyId, showNotArrived } });
  }, [companyId, search, getEmployees, showNotArrived]);

  const debouncedSetSearch = useMemo(() => debounce(setSearch, 300), [setSearch]);

  return (
    <Flex direction="column" className={className}>
      <Select
        label={label || t('forms.labels.employee')}
        options={options}
        getOptionLabel={option => option.fullName || '-'}
        getOptionValue={option => option?.id || ''}
        onInputChange={debouncedSetSearch}
        isLoading={loading}
        menuIsOpen={opened}
        onFocus={() => !opened && setOpened(true)}
        onBlur={() => opened && setOpened(false)}
        {...props}
      />
      <Bloc className={cn({ 'invalid-feedback': errorMessage })}>{errorMessage && t(errorMessage)}</Bloc>
    </Flex>
  );
};

export default EmployeeSelectV2;
