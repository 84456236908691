import React from 'react';

const AddIcon: SvgComponent = ({ className, width = 24, color = 'black' }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.5 3.5V0H3.5V3.5H0V4.5H3.5V8H4.5V4.5H8V3.5H4.5Z" fill={color} />
  </svg>
);

export default AddIcon;
