import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Steps } from '~/components/shared/Stepper';

import EulaStep from './_steps/EulaStep';
import { PrimoAdminContext } from '~/providers/primo/PrimoAdminProvider';

const InitialSetupStepperContent: FunctionComponent = () => {
  const { t } = useTranslation();
  const { companyInitialSetup } = useContext(PrimoAdminContext);

  if (!companyInitialSetup) return null;

  const { eulaAccepted } = companyInitialSetup;

  return <Steps title={t('titles.initial_setup_initial_step')}>{!eulaAccepted ? <EulaStep /> : undefined}</Steps>;
};

export default InitialSetupStepperContent;
