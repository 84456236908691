import React from 'react';
import cn from 'classnames';

import './index.scss';

export type CardItemProps = {
  children: ReactChildren;
  variant?: 'full' | 'contain';
};

const CardItem: FunctionComponent<CardItemProps> = ({ children, className, variant = 'full' }) => (
  <div className={cn('pri-card-item', `--${variant}`, className)}>{children}</div>
);

export default CardItem;
