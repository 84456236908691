import { ProductType } from '~/graphql/schema';

const accessoriesType = [
  String(ProductType.CableAdapter),
  String(ProductType.Charger),
  String(ProductType.Ergonomic),
  String(ProductType.Headset),
  String(ProductType.Hub),
  String(ProductType.Keyboard),
  String(ProductType.LaptopCase),
  String(ProductType.Monitor),
  String(ProductType.Mouse),
  String(ProductType.Mousepad),
  String(ProductType.Speaker),
  String(ProductType.StorageDevice),
  String(ProductType.Webcam),
  String(ProductType.ShippingMaterial),
];

export default (product: { type: string }) => accessoriesType.includes(product.type);
