import React from 'react';

const AppleIcon: SvgComponent = ({ className, width = 24, color = '#1E1E1E' }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 12 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.3597 0.562424C7.34419 0.864022 6.52619 1.63363 6.13703 2.65366C6.00105 3.01011 5.95776 3.23359 5.95606 3.58787C5.95523 3.75646 5.96238 3.90241 5.97189 3.9122C6.01033 3.95172 6.29596 3.94466 6.51613 3.89873C7.10802 3.77529 7.73162 3.39166 8.13538 2.90257C8.61965 2.31593 8.89951 1.62832 8.94548 0.91216C8.95287 0.796685 8.95226 0.656708 8.9441 0.601103L8.92924 0.5L8.74514 0.50132C8.60977 0.502294 8.5077 0.518463 8.3597 0.562424ZM8.31732 4.12992C7.86989 4.20877 7.62619 4.28299 7.00686 4.52905C6.46646 4.74377 6.29691 4.79256 6.09232 4.7923C5.90247 4.79204 5.72761 4.74888 5.40818 4.6234C5.27748 4.57206 5.04247 4.47959 4.88596 4.41793C4.32536 4.19702 3.91257 4.12319 3.43973 4.15925C2.62955 4.221 1.85717 4.58327 1.26454 5.17948C0.591482 5.85657 0.20236 6.69728 0.0392136 7.82677C-0.0132754 8.19021 -0.0130211 9.09046 0.0396798 9.51506C0.150167 10.405 0.363048 11.221 0.70127 12.0513C1.00535 12.7977 1.29903 13.3137 1.84465 14.0605C2.45208 14.8918 2.89748 15.3025 3.35693 15.4548C3.47391 15.4936 3.54557 15.4999 3.87055 15.4999C4.23367 15.4999 4.25605 15.4973 4.45079 15.4336C4.56221 15.3971 4.7517 15.3244 4.87187 15.272C5.4833 15.0052 5.78041 14.9314 6.25125 14.9294C6.74335 14.9273 7.02601 14.991 7.55093 15.2224C7.86041 15.3588 8.05435 15.4281 8.23924 15.4684C8.439 15.5119 8.91837 15.5102 9.08766 15.4654C9.66841 15.3117 10.1664 14.8574 10.865 13.844C11.2346 13.308 11.5067 12.8173 11.7294 12.2856C11.8332 12.0378 12.0113 11.5377 11.9995 11.5273C11.9964 11.5246 11.9414 11.4958 11.8773 11.4632C11.2322 11.1358 10.6603 10.5656 10.3569 9.94727C10.2454 9.72026 10.169 9.50099 10.1042 9.22286C10.0258 8.88624 10.0112 8.2793 10.0727 7.91144C10.1859 7.23446 10.4891 6.63994 10.9767 6.13875C11.1576 5.95285 11.3902 5.75549 11.5765 5.62982L11.6246 5.59735L11.489 5.42419C10.9357 4.71734 10.1734 4.27879 9.27026 4.14769C9.06573 4.11799 8.4501 4.10652 8.31732 4.12992Z"
      fill={color}
    />
  </svg>
);

export default AppleIcon;
