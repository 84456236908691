import React from 'react';
import { useTranslation } from 'react-i18next';
import Pill from '~/components/shared/Pill';
import { EnrollmentType } from '~/graphql/schema';
import ContinuousDeployment from '~/components/icons/continuousDeployment';
import { useCompany } from '~/hooks/useCompany';

export interface DeviceEnrollmentTypeBadgeProps {
  enrollmentType: EnrollmentType | null | undefined;
}

export const DeviceEnrollmentTypePill: FunctionComponent<DeviceEnrollmentTypeBadgeProps> = ({ enrollmentType }) => {
  const { t } = useTranslation();
  const company = useCompany();

  if (!enrollmentType || !company?.hasMDM) return null;

  if (enrollmentType === EnrollmentType.Automatic) {
    return (
      <Pill color="blue" icon={<ContinuousDeployment width={16} />}>
        {t('enums.enrollment_type.automatic')}
      </Pill>
    );
  }

  return <Pill color="grey">{t('enums.enrollment_type.manual')}</Pill>;
};

export default DeviceEnrollmentTypePill;
