import React from 'react';

const ShoppingIcon: SvgComponent = ({ className, width = 24 }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M17 24H21V28H17V24ZM24 24H28V28H24V24ZM17 17H21V21H17V17ZM24 17H28V21H24V17Z" fill="black" />
    <path
      d="M28 11H22V7C22 5.3 20.7 4 19 4H13C11.3 4 10 5.3 10 7V11H4C3.4 11 3 11.4 3 12C3 12.1 3 12.1 3 12.2L4.9 24.3C5 25.3 5.9 26 6.9 26H15V24H6.9L5.2 13H28V11ZM12 7C12 6.4 12.4 6 13 6H19C19.6 6 20 6.4 20 7V11H12V7Z"
      fill="black"
    />
  </svg>
);

export default ShoppingIcon;
