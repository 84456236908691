import type { TFunction } from 'i18next';
import * as zod from 'zod';

export const selfSignUpWithPasswordSchema = (t: TFunction) =>
  zod.object({
    email: zod.string().email(t('forms.validations.invalid_email')),
    password: zod
      .string()
      .min(8, t('forms.validations.password_min_length'))
      .refine(password => {
        const lowerCaseRegEx = /[a-z]/;
        const upperCaseRegEx = /[A-Z]/;
        const digitRegEx = /\d/;

        const conditions = [lowerCaseRegEx.test(password), upperCaseRegEx.test(password), digitRegEx.test(password)];

        return conditions.every(condition => condition);
      }, t('forms.validations.password_strength')),
  });

export type SelfSignUpWithPasswordSchemaFields = zod.infer<ReturnType<typeof selfSignUpWithPasswordSchema>>;
