import React from 'react';
import { OffboardingStepperProvider } from './context';
import OffboardingStepperContent from './stepper';
import { Stepper } from '~/components/shared/Stepper';
import { useCompany } from '~/hooks/useCompany';

export type OffboardingStepperProps = {
  onClose: () => void;
  employeeId?: string | null;
};

const OffboardingStepper: FunctionComponent<OffboardingStepperProps> = ({ onClose, employeeId }) => {
  const company = useCompany();

  if (!company) {
    return null;
  }
  return (
    <Stepper onClose={onClose}>
      <OffboardingStepperProvider companyId={company.id} employeeId={employeeId}>
        <OffboardingStepperContent employeeId={employeeId} />
      </OffboardingStepperProvider>
    </Stepper>
  );
};

export default OffboardingStepper;
