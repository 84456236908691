import React, { useMemo } from 'react';
import { Checkbox as CarbonCheckbox, ClassPrefix } from '@carbon/react';
import cn from 'classnames';
import type { ReactNode } from 'react';
import type { UseFormRegister } from 'react-hook-form/dist/types/form';
import type { FieldErrors } from 'react-hook-form/dist/types/errors';
import type { FieldValues, Path } from 'react-hook-form';

import './index.scss';

export type CheckboxProps<FV extends FieldValues> = {
  name: string;
  className?: string;
  label?: string | ReactNode;
  helperText?: string | ReactNode;
  disabled?: boolean;
  required?: boolean;
  defaultChecked?: boolean;
  value?: string;

  register?: UseFormRegister<FV>;
  errors?: FieldErrors;
};

const Checkbox = <FV extends FieldValues>({ label, register, errors, className, ...props }: CheckboxProps<FV>) => {
  const id = useMemo(() => `input-${Math.random().toFixed(10).slice(2, 10)}`, []);
  const error = errors?.[props.name];

  return (
    <ClassPrefix prefix="carbon">
      <CarbonCheckbox
        className={cn('pri-checkbox', className)}
        id={id}
        labelText={label ?? ''}
        invalid={!!error}
        invalidText={error?.message?.toString() || ''}
        {...register?.(props.name as Path<FV>)}
        {...props}
      />
    </ClassPrefix>
  );
};

export default Checkbox;
