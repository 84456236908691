import React from 'react';
import { OnboardingStepperProvider } from './context';
import OnboardingStepperContent from './stepper';
import { Stepper } from '~/components/shared/Stepper';

export type OnboardingStepperProps = {
  onClose: () => void;
  employeeId?: string | null;
};

const OnboardingStepper: FunctionComponent<OnboardingStepperProps> = ({ onClose, employeeId }) => (
  <Stepper onClose={onClose}>
    <OnboardingStepperProvider employeeId={employeeId}>
      <OnboardingStepperContent employeeId={employeeId} />
    </OnboardingStepperProvider>
  </Stepper>
);

export default OnboardingStepper;
