import React from 'react';

const WarningRoundedIcon: SvgComponent = ({ className, width = 24, color = '#F1C21B' }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Warning--filled">
      <path
        d="M12 1.5C9.9233 1.5 7.89323 2.11581 6.16652 3.26957C4.4398 4.42332 3.09399 6.0632 2.29927 7.98182C1.50455 9.90045 1.29661 12.0116 1.70176 14.0484C2.1069 16.0852 3.10693 17.9562 4.57538 19.4246C6.04383 20.8931 7.91476 21.8931 9.95156 22.2982C11.9884 22.7034 14.0996 22.4955 16.0182 21.7007C17.9368 20.906 19.5767 19.5602 20.7304 17.8335C21.8842 16.1068 22.5 14.0767 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5ZM12 21C10.22 21 8.47992 20.4722 6.99987 19.4832C5.51983 18.4943 4.36628 17.0887 3.68509 15.4442C3.0039 13.7996 2.82567 11.99 3.17294 10.2442C3.5202 8.49836 4.37737 6.89471 5.63604 5.63604C6.89472 4.37737 8.49836 3.5202 10.2442 3.17293C11.99 2.82567 13.7996 3.0039 15.4442 3.68508C17.0887 4.36627 18.4943 5.51983 19.4832 6.99987C20.4722 8.47991 21 10.22 21 12C21 14.3869 20.0518 16.6761 18.364 18.364C16.6761 20.0518 14.387 21 12 21Z"
        fill={color}
      />
      <path
        d="M11.25 6H12.75V14.25H11.25V6ZM12 16.5C11.7775 16.5 11.56 16.566 11.375 16.6896C11.19 16.8132 11.0458 16.9889 10.9606 17.1945C10.8755 17.4 10.8532 17.6262 10.8966 17.8445C10.94 18.0627 11.0472 18.2632 11.2045 18.4205C11.3618 18.5778 11.5623 18.685 11.7805 18.7284C11.9988 18.7718 12.225 18.7495 12.4305 18.6644C12.6361 18.5792 12.8118 18.435 12.9354 18.25C13.059 18.065 13.125 17.8475 13.125 17.625C13.125 17.3266 13.0065 17.0405 12.7955 16.8295C12.5845 16.6185 12.2984 16.5 12 16.5Z"
        fill={color}
      />
    </g>
  </svg>
);

export default WarningRoundedIcon;
