import moment from 'moment/moment';
import type { OnboardingStepperContextData } from '../types';
import type useCreateOnboarding from './_queries/useCreateOnboarding';
import isDevice from '~/utils/products/isDevice';

export default async (
  companyId: string,
  contextData: OnboardingStepperContextData,
  { createOnboarding }: ReturnType<typeof useCreateOnboarding>,
) => {
  const { employee, equipments } = contextData;
  const { inventory: inventoryEquipments } = equipments || {};

  if (!employee) return new Error();

  const assignedFromInventory = (inventoryEquipments || []).reduce<{ deviceIds: string[]; accessoryIds: string[] }>(
    (prev, next) => {
      if (isDevice({ type: next.item.type || '' })) return { ...prev, deviceIds: [...prev.deviceIds, next.item.id] };
      return { ...prev, accessoryIds: [...prev.accessoryIds, next.item.id] };
    },
    { deviceIds: [], accessoryIds: [] },
  );

  const { data } = await createOnboarding({
    variables: {
      orderId: null,
      employee: {
        id: employee.id,
        phone: employee.phone || null,
        firstName: employee.firstName,
        lastName: employee.lastName,
        email: employee.email,
        personalEmail: employee.personalEmail || null,
        companyId,
      },
      onboarding: {
        startDate: employee.startDate ? moment(employee.startDate).format('YYYY-MM-DD') : null,
        automaticEnrollment: Boolean(employee.automaticEnrollment),
        sendToPersonalEmail: Boolean(employee.sendToPersonalEmail),
        apps: contextData.apps || [],
      },
      assignedFromInventory,
    },
  });

  if (data?.onboardEmployee?.__typename === 'MutationOnboardEmployeeSuccess') {
    return data.onboardEmployee.data;
  }

  return new Error();
};
