import { useCallback, useEffect, useMemo } from 'react';
import { ok, refuse } from '@primo/operation-result';
import type { EmployeeOffboardingStatus, GetOffboardingsQuery } from '~/graphql/schema';
import { useGetOffboardingsLazyQuery } from '~/graphql/schema';
import type { UseQueryOptions } from '~/hooks/queries/types';

type UseGetOffboardingsVariables = {
  companyId?: string;
  status?: EmployeeOffboardingStatus | Array<EmployeeOffboardingStatus>;
};

const useQueryGetOffboardings = (queryOptions: UseQueryOptions<UseGetOffboardingsVariables> = {}) => {
  const serializeOffboardingsData = useCallback((data: GetOffboardingsQuery | undefined) => {
    if (data?.getEmployeeOffboardings?.__typename === 'QueryGetEmployeeOffboardingsSuccess') {
      return ok(data.getEmployeeOffboardings.data);
    }
    if (data?.getEmployeeOffboardings?.__typename === 'QueryGetEmployeeOffboardingsError')
      return refuse(data.getEmployeeOffboardings.reasons);
    return refuse(['Unknown error']);
  }, []);

  const [query, { data: offboardingsData, loading: offboardingsLoading, called: offboardingsCalled }] =
    useGetOffboardingsLazyQuery();

  const offboardingsResult = useMemo(
    () => serializeOffboardingsData(offboardingsData),
    [serializeOffboardingsData, offboardingsData],
  );

  const getOffboardings = useCallback(
    async (variables: UseGetOffboardingsVariables) => {
      const result = await query({ variables });
      return serializeOffboardingsData(result.data);
    },
    [query, serializeOffboardingsData],
  );

  useEffect(() => {
    if (queryOptions.runOnInit && !offboardingsCalled) getOffboardings(queryOptions);
  }, [getOffboardings, offboardingsCalled, queryOptions]);

  return useMemo(
    () => ({
      getOffboardings,
      offboardingsResult,
      offboardingsLoading,
      offboardingsCalled,
    }),
    [getOffboardings, offboardingsResult, offboardingsCalled, offboardingsLoading],
  );
};

export default useQueryGetOffboardings;
