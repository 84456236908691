import React from 'react';

const EditIcon: SvgComponent = ({ className, width = 12, color = '1E0918' }) => (
  <svg className={className} width={width} height={width} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.2 4C11.6 3.6 11.6 3 11.2 2.6L9.4 0.8C9 0.4 8.4 0.4 8 0.8L0.5 8.3V11.5H3.7L11.2 4ZM8.7 1.5L10.5 3.3L9 4.8L7.2 3L8.7 1.5ZM1.5 10.5V8.7L6.5 3.7L8.3 5.5L3.3 10.5H1.5Z"
      fill={color}
    />
  </svg>
);

export default EditIcon;
