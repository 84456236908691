import { useMemo } from 'react';
import { isEqual } from 'lodash';
import useMutationCreateOrUpdateCompanyAddress from '~/hooks/queries/companies/useMutationCreateOrUpdateCompanyAddress';
import { useCompany } from '~/hooks/useCompany';
import type { AddressItem } from '~/graphql/schema';
import { OrderShippingAddressChoice } from '~/graphql/schema';

const useUpdateAddressIfNeeded = () => {
  const { createOrUpdateCompanyAddress } = useMutationCreateOrUpdateCompanyAddress();
  const company = useCompany();

  return useMemo(
    () => async (shippingAddressType: OrderShippingAddressChoice, address: AddressItem) => {
      if (shippingAddressType === OrderShippingAddressChoice.New && company) {
        await createOrUpdateCompanyAddress({
          companyId: company.id,
          address: address as AddressItem,
        });
      }
      if (shippingAddressType === OrderShippingAddressChoice.Office) {
        const concernedAddress = company?.addresses.find(({ id }) => id === (address as AddressItem).id);
        if (!isEqual(concernedAddress, address)) {
          await createOrUpdateCompanyAddress({
            companyId: company?.id || '',
            address: address as AddressItem,
          });
        }
      }
    },
    [company, createOrUpdateCompanyAddress],
  );
};

export default useUpdateAddressIfNeeded;
