import './datadog';
import React from 'react';
import ReactDOM from 'react-dom/client';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-number-input/style.css';

import './index.scss';
import './config/i18n';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
