import type { ReactElement } from 'react';
import React from 'react';
import cn from 'classnames';

import './style.scss';
import Flex from '~/components/shared/shaping/Flex';
import { Title } from '../typography/Title';
import type { TitleProps } from '../typography/Title';
import Bloc from '../shaping/Bloc';
import { Text } from '~/components/shared/typography';

type BulletPoint = {
  title: string;
  titleLevel?: TitleProps['level'];
  description?: string;
  component?: ReactElement;
  disabled?: boolean;
};

export type BulletPointListProps = {
  bulletPoints: BulletPoint[];
  separator?: boolean;
  numbered?: boolean;
  children?: never;
};

const BulletPointList: FunctionComponent<BulletPointListProps> = ({
  className,
  bulletPoints,
  numbered = false,
  separator = false,
}) => (
  <Flex direction="column" className={cn(className, 'pri-bullet-point-list')} gap={6}>
    {bulletPoints.map((bulletPoint, index) => (
      <Flex direction="column" key={bulletPoint.title}>
        <Flex
          direction="row"
          gap={4}
          className={cn('pri-bullet-point', bulletPoint.disabled ? 'disabled' : '')}
          align="start"
        >
          <Bloc className={cn('pri-bullet-point-dot', { 'pri-bullet-point-numbered': numbered })}>
            {numbered ? (
              <Text weight="medium" size="sm">
                {index + 1}
              </Text>
            ) : (
              <>&nbsp;</>
            )}
          </Bloc>
          <Flex className="pri-bullet-point-item" direction="column" gap={1} fullWidth>
            <Title level={bulletPoint.titleLevel || 5}>{bulletPoint.title}</Title>
            {bulletPoint.description && (
              <Text weight="light" variant="light-muted">
                {bulletPoint.description}
              </Text>
            )}
            {bulletPoint.component}
          </Flex>
        </Flex>
        {separator && index !== bulletPoints.length - 1 && <hr className="pri-bullet-point-separator" />}
      </Flex>
    ))}
  </Flex>
);

export default BulletPointList;
