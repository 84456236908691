import { useMemo } from 'react';
import { isEqual } from 'lodash';
import type { Option, Value } from './types';

export const useComputeValidOptions = <Val extends Value>(
  customOptions: Option<Val>[],
  options: Val[],
  selectedOption: Val | null,
  getOptionLabel?: (option: Val) => string,
) => {
  let validOptions = options;
  if (!options.find(option => isEqual(option, selectedOption))) {
    validOptions = options.concat(selectedOption || []);
  }

  return useMemo(
    () =>
      validOptions
        .map(option => {
          let optionLabel = String(option);
          if (getOptionLabel) optionLabel = getOptionLabel(option);
          if (typeof option === 'object' && 'label' in option) optionLabel = String(option.label);
          return {
            value: option,
            label: optionLabel,
          };
        })
        .concat(customOptions),
    [validOptions, customOptions, getOptionLabel],
  );
};
