import type { TFunction } from 'i18next';
import * as zod from 'zod';

export const chooseOrganizationSchema = (t: TFunction) =>
  zod.object({
    organization: zod.string().min(1, t('forms.validations.required')),
  });

export type OrganizationFormFields = zod.infer<ReturnType<typeof chooseOrganizationSchema>>;

export const signInSchema = (t: TFunction) =>
  zod.object({
    email: zod.string().min(1, t('forms.validations.required')).email(t('forms.validations.invalid_email')),
    password: zod.string().min(1, t('forms.validations.required')),
  });

export type SignInFormFields = zod.infer<ReturnType<typeof signInSchema>>;

export const resetPasswordRequestSchema = (t: TFunction) =>
  zod.object({
    email: zod.string().min(1, t('forms.validations.required')).email(t('forms.validations.invalid_email')),
  });

export type ResetPasswordRequestFormFields = zod.infer<ReturnType<typeof resetPasswordRequestSchema>>;
