import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useEffectOnce } from 'react-use';

import { Step } from '~/components/shared/Stepper';
import { StepperContext } from '~/components/shared/Stepper/_components/StepperContext';
import { Form } from '~/components/shared/forms/Form';
import Loader from '~/components/shared/Loader';
import Flex from '~/components/shared/shaping/Flex';
import { Text, Title } from '~/components/shared/typography';
import CardTile from '~/components/shared/CardTile';
import { DeviceNameV2 } from '~/components/shared/DeviceNameV2';
import DeviceTypeIconV2 from '~/components/featured/typeIcon/DeviceTypeIcon';
import Select from '~/components/shared/forms/Select';
import { DevicePlatform, EmployeeOffboardingDeviceRecycle, HandleDeviceData, MdmAction } from '~/graphql/schema';
import { Truthy } from '~/utils/truthy';
import { HasMDM, HexnodeLegacy } from '~/features/mdm';
import { useCompany } from '~/hooks/useCompany';

import type { OffboardingStepperContextData } from '../types';
import { OffboardingStepperContext } from '../context';
import DeviceTagsPills from '~/components/featured/pills/devicePills/DeviceTagsPills';
import Notice from '~/components/shared/Notice';
import { WarningIcon } from '~/components/icons';

type FieldsValue = {
  [key: string]: {
    handleDeviceData: HandleDeviceData;
    handleDeviceRecycle: EmployeeOffboardingDeviceRecycle;
  };
};

type DeviceOptionAvailability = {
  [key: string]: boolean;
};

const HandleDevicesStep: FunctionComponent = () => {
  const { t } = useTranslation();
  const company = useCompany();
  const { loading, setContextData, contextData } = useContext(OffboardingStepperContext);
  const { currentStep, setFooterProps, setCurrentStep } = useContext(StepperContext);
  const form = useForm<FieldsValue>({
    defaultValues: {
      ...contextData.employee?.devices.reduce<FieldsValue>(
        (prev, next) => ({
          ...prev,
          [next.id]: {
            handleDeviceData: HandleDeviceData.Nothing,
            handleDeviceRecycle: EmployeeOffboardingDeviceRecycle.Nothing,
          },
        }),
        {},
      ),
    },
  });
  const { control, getValues, watch } = form;

  const watchedOptions = watch();

  const shouldHideLockOptionForDevice = useMemo<DeviceOptionAvailability>(
    () =>
      Object.entries(watchedOptions).reduce(
        (prev, [key, value]) => ({
          ...prev,
          [key]:
            value.handleDeviceRecycle === EmployeeOffboardingDeviceRecycle.SellOrRecycle ||
            value.handleDeviceRecycle === EmployeeOffboardingDeviceRecycle.Donate,
        }),
        {},
      ),
    [watchedOptions],
  );

  const wipeOptionIsSelected = useMemo<DeviceOptionAvailability>(
    () =>
      Object.entries(watchedOptions).reduce(
        (prev, [key, value]) => ({
          ...prev,
          [key]: value.handleDeviceData === HandleDeviceData.SoftWipe,
        }),
        {},
      ),
    [watchedOptions],
  );

  const hasLockOptionV2 = useMemo(() => company?.mdmActions?.includes(MdmAction.Lock), [company]);
  const hasWipeOptionV2 = useMemo(() => company?.mdmActions?.includes(MdmAction.Wipe), [company]);

  const onNext = useCallback(async () => {
    const values = getValues();
    const devices = Object.entries(values)
      .map(([id, { handleDeviceData, handleDeviceRecycle }]) => {
        const device = contextData.employee?.devices.find(({ id: deviceId }) => deviceId === id);
        if (!device) return null;
        return {
          ...device,
          handleDeviceData,
          handleDeviceRecycle,
        };
      })
      .filter(Truthy);
    const newContextData: OffboardingStepperContextData = { ...contextData, devices };

    setContextData(newContextData);
    return true;
  }, [contextData, getValues, setContextData]);

  useEffect(() => {
    setFooterProps({
      onNext,
      nextLabel: t('buttons.next'),
    });
  }, [setFooterProps, onNext, t]);

  useEffectOnce(() => {
    if (!contextData?.employee?.devices?.length) {
      setCurrentStep(currentStep + 1);
    }
  });

  const devices = contextData.employee?.devices || [];

  return (
    <Step size="lg">
      <Flex className="pri-mb-9" direction="column" gap={2}>
        <Title level={2}>{t('steppers.offboarding.device_reallocation')}</Title>
        <Text weight="light">
          {t('steppers.offboarding.device_reallocation_description', {
            firstName: contextData?.employee?.firstName ?? '',
          })}
        </Text>
      </Flex>
      <Form form={form}>
        {loading ? (
          <Loader spacing="md" />
        ) : (
          <Flex direction="column" gap={6}>
            <Text className="pri-mb-3" size="lg" weight="medium">
              {t('steppers.offboarding.what_to_do_with_equipment')}
            </Text>
            <Flex direction="column" gap={9}>
              {devices.map((device, index) => (
                <Flex direction="column" gap={5} key={device.id}>
                  <CardTile
                    prefixIcon={<DeviceTypeIconV2 type={device.type} />}
                    title={<DeviceNameV2 {...device} withSerial />}
                    statusPill={<DeviceTagsPills device={device} />}
                  >
                    {wipeOptionIsSelected[device.id] &&
                      device.platform === DevicePlatform.Macos &&
                      !!device.activationLockEnabled &&
                      !device.computedActivationLockBypassKey && (
                        <Notice
                          variant="plain-danger"
                          icon={<WarningIcon width={16} />}
                          title={t('notices.permanent_lock_warning.title')}
                          text={t('notices.permanent_lock_warning.text', { employee: contextData.employee?.fullName })}
                        />
                      )}
                  </CardTile>
                  <Select
                    name={`${device.id}.handleDeviceRecycle`}
                    control={control}
                    label={t('steppers.questions.handle_device_recycle')}
                    getOptionLabel={option => t(`forms.labels.device_recycle.${option.toLowerCase()}`)}
                    options={Object.values(EmployeeOffboardingDeviceRecycle)}
                    defaultValue={EmployeeOffboardingDeviceRecycle.Nothing}
                    menuPlacement={devices.length > 1 && index === devices.length - 1 ? 'top' : 'bottom'}
                  />
                  <HexnodeLegacy>
                    <Flex direction="column">
                      <Select
                        name={`${device.id}.handleDeviceData`}
                        control={control}
                        label={t('steppers.questions.handle_device_data')}
                        getOptionLabel={option => t(`forms.labels.device_data.${option.toLowerCase()}`)}
                        options={
                          shouldHideLockOptionForDevice[device.id]
                            ? Object.values(HandleDeviceData).filter(v => v !== HandleDeviceData.Lock)
                            : Object.values(HandleDeviceData)
                        }
                        defaultValue={HandleDeviceData.Nothing}
                        isDisabled={!device.agentEnabled}
                        menuPlacement={devices.length > 1 && index === devices.length - 1 ? 'top' : 'bottom'}
                      />
                      {!device.agentEnabled && (
                        <Flex gap={1} align="center" className="pri-mt--2">
                          <Text weight="light" size="sm" variant="muted">
                            {t('notices.why_disabled')}
                          </Text>
                        </Flex>
                      )}
                    </Flex>
                  </HexnodeLegacy>
                  <HasMDM>
                    <Flex direction="column">
                      <Select
                        name={`${device.id}.handleDeviceData`}
                        control={control}
                        label={t('steppers.questions.handle_device_data')}
                        getOptionLabel={option => t(`forms.labels.device_data.${option.toLowerCase()}`)}
                        options={Object.values(HandleDeviceData)
                          .filter(d => (shouldHideLockOptionForDevice[device.id] ? d !== HandleDeviceData.Lock : true))
                          .filter(d => (!hasLockOptionV2 ? d !== HandleDeviceData.Lock : true))
                          .filter(d => (!hasWipeOptionV2 ? d !== HandleDeviceData.SoftWipe : true))}
                        defaultValue={HandleDeviceData.Nothing}
                        isDisabled={!device.agentEnabled}
                        menuPlacement={devices.length > 1 && index === devices.length - 1 ? 'top' : 'auto'}
                      />
                      {!device.agentEnabled && (
                        <Flex gap={1} align="center" className="pri-mt--2">
                          <Text weight="light" size="sm" variant="muted">
                            {t('notices.why_disabled')}
                          </Text>
                        </Flex>
                      )}
                    </Flex>
                  </HasMDM>
                </Flex>
              ))}
            </Flex>
          </Flex>
        )}
      </Form>
    </Step>
  );
};

export default HandleDevicesStep;
