import React from 'react';
import cn from 'classnames';

import { NavProvider } from './NavContext';

import './index.scss';
import Flex from '~/components/shared/shaping/Flex';

export * from './NavContext';
export * from './NavGroup';
export * from './NavItem';
export * from './NavSubItem';

export const Nav: FunctionComponent = ({ children, className }) => (
  <NavProvider>
    <Flex direction="column" fullHeight className={cn('pri-nav-v2', className)}>
      {children}
    </Flex>
  </NavProvider>
);
