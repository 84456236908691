import React, { useMemo } from 'react';
import { createPortal } from 'react-dom';

import StepperProvider from './StepperContext';
import type { StepperProps } from './Stepper';
import Stepper from './Stepper';

type StepperContainerV2Props = {
  onClose: () => void;
};

const StepperContainerV2: React.FC<StepperProps & StepperContainerV2Props> = props => {
  const { onClose } = props;
  const domNode = useMemo(
    () => document.getElementById('steppers-root') || document.body.appendChild(document.createElement('div')),
    [],
  );

  return createPortal(
    <StepperProvider onClose={onClose}>
      <Stepper {...props} />
    </StepperProvider>,
    domNode,
  );
};

export default StepperContainerV2;
