import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import QuestionIcon from '~/components/icons/question';
import BulletPointList from '~/components/shared/BulletPointList';

import CollapsibleSection from '~/components/shared/CollapsibleSection';
import Tabs from '~/components/shared/Tabs';
import Tab from '~/components/shared/Tabs/Tab';

const STEPS_LINUX = [
  'instructions.find_serial_number.linux.how_to_1',
  'instructions.find_serial_number.linux.how_to_2',
  'instructions.find_serial_number.linux.how_to_3',
];

const STEPS_WIN = [
  'instructions.find_serial_number.windows.how_to_1',
  'instructions.find_serial_number.windows.how_to_2',
  'instructions.find_serial_number.windows.how_to_3',
];

const STEPS_MACOS = [
  'instructions.find_serial_number.macos.how_to_1',
  'instructions.find_serial_number.macos.how_to_2',
  'instructions.find_serial_number.macos.how_to_3',
];

export const HowToFindSerialNumberHelper: FunctionComponent = () => {
  const { t } = useTranslation();
  const [, setCurrentTab] = useState('macos');

  return (
    <CollapsibleSection collapsedByDefault title={t('titles.how_to_find_serial_number')} icon={<QuestionIcon />}>
      <Tabs onChange={setCurrentTab} disableURLSearchUpdate>
        <Tab tabKey="macos">
          <BulletPointList bulletPoints={STEPS_MACOS.map(key => ({ title: t(key) }))} numbered />
        </Tab>
        <Tab tabKey="windows">
          <BulletPointList bulletPoints={STEPS_WIN.map(key => ({ title: t(key) }))} numbered />
        </Tab>
        <Tab tabKey="linux">
          <BulletPointList bulletPoints={STEPS_LINUX.map(key => ({ title: t(key) }))} numbered />
        </Tab>
      </Tabs>
    </CollapsibleSection>
  );
};
