import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Card from '~/components/shared/Card';
import CardItem from '~/components/shared/CardItem';
import CardItemProperty from '~/components/shared/CardItem/property';
import { InfoIcon } from '~/components/icons';

import { OffboardingStepperContext } from '../context';

const EmployeeRecap: FunctionComponent = () => {
  const { t } = useTranslation();
  const {
    contextData: { employee },
  } = useContext(OffboardingStepperContext);

  return (
    <Card title={t('titles.informations')} icon={<InfoIcon />}>
      <CardItem>
        <CardItemProperty label={t('properties.employees.firstname')} value={employee?.firstName} />
      </CardItem>
      <CardItem>
        <CardItemProperty label={t('properties.employees.lastname')} value={employee?.lastName} />
      </CardItem>
      <CardItem>
        <CardItemProperty label={t('properties.employees.email')} value={employee?.email} />
      </CardItem>
    </Card>
  );
};

export default EmployeeRecap;
