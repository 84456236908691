import React from 'react';

const SecurityIcon: SvgComponent = ({ className, width = 24, color = '#1E1E1E' }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.75 10.3688L7.13125 8.75L6.25 9.63125L8.75 12.1312L13.75 7.13125L12.8688 6.25L8.75 10.3688Z"
      fill={color}
    />
    <path
      d="M10 18.75L6.14013 16.6919C5.03967 16.1065 4.1194 15.2325 3.4781 14.1636C2.8368 13.0948 2.49867 11.8715 2.5 10.625V2.5C2.50035 2.16859 2.63216 1.85085 2.8665 1.6165C3.10085 1.38216 3.41859 1.25035 3.75 1.25H16.25C16.5814 1.25035 16.8992 1.38216 17.1335 1.6165C17.3678 1.85085 17.4997 2.16859 17.5 2.5V10.625C17.5013 11.8715 17.1632 13.0948 16.5219 14.1636C15.8806 15.2325 14.9603 16.1065 13.8599 16.6919L10 18.75ZM3.75 2.5V10.625C3.74892 11.6449 4.0256 12.6458 4.55034 13.5203C5.07509 14.3949 5.82808 15.11 6.7285 15.5889L10 17.3333L13.2715 15.5889C14.1719 15.11 14.9249 14.3949 15.4497 13.5203C15.9744 12.6458 16.2511 11.6449 16.25 10.625V2.5H3.75Z"
      fill={color}
    />
  </svg>
);

export default SecurityIcon;
