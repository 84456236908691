import React from 'react';

const OrderIcon: SvgComponent = ({ className, width = 20 }) => (
  <svg className={className} width={width} height={width} viewBox="0 0 20 20" fill="none">
    <path
      d="M10.625 15H13.125V17.5H10.625V15ZM15 15H17.5V17.5H15V15ZM10.625 10.625H13.125V13.125H10.625V10.625ZM15 10.625H17.5V13.125H15V10.625Z"
      fill="black"
    />
    <path
      d="M17.5 6.875H13.75V4.375C13.75 3.3125 12.9375 2.5 11.875 2.5H8.125C7.0625 2.5 6.25 3.3125 6.25 4.375V6.875H2.5C2.125 6.875 1.875 7.125 1.875 7.5C1.875 7.5625 1.875 7.5625 1.875 7.625L3.0625 15.1875C3.125 15.8125 3.6875 16.25 4.3125 16.25H9.375V15H4.3125L3.25 8.125H17.5V6.875ZM7.5 4.375C7.5 4 7.75 3.75 8.125 3.75H11.875C12.25 3.75 12.5 4 12.5 4.375V6.875H7.5V4.375Z"
      fill="black"
    />
  </svg>
);

export default OrderIcon;
