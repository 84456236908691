import type { Address, AddressItem } from '~/graphql/schema';

export function inlineAddress(address: Maybe<Address | AddressItem>): string {
  if (!address) {
    return '';
  }
  const addressFormatted = [
    address.street,
    address.complement,
    [address.postalCode, address.city].filter(Boolean).join(' '),
    address.country,
  ]
    .filter(Boolean)
    .join(', ');
  return [address.contactName, addressFormatted, address.phone].filter(Boolean).join(' - ');
}
