import React, { useContext } from 'react';
import { PrimoAdminContext } from '~/providers/primo/PrimoAdminProvider';

const Beta: FunctionComponent<{ span?: true; not?: true }> = ({ children, not = false, span = false, className }) => {
  const Cmp = span ? 'span' : 'div';
  const { isBetaActivated } = useContext(PrimoAdminContext);

  if (isBetaActivated === not) {
    return null;
  }
  return <Cmp className={className}>{children}</Cmp>;
};

export default Beta;
