import { useCallback, useEffect, useMemo } from 'react';
import { ok, refuse } from '@primo/operation-result';
import type { CheckIfEmployeeEmailExistQuery } from '~/graphql/schema';
import { useCheckIfEmployeeEmailExistLazyQuery } from '~/graphql/schema';
import type { UseQueryOptions } from '~/hooks/queries/types';

type UseCheckExistingEmailVariables = {
  email: string;
  companyId: string;
};

const useQueryCheckExistingEmail = (queryOptions: UseQueryOptions<UseCheckExistingEmailVariables> = {}) => {
  const serializeCheckEmailData = useCallback((data: CheckIfEmployeeEmailExistQuery | undefined) => {
    if (data?.doesEmployeeEmailExist?.__typename === 'QueryDoesEmployeeEmailExistSuccess') {
      return ok(data.doesEmployeeEmailExist.data);
    }
    if (data?.doesEmployeeEmailExist?.__typename === 'QueryDoesEmployeeEmailExistError')
      return refuse(data.doesEmployeeEmailExist.reasons);
    return refuse(['Unknown error']);
  }, []);

  const [query, { data: employeeData, loading: existingEmailLoading, called: existingEmailCalled }] =
    useCheckIfEmployeeEmailExistLazyQuery();

  const existingEmailResult = useMemo(
    () => serializeCheckEmailData(employeeData),
    [serializeCheckEmailData, employeeData],
  );

  const checkExistingEmail = useCallback(
    async (variables: UseCheckExistingEmailVariables) => {
      const result = await query({ variables });
      return serializeCheckEmailData(result.data);
    },
    [query, serializeCheckEmailData],
  );

  useEffect(() => {
    if (queryOptions.runOnInit && !existingEmailCalled)
      checkExistingEmail({ email: queryOptions.email, companyId: queryOptions.companyId });
  }, [checkExistingEmail, queryOptions, existingEmailCalled]);

  return useMemo(
    () => ({
      checkExistingEmail,
      existingEmailResult,
      existingEmailLoading,
      existingEmailCalled,
    }),
    [checkExistingEmail, existingEmailResult, existingEmailLoading, existingEmailCalled],
  );
};

export default useQueryCheckExistingEmail;
