import { useEffect, useState } from 'react';

/**
 * const fullLoadedOnce = useFullLoadedOnce([loading1, loading2, loading3]);
 *
 * `fullLoadedOnce` is falsy until all `loadings` are loaded
 * `fullLoadedOnce` is truthy when all `loadings` are falsy in same time
 * `fullLoadedOnce` keep truthy state even it `loadings` changes because it watch the first full loaded state
 *
 * `useFullLoadedOnce` hook is used in PageComponent to load initial content
 * do not add loading in this hook if you want to display a page event if this loading is not done
 */
const useFullLoadedOnce = (loadings: boolean[]) => {
  const [fullLoadedOnce, setFullLoadedOnce] = useState(false);

  useEffect(() => {
    const areStillLoading = loadings.reduce((isLoading, loading) => isLoading || loading, false);

    if (!areStillLoading && !fullLoadedOnce) {
      setFullLoadedOnce(true);
    }
  }, [loadings, fullLoadedOnce]);

  return fullLoadedOnce;
};

export default useFullLoadedOnce;
