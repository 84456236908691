import React from 'react';

const ChevronLastIcon: SvgComponent = ({ className, width = 24, color = '#1E1E1E' }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.00078 8L4.00078 13L3.30078 12.3L7.60078 8L3.30078 3.7L4.00078 3L9.00078 8ZM11.0008 2H12.0008V14H11.0008V2Z"
      fill={color}
    />
  </svg>
);

export default ChevronLastIcon;
