import cn from 'classnames';
import type { ReactElement } from 'react';
import React, { useMemo } from 'react';

import Bloc from '~/components/shared/shaping/Bloc';
import Flex from '~/components/shared/shaping/Flex';
import { Text } from '~/components/shared/typography';

import './index.scss';

export type MessageProps = {
  text?: string;
  icon?: ReactElement<SvgComponentProps>;
  variant?:
    | 'info'
    | 'success'
    | 'warning'
    | 'danger'
    | 'muted'
    | 'muted-light'
    | 'plain-info'
    | 'plain-success'
    | 'plain-warning'
    | 'plain-danger'
    | 'plain-muted'
    | 'plain-muted-light';
};

const Message: FunctionComponent<MessageProps> = ({ className, text, children, variant, icon }) => {
  const content = useMemo(() => (text ? <Text weight="light">{text}</Text> : children), [text, children]);

  return (
    <Bloc className={cn('pri-message', { [`pri-message-${variant}`]: variant }, className)}>
      {icon ? (
        <Flex direction="row" gap={3} align="center">
          {icon}
          {content}
        </Flex>
      ) : (
        content
      )}
    </Bloc>
  );
};

export default Message;
