import React, { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { isOk } from '@primo/operation-result';
import { toast } from 'react-toastify';
import { Step } from '~/components/shared/Stepper';
import { OffboardingStepperContext } from '../context';
import { StepperContext } from '~/components/shared/Stepper/_components/StepperContext';
import { Form } from '~/components/shared/forms/Form';
import Loader from '~/components/shared/Loader';
import Flex from '~/components/shared/shaping/Flex';
import { Text, Title } from '~/components/shared/typography';
import Radio from '~/components/shared/forms/Radio';
import { Truthy } from '~/utils/truthy';
import EmployeeSelectV2 from '~/components/featured/selects/EmployeeSelect';
import { PrimoAdminContext } from '~/providers/primo/PrimoAdminProvider';
import CardTile from '~/components/shared/CardTile';
import UserIcon from '~/components/icons/user';
import type { OffboardingStepperContextData } from '../types';
import useQueryGetEmployee from '~/hooks/queries/employees/useQueryGetEmployee';
import EmployeeStatusPill from '~/components/featured/pills/EmployeeStatusPill';
import Message from '~/components/shared/Message';

const ChooseEmployeeStep: FunctionComponent = () => {
  const { t } = useTranslation();
  const form = useForm<{ employeeChoice: string; employee?: string }>({
    defaultValues: { employeeChoice: 'select' },
  });
  const { setFooterProps } = useContext(StepperContext);
  const { company } = useContext(PrimoAdminContext);
  const { watch, control } = form;
  const employeeChoice = watch('employeeChoice');
  const employee = watch('employee');

  const { pendingOffboardings, loading, setContextData, contextData } = useContext(OffboardingStepperContext);
  const { getEmployee } = useQueryGetEmployee();

  const onNext = useCallback(async () => {
    if (employeeChoice === 'select' && !employee) {
      toast.error(<>{t('errors.choose_an_employee')}</>);
      return false;
    }
    const newContextData: OffboardingStepperContextData = { ...contextData, employee: undefined };

    const employeeId = employeeChoice === 'select' && employee ? employee : employeeChoice;
    const fetchedEmployeeResult = await getEmployee({ employeeId });

    if (!isOk(fetchedEmployeeResult) || !fetchedEmployeeResult.data.employee) {
      toast.error(<>{t('errors.unknown_error')}</>);
      return false;
    }
    newContextData.employee = {
      ...fetchedEmployeeResult.data.employee,
      devices: fetchedEmployeeResult.data.devices,
    };

    setContextData(newContextData);
    return true;
  }, [getEmployee, employee, contextData, employeeChoice, setContextData, t]);

  useEffect(() => {
    setFooterProps({ onNext });
  }, [setFooterProps, onNext]);

  if (!company) return null;

  return (
    <Step size="lg">
      <Title className="pri-mb-9" level={2}>
        {t('steppers.offboarding.offboard_the_employee')}
      </Title>
      <Form form={form}>
        {loading ? (
          <Loader spacing="md" />
        ) : (
          <Flex direction="column" gap={6}>
            <Text className="pri-mb-3" size="lg" weight="medium">
              {t('steppers.offboarding.who_is_it_for')}
            </Text>
            <Radio
              name="employeeChoice"
              control={form.control}
              options={[
                {
                  label: (
                    <Flex direction="column" fullWidth>
                      <Text weight="light" size="lg" className="pri-mb-4">
                        {t('steppers.offboarding.new_offboarding')}
                      </Text>
                      <EmployeeSelectV2
                        label={t('steppers.offboarding.select_employee')}
                        name="employee"
                        control={control}
                        companyId={company.id}
                      />
                    </Flex>
                  ),
                  value: 'select',
                },
                {
                  separator: (
                    <Text weight="light" size="lg" className="pri-mt-4">
                      {t('steppers.offboarding.offboarding_already_in_progress')}
                    </Text>
                  ),
                },
                ...(pendingOffboardings.length > 0
                  ? pendingOffboardings.map(po => ({
                      label: (
                        <CardTile
                          spacing="md"
                          prefixIcon={<UserIcon />}
                          title={po.fullName}
                          statusPill={
                            <EmployeeStatusPill terminationDate={po.terminationDate} offboardingStatus={po.status} />
                          }
                        />
                      ),
                      value: po.employeeId,
                      dotAlignment: 'center' as const,
                    }))
                  : [
                      {
                        separator: (
                          <Flex className="pri-mt--4" direction="column" fullWidth>
                            <Message
                              variant="plain-info"
                              text={
                                !company?.isHrisSyncEnabled
                                  ? t('forms.labels.enable_hris_to_simplify_offboardings')
                                  : t('forms.labels.no_offboarding_in_progress')
                              }
                            />
                          </Flex>
                        ),
                      },
                    ]),
              ].filter(Truthy)}
            />
          </Flex>
        )}
      </Form>
    </Step>
  );
};

export default ChooseEmployeeStep;
