import { useCallback, useContext, useMemo } from 'react';
import type { GetOnboardingCompanyCatalogQuery } from '~/graphql/schema';
import { useGetOnboardingCompanyCatalogQuery } from '~/graphql/schema';
import { PrimoAdminContext } from '~/providers/primo/PrimoAdminProvider';

const useGetCatalog = () => {
  const { company } = useContext(PrimoAdminContext);
  const companyId = company?.id;

  const getCompanyCatalog = useCallback((data: GetOnboardingCompanyCatalogQuery | undefined) => {
    if (data?.getCompanyCatalog?.__typename === 'QueryGetCompanyCatalogSuccess') {
      return data.getCompanyCatalog.data ?? [];
    }
    return [];
  }, []);

  const {
    data: catalogData,
    loading: catalogLoading,
    called: catalogCalled,
  } = useGetOnboardingCompanyCatalogQuery({
    variables: { companyId },
    fetchPolicy: 'no-cache',
  });

  const catalog = useMemo(() => getCompanyCatalog(catalogData), [getCompanyCatalog, catalogData]);

  return {
    catalog,
    catalogLoading,
    catalogCalled,
  };
};

export default useGetCatalog;
