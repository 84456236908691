import { every, includes } from 'lodash';
import type { FC, ReactElement } from 'react';

import { useCompany } from '~/hooks/useCompany';
import type { MdmAction } from '~/graphql/schema';
import type { PrimoAdminContextProps } from '~/providers/primo/PrimoAdminProvider';

type HasMDMProps = { children: ReactElement | null; withActions?: MdmAction[] };

const hasAllActionsInMDMCompany = (
  company: NonNullable<PrimoAdminContextProps['company']>,
  actions: HasMDMProps['withActions'],
) => every(actions, action => includes(company.mdmActions, action));

export const HasMDM: FC<HasMDMProps> = ({ children, withActions = [] }) => {
  const company = useCompany();

  if (
    !company ||
    !company.hasMDM ||
    company.mdmLegacy ||
    (withActions.length && !hasAllActionsInMDMCompany(company, withActions))
  ) {
    return null;
  }
  return children;
};
