import React from 'react';

const KeyIcon: SvgComponent = ({ className, width = 24, color = '#1E1E1E' }) => (
  <svg className={className} width={width} height={width} viewBox="0 0 20 20">
    <path
      d="M11 11.68V2H7V3.6H9.4V5.2H7V6.8H9.4V11.68C8.04 12 7 13.28 7 14.8C7 16.56 8.44 18 10.2 18C11.96 18 13.4 16.56 13.4 14.8C13.4 13.28 12.36 12.08 11 11.68ZM10.2 16.4C9.32 16.4 8.6 15.68 8.6 14.8C8.6 13.92 9.32 13.2 10.2 13.2C11.08 13.2 11.8 13.92 11.8 14.8C11.8 15.68 11.08 16.4 10.2 16.4Z"
      fill={color}
    />
  </svg>
);

export default KeyIcon;
