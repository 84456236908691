import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '~/components/shared/Loader';

import { LOGIN_ROUTE, ROOT } from '~/config/routes';
import { PrimoAdminContext } from '~/providers/primo/PrimoAdminProvider';

/**
 * Will redirect to <children> or <SignIn> page based on Auth0 session
 * @param children -
 * @constructor
 */
const AdminAuthenticatedGuard: FunctionComponent = ({ children = null }) => {
  const navigate = useNavigate();
  const { isAuthenticated, isLoading, employee, logout } = useContext(PrimoAdminContext);

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      const { pathname } = window.location;
      const searchParams = new URLSearchParams({ to: window.location.pathname });
      navigate(pathname === ROOT ? LOGIN_ROUTE() : `${LOGIN_ROUTE()}?${searchParams.toString()}`);
    }

    // Logout user if not admin or internal employee
    if (isAuthenticated && !isLoading && employee?.isOnlyEmployee) {
      logout(window.location.pathname);
    }
  }, [isAuthenticated, isLoading, navigate, employee, logout]);

  if (isLoading) return <Loader />;

  if (!isAuthenticated) {
    return null;
  }
  return <div>{children}</div>;
};

export default AdminAuthenticatedGuard;
