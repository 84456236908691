import type { ReactNode } from 'react';
import React, { useContext, useMemo } from 'react';
import classnames from 'classnames';
import { Link, matchPath, useLocation } from 'react-router-dom';

import { NavContext } from './NavContext';
import ChevronRightIcon from '~/components/icons/chevronRight';
import { Text, Title } from '~/components/shared/typography';
import Flex from '~/components/shared/shaping/Flex';
import Badge from '~/components/shared/Badge';

export type NavItemProps = {
  label: ReactNode;
  to: string;
  group?: string;
  arrow?: true;
  arrowCollapsed?: boolean;
  activable?: boolean;
  badge?: number | null;
  icon?: ReactNode;
};

export const NavItem: FunctionComponent<NavItemProps> = ({
  arrow = false,
  arrowCollapsed = true,
  activable = true,
  label,
  group,
  to,
  className,
  badge,
  icon,
}) => {
  const { toggle } = useContext(NavContext);
  const { pathname } = useLocation();

  const active = useMemo(() => matchPath(`${to}/*`, pathname) && activable, [to, pathname, activable]);

  return (
    <Link className={classnames('pri-nav-item', className)} to={to} onClick={() => toggle(group || to)}>
      <Flex direction="row" align="center" gap={2}>
        <Flex justify="between" fullWidth align="center" gap={2}>
          {icon}
          {active ? (
            <Title variant="primary" level={5}>
              {label} {!!badge && <Badge content={badge} />}
            </Title>
          ) : (
            <Text variant="light">
              {label} {!!badge && <Badge content={badge} />}
            </Text>
          )}
          {arrow && (
            <ChevronRightIcon
              width={20}
              color="white"
              className={classnames('pri-ml-1 -arrow', { '--collapsed': arrowCollapsed })}
            />
          )}
        </Flex>
      </Flex>
    </Link>
  );
};
