import React from 'react';

import type { Device } from '~/graphql/schema';
import type { TextProps } from '~/components/shared/typography';
import { Text, Title } from '~/components/shared/typography';

import './style.scss';

export type DeviceNameV2Props = Partial<Pick<Device, 'name'>> &
  Pick<TextProps, 'maxLength'> & { asTitle?: boolean; withSerial?: boolean };

export const DeviceNameV2: FunctionComponent<DeviceNameV2Props> = ({ name, maxLength = 50, asTitle }) =>
  asTitle ? (
    <Title maxLength={maxLength}>{name ?? ''}</Title>
  ) : (
    <Text maxLength={maxLength} weight="regular">
      {name ?? ''}
    </Text>
  );
