import { useCallback, useEffect, useMemo } from 'react';
import { ok, refuse } from '@primo/operation-result';
import type { EmployeeOffboardingDeviceRecycle, HandleDeviceData, OffboardEmployeeMutation } from '~/graphql/schema';
import { useOffboardEmployeeMutation } from '~/graphql/schema';
import type { UseQueryOptions } from '~/hooks/queries/types';

type UseMutationOffboardEmployeeVariables = {
  employeeId: string;
  procedureDate: string;
  employee: {
    phone?: string;
    personalEmail?: string;
  };
  devices: {
    data?: HandleDeviceData;
    deviceId: string;
    deviceName?: string;
    recycle: EmployeeOffboardingDeviceRecycle;
  }[];
  apps: string[];
};

const useMutationOffboardEmployee = (queryOptions: UseQueryOptions<UseMutationOffboardEmployeeVariables> = {}) => {
  const serializeOffboardingData = useCallback((data: OffboardEmployeeMutation | undefined | null) => {
    if (data?.offboardEmployee?.__typename === 'MutationOffboardEmployeeSuccess') {
      return ok(data.offboardEmployee.data);
    }
    if (data?.offboardEmployee?.__typename === 'MutationOffboardEmployeeError')
      return refuse(data.offboardEmployee.reasons);
    return refuse(['Unknown error']);
  }, []);

  const [mutation, { data: offboardingData, loading: offboardingLoading, called: offboardingCalled }] =
    useOffboardEmployeeMutation({
      refetchQueries: [
        'GetEmployeePage',
        'ListEmployeesPage',
        'GetViewEmployees',
        'ListOffboardingsPage',
        'GetOffboardingToDeclareDocument',
        'GetEmployeePageDocument',
        'ListOnboardingsPage',
      ],
    });

  const offboardEmployeeResult = useMemo(
    () => serializeOffboardingData(offboardingData),
    [serializeOffboardingData, offboardingData],
  );

  const offboardEmployee = useCallback(
    async (variables: UseMutationOffboardEmployeeVariables) => {
      const result = await mutation({
        variables,
      });
      return serializeOffboardingData(result.data);
    },
    [mutation, serializeOffboardingData],
  );

  useEffect(() => {
    if (queryOptions.runOnInit && !offboardingCalled) offboardEmployee({ ...queryOptions });
  }, [offboardEmployee, queryOptions, offboardingCalled]);

  return useMemo(
    () => ({
      offboardEmployee,
      offboardEmployeeResult,
      offboardingLoading,
      offboardingCalled,
    }),
    [offboardEmployee, offboardEmployeeResult, offboardingLoading, offboardingCalled],
  );
};

export default useMutationOffboardEmployee;
