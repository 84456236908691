import type { ReactNode } from 'react';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useBoolean } from 'react-use';
import Bloc from '~/components/shared/shaping/Bloc';
import Loader from '~/components/shared/Loader';

type FooterProps = {
  onNext?: () => boolean | Promise<boolean>;
  onPrev?: () => boolean | Promise<boolean>;
  nextLabel?: string;
  informationNode?: ReactNode;
  loaderInButton?: boolean;
  disabledNextButton?: boolean;
};

export type StepperContextProps = {
  loading: boolean;
  currentStep: number;
  setCurrentStep: (step: number) => void;
  footerProps: FooterProps;
  setFooterProps: (props: FooterProps) => void;
  toggleLoading: (state?: boolean) => void;
  onClose: () => void;
};

export const StepperContext = createContext<StepperContextProps>({
  loading: false,
  currentStep: 1,
  setCurrentStep: () => {},
  footerProps: {},
  setFooterProps: () => {},
  toggleLoading: () => null,
  onClose: () => null,
});

type StepperProviderProps = {
  onClose: () => void;
};

const StepperProvider: FunctionComponent<StepperProviderProps> = ({ children, onClose }) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [footerProps, setFooterProps] = useState<FooterProps>({});
  const [loading, toggleLoading] = useBoolean(false);

  useEffect(() => {
    window.history.pushState(null, '', window.location.href);

    window.onpopstate = () => {
      onClose();
      window.history.pushState(null, '', window.location.href);
    };
  }, [onClose]);

  const value = useMemo(
    () => ({
      loading,
      currentStep,
      footerProps,
      setFooterProps,
      setCurrentStep,
      onClose,
      toggleLoading,
    }),
    [loading, currentStep, footerProps, setFooterProps, setCurrentStep, onClose, toggleLoading],
  );

  return (
    <StepperContext.Provider value={value}>
      {children}
      {loading && !footerProps.loaderInButton && (
        <Bloc className="pri-stepper-loader">
          <Loader spacing="sm" />
        </Bloc>
      )}
    </StepperContext.Provider>
  );
};

export default StepperProvider;
