import type { FC, ReactElement } from 'react';

import { useCompany } from '~/hooks/useCompany';

export const HexnodeLegacy: FC<{ children: ReactElement | null }> = ({ children }) => {
  const company = useCompany();

  if (company?.hasMDM && company?.mdmLegacy) {
    return children;
  }
  return null;
};
