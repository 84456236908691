import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { isRefusal } from '@primo/operation-result';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';

import Checkbox from '~/components/shared/forms/Checkbox';
import DatePicker from '~/components/shared/forms/DatePicker';
import Flex from '~/components/shared/shaping/Flex';
import Input from '~/components/shared/forms/Input';
import Message from '~/components/shared/Message';
import useQueryCheckExistingEmail from '~/hooks/queries/employees/useQueryCheckExistingEmail';
import useQueryCheckExistingPersonalEmail from '~/hooks/queries/employees/useQueryCheckExistingPersonalEmail';
import { Form } from '~/components/shared/forms/Form';
import { HasMDM } from '~/features/mdm';
import { InfoIcon } from '~/components/icons';
import { PrimoAdminContext } from '~/providers/primo/PrimoAdminProvider';
import { Step } from '~/components/shared/Stepper';
import { StepperContext } from '~/components/shared/Stepper/_components/StepperContext';
import { Text, Title } from '~/components/shared/typography';

import type { Fields } from './schema';
import { schema } from './schema';
import { OnboardingStepperContext } from '../../context';
import type { OnboardingStepperContextData } from '../../types';
import Loader from '~/components/shared/Loader';

const FillEmployeeInformationStep: FunctionComponent = () => {
  const { t } = useTranslation();

  const { company } = useContext(PrimoAdminContext);
  const { contextData, setContextData, loading, setupCalled } = useContext(OnboardingStepperContext);
  const { setFooterProps } = useContext(StepperContext);
  const { employee } = contextData;

  const form = useForm<Fields>({
    defaultValues: {
      phone: employee?.phone || undefined,
      email: employee?.email || undefined,
      firstName: employee?.firstName || undefined,
      lastName: employee?.lastName || undefined,
      picture: employee?.picture || undefined,
      startDate: employee?.startDate || undefined,
      personalEmail: undefined,
      automaticEnrollment: Boolean(
        typeof employee?.automaticEnrollment !== 'undefined' ? employee?.automaticEnrollment : company?.hasMDM,
      ),
      sendToPersonalEmail: !!employee?.sendToPersonalEmail,
    },
    resolver: zodResolver(
      schema(t, employee?.terminationDate, company?.hasProvisioning ? company?.domains : undefined),
    ),
  });
  const {
    register,
    control,
    trigger,
    getValues,
    formState: { errors },
    setValue,
    watch,
  } = form;

  const shouldDisplayDomainMessage = useMemo(
    () => company?.hasProvisioning && !company?.domains?.some(domain => employee?.email?.endsWith(domain)),
    [company, employee],
  );
  const automaticEnrollment = watch('automaticEnrollment');
  const personalEmail = watch('personalEmail');
  const { checkExistingEmail } = useQueryCheckExistingEmail();
  const { checkExistingPersonalEmail } = useQueryCheckExistingPersonalEmail();

  const onNext = useCallback(async () => {
    const isValid = await trigger();
    const newContextData: OnboardingStepperContextData = { ...contextData };

    if (!isValid || !company) return false;

    const values = getValues();

    if (values.email && values.email !== employee?.email) {
      const emailExists = await checkExistingEmail({ email: values.email, companyId: company.id });
      if (isRefusal(emailExists) || emailExists.data) {
        toast.error(<>{t('errors.email_already_exists')}</>);
        return false;
      }
    }

    if (values.personalEmail && values.personalEmail !== employee?.personalEmail) {
      const personalEmailExists = await checkExistingPersonalEmail({
        personalEmail: values.personalEmail,
        companyId: company.id,
      });
      if (isRefusal(personalEmailExists) || personalEmailExists.data) {
        toast.error(<>{t('errors.personal_email_already_exists')}</>);
        return false;
      }
    }

    newContextData.employee = {
      ...contextData.employee,
      ...values,
      id: employee?.id,
    };
    setContextData(newContextData);
    return true;
  }, [
    trigger,
    contextData,
    company,
    getValues,
    employee,
    setContextData,
    checkExistingEmail,
    t,
    checkExistingPersonalEmail,
  ]);

  useEffect(() => {
    if (setupCalled && !loading) {
      if (employee?.firstName) setValue('firstName', employee.firstName);
      if (employee?.lastName) setValue('lastName', employee.lastName);
      if (employee?.email) setValue('email', employee.email);
      if (employee?.phone) setValue('phone', employee.phone);
      if (employee?.personalEmail) setValue('personalEmail', employee.personalEmail);
      if (employee?.startDate) setValue('startDate', employee.startDate);
      if (employee?.picture) setValue('picture', employee.picture);
    }
  }, [setupCalled, loading, employee, setValue]);

  useEffect(() => {
    setFooterProps({ onNext });
  }, [setFooterProps, onNext]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Step size="lg">
      <Title className="pri-mb-9" level={2}>
        {t('steppers.onboarding.onboard_the_employee')}
      </Title>
      <Form form={form}>
        <Flex direction="column" gap={10}>
          <fieldset>
            <legend className="pri-mb-4">
              <Text size="lg" weight="medium">
                {t('steppers.onboarding.general_information')}
              </Text>
            </legend>
            <Flex direction="column" gap={5}>
              <Flex gap={6}>
                <Input
                  required
                  className=".pri-flex-1"
                  name="firstName"
                  label={t('forms.labels.firstName')}
                  register={register}
                  errors={errors}
                />
                <Input
                  required
                  name="lastName"
                  className=".pri-flex-1"
                  label={t('forms.labels.lastName')}
                  register={register}
                  errors={errors}
                />
              </Flex>
              <DatePicker
                required
                name="startDate"
                placeholder={t('forms.placeholders.start_date')}
                label={t('forms.labels.start_date')}
                control={control}
                errors={errors}
                dateFormat="DD/MM/YYYY"
              />
              <Input
                name="phone"
                label={t('forms.labels.phone')}
                register={register}
                errors={errors}
                helperText={
                  <Text weight="light" size="sm" variant="muted">
                    {t('steppers.onboarding.backup_contact_information_usage')}
                  </Text>
                }
              />
            </Flex>
          </fieldset>

          <fieldset>
            <legend className="pri-mb-4">
              <Text size="lg" weight="medium">
                {t('steppers.onboarding.email_address')}
              </Text>
            </legend>
            <Flex direction="column" gap={5}>
              {shouldDisplayDomainMessage ? (
                <Message className="pri-mb-2" variant="plain-info">
                  <Flex direction="column" gap={1}>
                    <Flex gap={2}>
                      <InfoIcon width={14} />
                      <Title level={5}>{t('titles.employee_domain_should_be_the_same')}</Title>
                    </Flex>
                    <Text weight="light" size="md">
                      {t('texts.employee_domain_should_be_the_same')}
                    </Text>
                  </Flex>
                </Message>
              ) : null}
              <Flex direction="column">
                <Input
                  required
                  name="email"
                  label={t('forms.labels.main_address')}
                  register={register}
                  errors={errors}
                />
                <HasMDM>
                  <Checkbox
                    className="pri-mb-4"
                    name="automaticEnrollment"
                    errors={errors}
                    register={register}
                    label={t('steppers.onboarding.automatically_send_enrollment')}
                  />
                </HasMDM>
              </Flex>
              <Flex direction="column">
                <Input
                  name="personalEmail"
                  label={t('forms.labels.backup_email')}
                  register={register}
                  errors={errors}
                  helperText={
                    <Text weight="light" size="sm" variant="muted">
                      {t('steppers.onboarding.backup_contact_information_usage')}
                    </Text>
                  }
                />
                <HasMDM>
                  <Checkbox
                    className="pri-mt-4"
                    name="sendToPersonalEmail"
                    errors={errors}
                    register={register}
                    label={t('steppers.onboarding.send_to_personal_email')}
                    disabled={!automaticEnrollment || !personalEmail}
                  />
                </HasMDM>
              </Flex>
            </Flex>
          </fieldset>
        </Flex>
      </Form>
    </Step>
  );
};

export default FillEmployeeInformationStep;
