/* eslint-disable jsx-a11y/click-events-have-key-events  */
/* eslint-disable jsx-a11y/no-static-element-interactions  */
import React from 'react';
import cn from 'classnames';

import './index.scss';

export type FlexProps = {
  justify?: 'start' | 'end' | 'center' | 'between' | 'around';
  align?: 'start' | 'end' | 'center' | 'baseline' | 'stretch';
  alignSelf?: 'start' | 'end' | 'center' | 'baseline' | 'stretch';
  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  wrap?: 'wrap' | 'nowrap' | 'wrap-reverse';
  gap?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  fullWidth?: boolean;
  fullHeight?: boolean;
  onClick?: () => void;
  style?: React.CSSProperties;
};

const Flex: FunctionComponent<FlexProps> = ({
  className,
  align,
  alignSelf,
  justify,
  wrap,
  direction,
  children,
  fullWidth,
  fullHeight,
  gap,
  onClick,
  style,
}) => (
  <div
    onClick={onClick}
    className={cn(className, 'pri-flex', {
      [`pri-flex-justify-${justify}`]: justify,
      [`pri-flex-align-${align}`]: align,
      [`pri-flex-align-self-${alignSelf}`]: alignSelf,
      [`pri-flex-direction-${direction}`]: direction,
      [`pri-flex-wrap-${wrap}`]: wrap,
      [`pri-flex-full-width`]: fullWidth,
      [`pri-flex-full-height`]: fullHeight,
      [`pri-flex-gap-${gap}`]: gap,
      'pri-flex-clickable': onClick,
    })}
    style={style}
  >
    {children}
  </div>
);

export default Flex;
