import React from 'react';
import cn from 'classnames';
import Bloc from '~/components/shared/shaping/Bloc';

import './style.scss';

type LoaderProps = {
  spacing?: 'sm' | 'md' | 'lg';
};

const Loader: FunctionComponent<LoaderProps> = ({ spacing = 'sm' }) => (
  <Bloc className={cn('pri-loader', `pri-loader-${spacing}`)}>
    <span className="pri-loader-element" />
  </Bloc>
);

export default Loader;
