import { useCallback, useEffect, useMemo } from 'react';
import { ok, refuse } from '@primo/operation-result';
import type { GetEmployeeDeviceQuery } from '~/graphql/schema';
import { useGetEmployeeDeviceLazyQuery } from '~/graphql/schema';
import type { UseQueryOptions } from '~/hooks/queries/types';

type UseGetEmployeeDeviceVariables = {
  deviceId: string;
};

const useQueryGetEmployeeDevice = (queryOptions: UseQueryOptions<UseGetEmployeeDeviceVariables> = {}) => {
  const serializeDeviceData = useCallback((data: GetEmployeeDeviceQuery | undefined) => {
    if (data?.getEmployeeDevice?.__typename === 'QueryGetEmployeeDeviceSuccess') {
      return ok(data.getEmployeeDevice.data);
    }
    if (data?.getEmployeeDevice?.__typename === 'QueryGetEmployeeDeviceError')
      return refuse(data.getEmployeeDevice.reasons);
    return refuse(['Unknown error']);
  }, []);

  const [query, { data: deviceData, loading: deviceLoading, called: deviceCalled }] = useGetEmployeeDeviceLazyQuery();

  const deviceResult = useMemo(() => serializeDeviceData(deviceData), [serializeDeviceData, deviceData]);

  const getEmployeeDevice = useCallback(
    async (variables: UseGetEmployeeDeviceVariables) => {
      const result = await query({ variables });
      return serializeDeviceData(result.data);
    },
    [query, serializeDeviceData],
  );

  useEffect(() => {
    if (queryOptions.runOnInit && !deviceCalled) getEmployeeDevice({ deviceId: queryOptions.deviceId });
  }, [getEmployeeDevice, queryOptions, deviceCalled]);

  return useMemo(
    () => ({
      getEmployeeDevice,
      deviceResult,
      deviceLoading,
      deviceCalled,
    }),
    [getEmployeeDevice, deviceResult, deviceLoading, deviceCalled],
  );
};

export default useQueryGetEmployeeDevice;
