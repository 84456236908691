import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSearchParam } from 'react-use';
import analytics from '~/analytics';

import { LOGIN_ROUTE, ROOT } from '~/config/routes';
import { PrimoAdminContext } from '~/providers/primo/PrimoAdminProvider';

const extractUserFromWrongOrganizationError = (input: string): [string, string] | null => {
  const regex = /user (\w+-oauth2\|\d+) is not part of the (org_[\w]+) organization/;
  const match = input.match(regex);

  if (match && match[1] && match[2]) {
    return [match[1], match[2]];
  }

  return null;
};
const LoginCallbackPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const to = useSearchParam('to');
  const [hash] = useState(window.location.hash);
  const { isAuthenticated, setConnectionError, setAccessToken } = useContext(PrimoAdminContext);

  useEffect(() => {
    if (isAuthenticated) {
      navigate(to || ROOT, { replace: true });
    }
  }, [isAuthenticated, to, navigate]);

  useEffect(() => {
    if (isAuthenticated) {
      return;
    }
    if (hash.match(/^#access_token/)) {
      const params = new URLSearchParams(hash.slice(1));
      const accessToken = params.get('access_token');
      if (accessToken) {
        setAccessToken(accessToken);
        return;
      }
    }
    if (hash.match(/^#error/)) {
      const params = new URLSearchParams(hash.slice(1));
      const error = params.get('error');
      const desc = params.get('error_description');

      const result = extractUserFromWrongOrganizationError(desc || '');
      if (result) {
        setConnectionError('errors.login.account_not_part_of_org');
        analytics?.track({
          type: 'track',
          event: 'login_error_account_not_part_of_org',
          userId: `company-${result[1]}`,
          properties: { type: 'company', googleUserId: result[0], auth0OrganizationId: result[1] },
        });
      } else {
        setConnectionError(error ? `errors.login.${error}` : 'errors.login.unable_to_login');
      }
    }
    navigate(LOGIN_ROUTE());
  }, [isAuthenticated, hash, setConnectionError, setAccessToken, navigate]);

  return null;
};

export default LoginCallbackPage;
