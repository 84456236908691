import { useCallback, useEffect, useMemo } from 'react';
import { ok, refuse } from '@primo/operation-result';
import type { GetEmployeeQuery } from '~/graphql/schema';
import { useGetEmployeeLazyQuery } from '~/graphql/schema';
import type { UseQueryOptions } from '~/hooks/queries/types';

type UseGetEmployeeVariables = {
  employeeId: string;
};

const useQueryGetEmployee = (queryOptions: UseQueryOptions<UseGetEmployeeVariables> = {}) => {
  const serializeEmployeeData = useCallback((data: GetEmployeeQuery | undefined) => {
    if (data?.getEmployee?.__typename === 'QueryGetEmployeeSuccess') {
      return ok(data.getEmployee.data);
    }
    if (data?.getEmployee?.__typename === 'QueryGetEmployeeError') return refuse(data.getEmployee.reasons);
    return refuse(['Unknown error']);
  }, []);

  const [query, { data: employeeData, loading: employeeLoading, called: employeeCalled }] = useGetEmployeeLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const employeeResult = useMemo(() => serializeEmployeeData(employeeData), [serializeEmployeeData, employeeData]);

  const getEmployee = useCallback(
    async (variables: UseGetEmployeeVariables) => {
      const result = await query({ variables });
      return serializeEmployeeData(result.data);
    },
    [query, serializeEmployeeData],
  );

  useEffect(() => {
    if (queryOptions.runOnInit && !employeeCalled) getEmployee({ employeeId: queryOptions.employeeId });
  }, [getEmployee, queryOptions, employeeCalled]);

  return useMemo(
    () => ({
      getEmployee,
      employeeResult,
      employeeLoading,
      employeeCalled,
    }),
    [getEmployee, employeeResult, employeeLoading, employeeCalled],
  );
};

export default useQueryGetEmployee;
