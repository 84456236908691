import type { HTMLAttributes } from 'react';
import React from 'react';

import LaptopIcon from '~/components/icons/laptop';
import MobileIcon from '~/components/icons/mobile';
import type { AccessoryType, DeviceType } from '~/graphql/schema';
import { ProductType } from '~/graphql/schema';
import MonitorIcon from '~/components/icons/monitor';
import CaseIcon from '~/components/icons/case';
import KeyboardIcon from '~/components/icons/keyboard';
import HubIcon from '~/components/icons/hub';
import HeadsetIcon from '~/components/icons/headset';
import MouseIcon from '~/components/icons/mouse';
import SpeakerIcon from '~/components/icons/speaker';
import CableAdapterIcon from '~/components/icons/adapter';
import ChargerIcon from '~/components/icons/charger';
import ErgonomicIcon from '~/components/icons/ergonomic';
import MousePadIcon from '~/components/icons/mousePad';
import StorageDeviceIcon from '~/components/icons/storageDevice';
import TabletIcon from '~/components/icons/tablet';
import WebcamIcon from '~/components/icons/webcam';
import ShippingMaterialIcon from '~/components/icons/shippingMaterial';

export interface EquipmentTypeIconProps extends HTMLAttributes<HTMLTableCellElement> {
  type?: ProductType | AccessoryType | DeviceType | null;
  width?: number;
}

const EquipmentTypeIconV2: FunctionComponent<EquipmentTypeIconProps> = ({ type, className, width }) => {
  switch (type) {
    case ProductType.Laptop:
      return <LaptopIcon className={className} width={width} />;
    case ProductType.Smartphone:
      return <MobileIcon className={className} width={width} />;
    case ProductType.CableAdapter:
      return <CableAdapterIcon className={className} width={width} />;
    case ProductType.Charger:
      return <ChargerIcon className={className} width={width} />;
    case ProductType.Ergonomic:
      return <ErgonomicIcon className={className} width={width} />;
    case ProductType.Headset:
      return <HeadsetIcon className={className} width={width} />;
    case ProductType.Hub:
      return <HubIcon className={className} width={width} />;
    case ProductType.Keyboard:
      return <KeyboardIcon className={className} width={width} />;
    case ProductType.LaptopCase:
      return <CaseIcon className={className} width={width} />;
    case ProductType.Monitor:
      return <MonitorIcon className={className} width={width} />;
    case ProductType.Mouse:
      return <MouseIcon className={className} width={width} />;
    case ProductType.Mousepad:
      return <MousePadIcon className={className} width={width} />;
    case ProductType.Speaker:
      return <SpeakerIcon className={className} width={width} />;
    case ProductType.StorageDevice:
      return <StorageDeviceIcon className={className} width={width} />;
    case ProductType.Tablet:
      return <TabletIcon className={className} width={width} />;
    case ProductType.Webcam:
      return <WebcamIcon className={className} width={width} />;
    case ProductType.ShippingMaterial:
      return <ShippingMaterialIcon className={className} width={width} />;
    default:
      return null;
  }
};

export default EquipmentTypeIconV2;
