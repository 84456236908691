import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { SSO_LOGIN_CHANNEL_ROUTE, SSO_LOGIN_OTP_ROUTE } from '~/config/routes';

import EmailForm from './EmailForm';
import ChannelForm from './ChannelForm';
import OTPForm from './OTPForm';
import Errors from './Errors';
import { SSOLoginProvider } from './context';
import LoginBackground from '~/features/layout/LoginBackgound';

import './index.scss';

const SSOLoginPage: FunctionComponent = () => (
  <SSOLoginProvider>
    <LoginBackground className="pri-otp-login-container">
      <Errors />
      <Routes>
        <Route index element={<EmailForm />} />
        <Route path={SSO_LOGIN_CHANNEL_ROUTE()} element={<ChannelForm />} />
        <Route path={SSO_LOGIN_OTP_ROUTE()} element={<OTPForm />} />
      </Routes>
    </LoginBackground>
  </SSOLoginProvider>
);

export default SSOLoginPage;
