import React, { useCallback, useMemo, useState } from 'react';

type NavContextProps = {
  current: string | null;
  open: (key: string) => void;
  toggle: (key: string) => void;
};

export const NavContext = React.createContext<NavContextProps>({
  current: null,
  open: () => undefined,
  toggle: () => undefined,
});

export const NavProvider: FunctionComponent = ({ children }) => {
  const [current, setCurrent] = useState<string | null>(null);

  const open = useCallback((key: string) => {
    setCurrent(key);
  }, []);
  const toggle = useCallback(
    (key: string) => {
      setCurrent(key === current ? null : key);
    },
    [current],
  );

  const value = useMemo(() => ({ current, open, toggle }), [current, open, toggle]);

  return <NavContext.Provider value={value}>{children}</NavContext.Provider>;
};
