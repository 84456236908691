import React from 'react';

const RadioCheckedIcon: SvgComponent = ({ className, width = 24, color = '#1E1E1E' }) => (
  <svg
    className={className}
    width={width}
    height={width}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M12.3333 1.5C10.2566 1.5 8.22648 2.11581 6.49977 3.26957C4.77305 4.42332 3.42724 6.0632 2.63252 7.98182C1.8378 9.90045 1.62987 12.0116 2.03501 14.0484C2.44016 16.0852 3.44018 17.9562 4.90864 19.4246C6.37709 20.8931 8.24801 21.8931 10.2848 22.2982C12.3216 22.7034 14.4328 22.4955 16.3514 21.7007C18.2701 20.906 19.9099 19.5602 21.0637 17.8335C22.2174 16.1068 22.8333 14.0767 22.8333 12C22.8333 9.21523 21.727 6.54451 19.7579 4.57538C17.7887 2.60625 15.118 1.5 12.3333 1.5ZM12.3333 21C10.5532 21 8.81317 20.4722 7.33312 19.4832C5.85308 18.4943 4.69953 17.0887 4.01834 15.4442C3.33715 13.7996 3.15892 11.99 3.50619 10.2442C3.85346 8.49836 4.71062 6.89471 5.9693 5.63604C7.22797 4.37737 8.83162 3.5202 10.5774 3.17293C12.3233 2.82567 14.1329 3.0039 15.7774 3.68508C17.4219 4.36627 18.8275 5.51983 19.8165 6.99987C20.8054 8.47991 21.3333 10.22 21.3333 12C21.3333 14.3869 20.385 16.6761 18.6972 18.364C17.0094 20.0518 14.7202 21 12.3333 21Z"
      fill={color}
    />
    <path
      d="M12.3333 7.5C11.4432 7.5 10.5732 7.76392 9.83319 8.25839C9.09317 8.75285 8.51639 9.45566 8.1758 10.2779C7.8352 11.1002 7.74609 12.005 7.91972 12.8779C8.09335 13.7508 8.52194 14.5526 9.15127 15.182C9.78061 15.8113 10.5824 16.2399 11.4553 16.4135C12.3283 16.5872 13.2331 16.4981 14.0553 16.1575C14.8776 15.8169 15.5804 15.2401 16.0749 14.5001C16.5693 13.76 16.8333 12.89 16.8333 12C16.8333 10.8065 16.3591 9.66193 15.5152 8.81802C14.6713 7.97411 13.5267 7.5 12.3333 7.5Z"
      fill={color}
    />
  </svg>
);

export default RadioCheckedIcon;
