import cn from 'classnames';
import React from 'react';
import { Text } from '../typography';

import './index.scss';

export type BadgeProps = {
  content: string | number;
  children?: never;
  size?: 'sm' | 'md';
  variant?: 'dark';
};

const Badge: FunctionComponent<BadgeProps> = ({ className, content, size, variant }) => (
  <span className={cn('pri-badge', { [`pri-badge-${size}`]: size, [`pri-badge-${variant}`]: variant }, className)}>
    <Text size="sm" weight="light" variant="light">
      {content}
    </Text>
  </span>
);

export default Badge;
