import type { TFunction } from 'i18next';

export function formatPropertyValue(t: TFunction, value: Maybe<string>, unit: Maybe<string>): string {
  const formattedValue = ['true', 'false'].includes(value || '')
    ? t(`properties.values.${value?.toLowerCase()}`)
    : `${value}`;
  const formattedUnit = unit ? `${t(`properties.units.${unit.toLowerCase()}`)}` : '';

  return `${formattedValue}${formattedUnit}`.trim();
}
