import { useEffect, useState } from 'react';
import { throttle } from 'lodash';

const useOnResize = () => {
  const [viewport, setViewPort] = useState<{ width: number; height: number }>({
    width: document.body.getBoundingClientRect().width,
    height: document.body.getBoundingClientRect().height,
  });

  useEffect(() => {
    const onResize = () => {
      setViewPort({
        width: document.body.getBoundingClientRect().width,
        height: document.body.getBoundingClientRect().height,
      });
    };

    window.addEventListener('resize', throttle(onResize, 200));

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return viewport;
};

export default useOnResize;
