import React from 'react';
import { useTranslation } from 'react-i18next';
import { displayPriceCurrency } from '~/utils/orders';

type PriceProps = {
  value: number | null | undefined;
  vat?: number;
};

const Price: FunctionComponent<PriceProps> = ({ value, vat }) => {
  const { i18n } = useTranslation();

  if (!value) return null;

  return <>{displayPriceCurrency(value, i18n.language, vat)}</>;
};

export default Price;
