import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

import type { Employee } from '~/graphql/schema';

/** Set datadog user when employee changes */
const useDatadogRefreshUser = (
  employee?: Pick<
    Employee,
    | 'id'
    | 'fullName'
    | 'companyId'
    | 'email'
    | 'picture'
    | 'initials'
    | 'isInternalEmployee'
    | 'firstName'
    | 'lastName'
    | 'phone'
    | 'isAdmin'
  > | null,
) => {
  useEffect(() => {
    if (employee) {
      datadogRum.setUser(employee);
      datadogLogs.setUser(employee);
    }
  }, [employee]);
};

export default useDatadogRefreshUser;
