import { useContext } from 'react';
import { useEffectOnce } from 'react-use';

import { PrimoAdminContext } from '~/providers/primo/PrimoAdminProvider';

const LogoutPage: FunctionComponent = () => {
  const { logout } = useContext(PrimoAdminContext);

  useEffectOnce(() => {
    logout();
  });

  return null;
};

export default LogoutPage;
