import { useCallback, useEffect, useMemo } from 'react';
import { ok, refuse } from '@primo/operation-result';
import type { CreateCompanyBillingProfileMutation } from '~/graphql/schema';
import { useCreateCompanyBillingProfileMutation } from '~/graphql/schema';
import type { UseQueryOptions } from '~/hooks/queries/types';

type UseCreateCompanyBillingProfileVariables = {
  companyId: string;
  name: string;
};

const useMutationCreateCompanyBillingProfile = (
  queryOptions: UseQueryOptions<UseCreateCompanyBillingProfileVariables> = {},
) => {
  const serializeCreateCompanyBillingProfileData = useCallback(
    (data: CreateCompanyBillingProfileMutation | undefined | null) => {
      if (data?.createCompanyBillingProfile?.__typename === 'MutationCreateCompanyBillingProfileSuccess') {
        return ok(data.createCompanyBillingProfile.data);
      }
      if (data?.createCompanyBillingProfile?.__typename === 'MutationCreateCompanyBillingProfileError')
        return refuse(data.createCompanyBillingProfile.reasons);
      return refuse(['Unknown error']);
    },
    [],
  );

  const [
    mutation,
    {
      data: createCompanyBillingProfileData,
      loading: createCompanyBillingProfileDataLoading,
      called: createCompanyBillingProfileDataCalled,
    },
  ] = useCreateCompanyBillingProfileMutation({
    refetchQueries: ['GetCompanyBillingProfiles'],
  });

  const createCompanyBillingProfileDataResult = useMemo(
    () => serializeCreateCompanyBillingProfileData(createCompanyBillingProfileData),
    [serializeCreateCompanyBillingProfileData, createCompanyBillingProfileData],
  );

  const createCompanyBillingProfile = useCallback(
    async (variables: UseCreateCompanyBillingProfileVariables) => {
      const result = await mutation({
        variables,
      });
      return serializeCreateCompanyBillingProfileData(result.data);
    },
    [mutation, serializeCreateCompanyBillingProfileData],
  );

  useEffect(() => {
    if (queryOptions.runOnInit && !createCompanyBillingProfileDataCalled)
      createCompanyBillingProfile({ ...queryOptions });
  }, [createCompanyBillingProfile, queryOptions, createCompanyBillingProfileDataCalled]);

  return useMemo(
    () => ({
      createCompanyBillingProfile,
      createCompanyBillingProfileDataResult,
      createCompanyBillingProfileDataLoading,
      createCompanyBillingProfileDataCalled,
    }),
    [
      createCompanyBillingProfile,
      createCompanyBillingProfileDataResult,
      createCompanyBillingProfileDataLoading,
      createCompanyBillingProfileDataCalled,
    ],
  );
};

export default useMutationCreateCompanyBillingProfile;
