import React, { useContext, useMemo } from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Text } from '~/components/shared/typography';
import Flex from '~/components/shared/shaping/Flex';

import StarFilledIcon from '~/components/icons/starFilled';
import StarEmptyIcon from '~/components/icons/starEmpty';
import Bloc from '~/components/shared/shaping/Bloc';
import Price from '~/components/shared/Price';
import { sortProperties } from '~/utils/sortProperties';
import { formatPropertyValue } from '~/utils/formatPropertyValue';
import Filler from '~/components/shared/shaping/Filler';
import EquipmentPicture from '~/components/featured/EquipmentPicture';

import './style.scss';
import type { QuantitySelectProps } from '~/components/shared/forms/QuantitySelect';
import QuantitySelect from '~/components/shared/forms/QuantitySelect';
import EquipmentProperty from '~/components/featured/EquipmentProperty';
import type { DeviceCondition, DeviceKeyboardLayout } from '~/graphql/schema';
import { DeviceType, EnrollmentType } from '~/graphql/schema';
import DeviceTagsPills from '~/components/featured/pills/devicePills/DeviceTagsPills';
import DeviceConditionStars from '~/components/featured/DeviceConditionStars';
import { PrimoAdminContext } from '~/providers/primo/PrimoAdminProvider';
import DeviceEnrollmentTypePill from '~/components/featured/pills/devicePills/DeviceEnrollmentTypePill';

type EquipmentCardProps = {
  children?: never;
  equipment: {
    id: string;
    name?: string | null;
    type?: string | null;
    brand?: string | null;
    picture?: string | null;
    priceNoVAT?: number | null;
    properties?: {
      key: string;
      value?: string | null;
      type?: 'BOOLEAN' | 'STRING' | null;
      unit?: string | null;
    }[];
    serialNumber?: string | null;
    year?: number | null;
    condition?: DeviceCondition | null;
    inchScreenSize?: number | null;
    keyboardLayout?: DeviceKeyboardLayout | null;
    tags?: string[];
    refurbished?: boolean | null;
  };
  star?: {
    filled: boolean;
    onClick: (equipmentId: string) => void;
  };
  quantitySelectProps?: QuantitySelectProps;
  fromCatalog: boolean;
  heightAuto?: boolean;
};

const EquipmentCard: FunctionComponent<EquipmentCardProps> = ({
  className,
  equipment,
  star,
  quantitySelectProps,
  fromCatalog,
  heightAuto,
}) => {
  const { t } = useTranslation();
  const { companySetup } = useContext(PrimoAdminContext);

  const form = useForm();

  const isSelected = useMemo(() => {
    if (!quantitySelectProps) return false;
    if (quantitySelectProps.name && quantitySelectProps.control) return form.getValues()[quantitySelectProps.name] > 0;
    return (quantitySelectProps.quantity || 0) > 0;
  }, [form, quantitySelectProps]);

  const companyUsesZTD = companySetup?.hasMdm && companySetup?.appleBMAccess && companySetup?.appleOrgId;
  const isMacOSProduct = equipment?.brand
    ? equipment?.type === DeviceType.Laptop && ['APPLE', 'MACOS'].includes(equipment.brand.toUpperCase().trim())
    : false;

  const shouldDisplayZTD = companyUsesZTD && isMacOSProduct && !equipment?.refurbished;

  return (
    <Flex
      className={cn(
        'pri-equipment-card',
        { 'pri-equipment-card-selected': isSelected, 'pri-height-auto': heightAuto },
        className,
      )}
      direction="column"
    >
      {star && (
        <Bloc className="star-icon" onClick={() => star.onClick(equipment.id)}>
          {star.filled ? <StarFilledIcon color="#FFE81D" /> : <StarEmptyIcon />}
        </Bloc>
      )}
      {shouldDisplayZTD && (
        <Bloc className="ztd-tag">
          <DeviceEnrollmentTypePill enrollmentType={EnrollmentType.Automatic} />
        </Bloc>
      )}

      <Flex justify="center" align="center">
        <EquipmentPicture equipment={equipment} />
      </Flex>
      <Flex direction="column" gap={4} className="pri-p-4 flex-1">
        <Flex wrap="wrap" align="center" gap={3}>
          <Text weight="medium" maxLength={30}>
            {equipment.name || '-'}
          </Text>
          {equipment.tags && <DeviceTagsPills device={{ id: '-', tags: equipment.tags }} />}
        </Flex>
        <Flex direction="column" gap={2}>
          {equipment.condition && (
            <Flex justify="between">
              <Text weight="light" size="sm">
                {t(`properties.devices.condition`)}
              </Text>
              <DeviceConditionStars condition={equipment.condition} size="sm" />
            </Flex>
          )}
          {fromCatalog ? (
            sortProperties(equipment.properties || []).map(property => (
              <EquipmentProperty
                key={property.key}
                name={t(`properties.devices.${property.key}`)}
                value={formatPropertyValue(t, property.value, property.unit)}
              />
            ))
          ) : (
            <>
              {equipment.inchScreenSize && (
                <EquipmentProperty
                  name={t('forms.labels.screen_size')}
                  value={`${equipment.inchScreenSize.toString()}"`}
                />
              )}
              {equipment.year && <EquipmentProperty name={t('forms.labels.year')} value={equipment.year.toString()} />}
              {equipment.keyboardLayout && (
                <EquipmentProperty
                  name={t('forms.labels.keyboard_layout')}
                  value={t(`enums.keyboard_layouts.${equipment.keyboardLayout.toLowerCase()}`)}
                />
              )}
              {equipment.serialNumber && (
                <EquipmentProperty name={t('forms.labels.serial_number')} value={equipment.serialNumber} />
              )}
            </>
          )}
        </Flex>
        <Filler className="pri-mt--4" />
        <Flex justify="between" align="center">
          <Text weight="medium">{equipment.priceNoVAT ? <Price value={equipment.priceNoVAT} /> : <>&nbsp;</>}</Text>
          <Bloc>{quantitySelectProps ? <QuantitySelect {...quantitySelectProps} /> : <>&nbsp;</>}</Bloc>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default EquipmentCard;
