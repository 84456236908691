import { Checkbox as CarbonCheckbox, ClassPrefix } from '@carbon/react';
import cn from 'classnames';
import type { ReactNode } from 'react';
import React, { useMemo } from 'react';

export type CheckBoxProps = {
  checked: boolean;
  label: string | NonNullable<ReactNode>;
  invalid?: boolean;
  invalidText?: string;
  defaultChecked?: boolean;
  onClick?: () => void;

  children?: never;
};

const CheckBox: FunctionComponent<CheckBoxProps> = ({
  className,
  checked,
  label,
  invalid,
  onClick,
  invalidText,
  defaultChecked,
}) => {
  const id = useMemo(() => `checkbox-${Math.random().toFixed(10).slice(2, 10)}`, []);

  return (
    <ClassPrefix prefix="carbon">
      <CarbonCheckbox
        checked={checked}
        className={cn('pri-checkbox', className)}
        id={id}
        onClick={onClick}
        defaultChecked={defaultChecked}
        labelText={label}
        invalid={invalid}
        invalidText={invalidText}
      />
    </ClassPrefix>
  );
};

export default CheckBox;
