import React, { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';

import { Step } from '~/components/shared/Stepper';
import { StepperContext } from '~/components/shared/Stepper/_components/StepperContext';
import { OnboardingStepperContext } from '~/features/steppers/OnboardingStepper/context';
import Flex from '~/components/shared/shaping/Flex';
import Tabs from '~/components/shared/Tabs';
import Tab from '~/components/shared/Tabs/Tab';
import { Text, Title } from '~/components/shared/typography';
import { Form } from '~/components/shared/forms/Form';
import isDevice from '~/utils/products/isDevice';
import isAccessory from '~/utils/products/isAccessory';
import Radio from '~/components/shared/forms/Radio';
import EquipmentCard from '~/components/featured/cards/EquipmentCard';
import Bloc from '~/components/shared/shaping/Bloc';
import type { OnboardingStepperContextData } from '~/features/steppers/OnboardingStepper/types';
import { useCompany } from '~/hooks/useCompany';

import useComputeSelectedEquipments from './useComputeSelectedEquipments';
import useInitForm from './useInitForm';

const ChooseEquipmentsStep: FunctionComponent = () => {
  const { t } = useTranslation();
  const { setFooterProps } = useContext(StepperContext);
  const { inventory, catalog, setContextData, contextData, onFinish } = useContext(OnboardingStepperContext);
  const company = useCompany();

  const form = useInitForm();

  const { watch, control } = form;

  const from = watch('from');
  const selection = watch('selection');
  const selectedEquipments = useComputeSelectedEquipments(cloneDeep(selection));
  const hasSelectedCatalog = selectedEquipments.catalog.length > 0;
  const catalogSelected = from === 'catalog';

  const nextLabel = useMemo(() => {
    if (company?.hasProvisioning) {
      return t('buttons.next');
    }
    return hasSelectedCatalog ? t('buttons.next') : t('steppers.onboarding.confirm_onboarding');
  }, [t, hasSelectedCatalog, company]);

  useEffect(() => {
    setFooterProps({
      nextLabel,
      onNext: () => {
        const newContextData: OnboardingStepperContextData = {
          ...contextData,
          equipments: { inventory: [], catalog: [] },
        };

        if (selectedEquipments.inventory.length > 0)
          newContextData.equipments?.inventory.push(...selectedEquipments.inventory);
        if (selectedEquipments.catalog.length > 0)
          newContextData.equipments?.catalog.push(...selectedEquipments.catalog);

        setContextData(newContextData);

        if (!hasSelectedCatalog && !company?.hasProvisioning) {
          onFinish(newContextData);
          return false;
        }

        return true;
      },
    });
  }, [
    nextLabel,
    contextData,
    hasSelectedCatalog,
    company,
    selectedEquipments,
    setFooterProps,
    setContextData,
    onFinish,
  ]);

  return (
    <Step size="lg">
      <Title className="pri-mb-9" level={2}>
        {t('steppers.onboarding.select_their_equipment')}
      </Title>
      <Form form={form}>
        <Flex direction="column" gap={9}>
          <Text size="lg" weight="medium">
            {t('steppers.onboarding.select_equipments')}
          </Text>
          <Flex fullWidth justify="end" className="pri-mb--3">
            <Bloc style={{ zIndex: 99 }}>
              <Radio
                inline
                name="from"
                options={[
                  { label: t('forms.labels.from_catalog'), value: 'catalog' },
                  { label: t('forms.labels.from_stock'), value: 'stock' },
                ]}
                control={control}
              />
            </Bloc>
          </Flex>
          <Tabs className="pri-mt--10">
            <Tab title={t('tabs.devices')}>
              <Flex gap={5} wrap="wrap">
                {(catalogSelected ? catalog.filter(isDevice) : inventory.devices).map(device => (
                  <EquipmentCard
                    key={device.id}
                    equipment={device}
                    fromCatalog={catalogSelected}
                    quantitySelectProps={{
                      name: `selection.${catalogSelected ? 'catalog' : 'inventory'}.${device.id}`,
                      control,
                      max: catalogSelected ? 10 : 1,
                      min: 0,
                    }}
                    heightAuto
                  />
                ))}
              </Flex>
            </Tab>
            <Tab title={t('tabs.accessories')}>
              <Flex gap={5} wrap="wrap">
                {(catalogSelected ? catalog.filter(isAccessory) : inventory.accessories).map(accessory => (
                  <EquipmentCard
                    key={accessory.id}
                    equipment={accessory}
                    fromCatalog={catalogSelected}
                    quantitySelectProps={{
                      name: `selection.${catalogSelected ? 'catalog' : 'inventory'}.${accessory.id}`,
                      control,
                      max: catalogSelected ? 10 : 1,
                      min: 0,
                    }}
                    heightAuto
                  />
                ))}
              </Flex>
            </Tab>
          </Tabs>
        </Flex>
      </Form>
    </Step>
  );
};

export default ChooseEquipmentsStep;
