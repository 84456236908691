import React from 'react';

import Flex from '~/components/shared/shaping/Flex';
import { Text } from '~/components/shared/typography';

type EquipmentPropertyProps = {
  name: string;
  value: string;
};

const EquipmentProperty: FunctionComponent<EquipmentPropertyProps> = ({ name, value }) => (
  <Flex justify="between">
    <Text weight="light" size="sm">
      {name}
    </Text>
    <Text weight="light" size="sm" maxLength={16}>
      {value}
    </Text>
  </Flex>
);
export default EquipmentProperty;
