import { useCallback, useContext, useMemo } from 'react';
import type { GetPendingOnboardingsQuery } from '~/graphql/schema';
import { EmployeeOnboardingStatus, useGetPendingOnboardingsQuery } from '~/graphql/schema';
import { PrimoAdminContext } from '~/providers/primo/PrimoAdminProvider';

const useGetPendingOnboardings = () => {
  const { company } = useContext(PrimoAdminContext);
  const companyId = company?.id;

  const getOnboardings = useCallback((data: GetPendingOnboardingsQuery | undefined) => {
    if (data?.getEmployeeOnboardings?.__typename === 'QueryGetEmployeeOnboardingsSuccess') {
      return data.getEmployeeOnboardings.data.list ?? [];
    }
    return [];
  }, []);

  const {
    data: pendingOnboardingsData,
    loading: pendingOnboardingsLoading,
    called: pendingOnboardingsCalled,
  } = useGetPendingOnboardingsQuery({
    variables: { companyId, status: [EmployeeOnboardingStatus.Pending] },
    fetchPolicy: 'no-cache',
  });
  const pendingOnboardings = useMemo(
    () => getOnboardings(pendingOnboardingsData),
    [getOnboardings, pendingOnboardingsData],
  );

  return {
    pendingOnboardings,
    pendingOnboardingsLoading,
    pendingOnboardingsCalled,
  };
};

export default useGetPendingOnboardings;
