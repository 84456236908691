import React, { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSearchParam } from 'react-use';
import Loader from '~/components/shared/Loader';

import { EMPLOYEE_SESSION_BASE_ROUTE_LINK } from '~/config/routes';
import { PrimoEmployeeContext } from '~/providers/primo/PrimoEmployeeProvider';

const EmployeeLoginPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const to = useSearchParam('to');
  const params = useParams();

  const { slug } = params;
  const { isAuthenticated, isLoading } = useContext(PrimoEmployeeContext);

  useEffect(() => {
    if (isAuthenticated && slug) {
      navigate(to || EMPLOYEE_SESSION_BASE_ROUTE_LINK(slug), { replace: true });
    }
  }, [isAuthenticated, to, navigate, slug]);

  useEffect(() => {
    if (isAuthenticated) {
      return;
    }

    // Redirect to BFF for SAML authorization
    if (!isAuthenticated && !isLoading) {
      window.location.href = `/saml/${slug}/authorize`;
    }
  }, [isAuthenticated, isLoading, navigate, to, slug]);

  if (isLoading) return <Loader />;

  if (!isAuthenticated) {
    return null;
  }

  return null;
};

export default EmployeeLoginPage;
