import type { ReactElement } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Flex from '~/components/shared/shaping/Flex';
import { Text } from '~/components/shared/typography';

export type CardItemPropertyProps = {
  label: string;
  value?: string | null | ReactElement;
  children?: never;
};

const CardItemPropertyValue: FunctionComponent<Pick<CardItemPropertyProps, 'value'>> = ({ value }) =>
  value && typeof value === 'string' ? (
    <Text weight="light" align="right" variant="muted" maxLength={40}>
      {value}
    </Text>
  ) : (
    (value as ReactElement | null)
  );

const CardItemProperty: FunctionComponent<CardItemPropertyProps> = ({ className, label, value }) => {
  const { t } = useTranslation();

  return (
    <Flex justify="between" align="center" className={className} gap={4}>
      <Text>{label}</Text>
      {value ? (
        <CardItemPropertyValue value={value} />
      ) : (
        <Text weight="light" variant="light-muted" align="right">
          {t('texts.not_added')}
        </Text>
      )}
    </Flex>
  );
};

export default CardItemProperty;
