import { useCallback, useEffect, useMemo } from 'react';
import { ok, refuse } from '@primo/operation-result';
import type { CreateOrUpdateCompanyAddressMutation } from '~/graphql/schema';
import { useCreateOrUpdateCompanyAddressMutation } from '~/graphql/schema';
import type { UseQueryOptions } from '~/hooks/queries/types';

type UseCreateOrUpdateCompanyAddressVariables = {
  companyId: string;
  address: {
    id?: string;
    name: string;
    city: string;
    complement?: string | null;
    country: string;
    phone?: string | null;
    postalCode: string;
    street: string;
  };
};

const useMutationCreateOrUpdateCompanyAddress = (
  queryOptions: UseQueryOptions<UseCreateOrUpdateCompanyAddressVariables> = {},
) => {
  const serializeCreateOrUpdateCompanyAddressData = useCallback(
    (data: CreateOrUpdateCompanyAddressMutation | undefined | null) => {
      if (data?.createOrUpdateCompanyAddress?.__typename === 'MutationCreateOrUpdateCompanyAddressSuccess') {
        return ok(data.createOrUpdateCompanyAddress.data);
      }
      if (data?.createOrUpdateCompanyAddress?.__typename === 'MutationCreateOrUpdateCompanyAddressError')
        return refuse(data.createOrUpdateCompanyAddress.reasons);
      return refuse(['Unknown error']);
    },
    [],
  );

  const [
    mutation,
    {
      data: createOrUpdateCompanyAddressData,
      loading: createOrUpdateCompanyAddressLoading,
      called: createOrUpdateCompanyAddressCalled,
    },
  ] = useCreateOrUpdateCompanyAddressMutation({ refetchQueries: ['GetEmployeeCompany'] });

  const createOrUpdateCompanyAddressResult = useMemo(
    () => serializeCreateOrUpdateCompanyAddressData(createOrUpdateCompanyAddressData),
    [serializeCreateOrUpdateCompanyAddressData, createOrUpdateCompanyAddressData],
  );

  const createOrUpdateCompanyAddress = useCallback(
    async (variables: UseCreateOrUpdateCompanyAddressVariables) => {
      const result = await mutation({
        variables,
        refetchQueries: ['GetEmployeeCompany'],
      });
      return serializeCreateOrUpdateCompanyAddressData(result.data);
    },
    [mutation, serializeCreateOrUpdateCompanyAddressData],
  );

  useEffect(() => {
    if (queryOptions.runOnInit && !createOrUpdateCompanyAddressCalled)
      createOrUpdateCompanyAddress({ ...queryOptions });
  }, [createOrUpdateCompanyAddress, queryOptions, createOrUpdateCompanyAddressCalled]);

  return useMemo(
    () => ({
      createOrUpdateCompanyAddress,
      createOrUpdateCompanyAddressResult,
      createOrUpdateCompanyAddressLoading,
      createOrUpdateCompanyAddressCalled,
    }),
    [
      createOrUpdateCompanyAddress,
      createOrUpdateCompanyAddressResult,
      createOrUpdateCompanyAddressLoading,
      createOrUpdateCompanyAddressCalled,
    ],
  );
};
export default useMutationCreateOrUpdateCompanyAddress;
