import { useCallback, useEffect, useMemo } from 'react';
import { ok, refuse } from '@primo/operation-result';
import type { GetDevicesTagsQuery } from '~/graphql/schema';
import { useGetDevicesTagsLazyQuery } from '~/graphql/schema';
import type { UseQueryOptions } from '~/hooks/queries/types';

type UseGetDevicesTagsVariables = {
  companyId: string;
};

const useQueryGetDevicesTags = (queryOptions: UseQueryOptions<UseGetDevicesTagsVariables> = {}) => {
  const serializeDeviceData = useCallback((data: GetDevicesTagsQuery | undefined) => {
    if (data?.getDevicesTags?.__typename === 'QueryGetDevicesTagsSuccess') {
      return ok(data.getDevicesTags.data);
    }
    if (data?.getDevicesTags?.__typename === 'QueryGetDevicesTagsError') return refuse(data.getDevicesTags.reasons);
    return refuse(['Unknown error']);
  }, []);

  const [query, { data: deviceData, loading: devicesTagsLoading, called: devicesTagsCalled }] =
    useGetDevicesTagsLazyQuery({ fetchPolicy: 'no-cache' });

  const devicesTagsResult = useMemo(() => serializeDeviceData(deviceData), [serializeDeviceData, deviceData]);

  const getDevicesTags = useCallback(
    async (variables: UseGetDevicesTagsVariables) => {
      const result = await query({ variables });
      return serializeDeviceData(result.data);
    },
    [query, serializeDeviceData],
  );

  useEffect(() => {
    if (queryOptions.runOnInit && !devicesTagsCalled) getDevicesTags({ companyId: queryOptions.companyId });
  }, [getDevicesTags, queryOptions, devicesTagsCalled]);

  return useMemo(
    () => ({
      getDevicesTags,
      devicesTagsResult,
      devicesTagsLoading,
      devicesTagsCalled,
    }),
    [getDevicesTags, devicesTagsResult, devicesTagsLoading, devicesTagsCalled],
  );
};

export default useQueryGetDevicesTags;
