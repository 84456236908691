import axios from 'axios';
import { useEffect } from 'react';
import { useAsync } from 'react-use';
import analytics from '~/analytics';
import type { Company, Employee } from '~/graphql/schema';

export type SegmentAnalyticsProps = {
  token: string | undefined;
  employee: Pick<
    Employee,
    'id' | 'fullName' | 'companyId' | 'email' | 'firstName' | 'lastName' | 'phone' | 'isAdmin'
  > | null;
  company: Pick<Company, 'id' | 'name'> | null;
};

export const useSegmentAnalytics = ({ token, employee, company }: SegmentAnalyticsProps) => {
  const { value: userHash } = useAsync(async () => {
    if (!token) {
      return null;
    }
    const { data } = await axios.post<{ userHash: string | null }>(
      '/intercom',
      {},
      {
        headers: { authorization: `Bearer ${token}` },
      },
    );

    return data.userHash;
  }, [token]);

  useEffect(() => {
    if (employee && company && userHash) {
      analytics?.identify(
        employee.id,
        {
          id: employee.id,
          email: employee.email,
          name: employee.fullName,
          firstName: employee.firstName,
          lastName: employee.lastName,
          phone: employee.phone,
          isAdmin: employee.isAdmin,
          company: {
            id: company.id,
            name: company.name,
          },
        },
        {
          Intercom: {
            user_hash: userHash,
          },
        },
      );
    }
  }, [employee, company, userHash]);

  return null;
};
