import React, { createContext, useCallback, useMemo, useState } from 'react';
import { useBoolean } from 'react-use';

import type { OnboardingStepperProps } from '~/features/steppers/OnboardingStepper';
import OnboardingStepper from '~/features/steppers/OnboardingStepper';
import type { OffboardingStepperProps } from '~/features/steppers/OffboardingStepper';
import OffboardingStepper from '~/features/steppers/OffboardingStepper';
import type { OrderStepperProps } from '~/features/steppers/OrderStepper';
import OrderStepper from '~/features/steppers/OrderStepper';

type OnboardingStepperPartialProps = Omit<OnboardingStepperProps, 'onClose'>;
type OffboardingStepperPartialProps = Omit<OffboardingStepperProps, 'onClose'>;
type OrderStepperPartialProps = Omit<OrderStepperProps, 'onClose'>;

export type SteppersContextProps = {
  openOrderStepper: (props: OrderStepperPartialProps) => void;
  openOnboardingStepper: (props: OnboardingStepperPartialProps) => void;
  openOffboardingStepper: (props: OffboardingStepperPartialProps) => void;
};

export const SteppersContext = createContext<SteppersContextProps>({
  openOrderStepper: () => {},
  openOnboardingStepper: () => {},
  openOffboardingStepper: () => {},
});

export const SteppersProvider: FunctionComponent = ({ children }) => {
  const [showOnboardingStepper, setShowOnboardingStepper] = useBoolean(false);
  const [onboardingStepperProps, setOnboardingStepperProps] = useState<OnboardingStepperPartialProps>({});

  const [showOrderStepper, setShowOrderStepper] = useBoolean(false);
  const [orderStepperProps, setOrderStepperProps] = useState<OrderStepperPartialProps>({});

  const [showOffboardingStepper, setShowOffboardingStepper] = useBoolean(false);
  const [offboardingStepperProps, setOffboardingStepperProps] = useState<OffboardingStepperPartialProps>({});

  const openOrderStepper = useCallback(
    (props: OrderStepperPartialProps) => {
      setOrderStepperProps(props);
      setShowOrderStepper(true);
    },
    [setShowOrderStepper],
  );

  const openOnboardingStepper = useCallback(
    (props: OnboardingStepperPartialProps) => {
      setOnboardingStepperProps(props);
      setShowOnboardingStepper(true);
    },
    [setShowOnboardingStepper],
  );
  const openOffboardingStepper = useCallback(
    (props: OffboardingStepperPartialProps) => {
      setOffboardingStepperProps(props);
      setShowOffboardingStepper(true);
    },
    [setShowOffboardingStepper],
  );

  const value = useMemo(
    () => ({ openOrderStepper, openOnboardingStepper, openOffboardingStepper }),
    [openOrderStepper, openOnboardingStepper, openOffboardingStepper],
  );

  return (
    <SteppersContext.Provider value={value}>
      {children}
      {showOnboardingStepper && (
        <OnboardingStepper
          {...onboardingStepperProps}
          onClose={() => {
            setShowOnboardingStepper(false);
            setOnboardingStepperProps({});
          }}
        />
      )}
      {showOffboardingStepper && (
        <OffboardingStepper
          {...offboardingStepperProps}
          onClose={() => {
            setShowOffboardingStepper(false);
            setOffboardingStepperProps({});
          }}
        />
      )}
      {showOrderStepper && (
        <OrderStepper
          {...orderStepperProps}
          onClose={() => {
            setShowOrderStepper(false);
            setOrderStepperProps({});
          }}
        />
      )}
    </SteppersContext.Provider>
  );
};
