import React from 'react';

const ErgonomicIcon: SvgComponent = ({ className, width = 24 }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M17 27.172V22H15V27.172L12.414 24.586L11 26L16 31L21 26L19.586 24.586L17 27.172Z" fill="black" />
    <path
      d="M30 12H2V20H4V30H6V20H26V30H28V20H30V12ZM28 18H4V14H28V18ZM15 4.828V10H17V4.828L19.586 7.414L21 6L16 1L11 6L12.414 7.414L15 4.828Z"
      fill="black"
    />
  </svg>
);

export default ErgonomicIcon;
