import cn from 'classnames';
import React, { useMemo } from 'react';
import CarbonTag from '@carbon/react/lib/components/Tag/Tag';
import { Text } from '../typography/Text';

import './index.scss';

export type PillProps = {
  color?: 'yellow' | 'red' | 'green' | 'blue' | 'grey' | 'lightgrey';
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  size?: 'xs' | 'sm' | 'md';
  icon?: React.ReactNode;
};

const Pill: FunctionComponent<PillProps> = ({ className, children, color = 'blue', onClick, size = 'md', icon }) => {
  const specificClassName = useMemo(
    () => (typeof children === 'string' ? `pri-pill-${children.toLowerCase().replaceAll(' ', '_')}` : ''),
    [children],
  );

  return (
    <CarbonTag
      className={cn(
        'pri-pill',
        `pri-pill-color-${color}`,
        `pri-pill-size-${size}`,
        className,
        specificClassName,
        icon ? 'pri-pill-with-icon' : '',
      )}
      onClick={onClick}
    >
      {icon}
      <Text size="sm">{children}</Text>
    </CarbonTag>
  );
};

export default Pill;
