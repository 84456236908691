import React from 'react';

const LockIcon: SvgComponent = ({ className, width = 24, color = 'black' }) => (
  <svg className={className} width={width} height={width} viewBox="0 0 26 26">
    <path
      d="M19.5 11.375H17.875V6.5C17.875 5.20707 17.3614 3.96709 16.4471 3.05285C15.5329 2.13861 14.2929 1.625 13 1.625C11.7071 1.625 10.4671 2.13861 9.55285 3.05285C8.63861 3.96709 8.125 5.20707 8.125 6.5V11.375H6.5C6.06902 11.375 5.6557 11.5462 5.35095 11.851C5.0462 12.1557 4.875 12.569 4.875 13V22.75C4.875 23.181 5.0462 23.5943 5.35095 23.899C5.6557 24.2038 6.06902 24.375 6.5 24.375H19.5C19.931 24.375 20.3443 24.2038 20.649 23.899C20.9538 23.5943 21.125 23.181 21.125 22.75V13C21.125 12.569 20.9538 12.1557 20.649 11.851C20.3443 11.5462 19.931 11.375 19.5 11.375ZM9.75 6.5C9.75 5.63805 10.0924 4.8114 10.7019 4.2019C11.3114 3.59241 12.138 3.25 13 3.25C13.862 3.25 14.6886 3.59241 15.2981 4.2019C15.9076 4.8114 16.25 5.63805 16.25 6.5V11.375H9.75V6.5ZM19.5 22.75H6.5V13H19.5V22.75Z"
      fill={color}
    />
  </svg>
);

export default LockIcon;
