import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import cn from 'classnames';

import './index.scss';
import Tooltip from '~/components/shared/Tooltip';

type SharedProps = {
  variant?: 'dark' | 'light' | 'primary' | 'muted';
  level?: 1 | 2 | 3 | 4 | 5 | 6;
  bold?: boolean;
  align?: 'left' | 'center' | 'right';
};

type TitlePropsGeneric = SharedProps & {
  children?: ReactNode | ReactNode[];
  maxLength?: never;
};

type TitlePropsMaxLength = SharedProps & {
  maxLength?: number;
  children: string;
};

export type TitleProps = TitlePropsGeneric | TitlePropsMaxLength;

export const Title: FunctionComponent<TitleProps> = ({
  className,
  variant,
  children,
  bold = false,
  level = 3,
  align,
  ...rest
}) => {
  const Heading = `h${level}` as keyof JSX.IntrinsicElements;
  const hasMaxLength = 'maxLength' in rest && typeof children === 'string';

  const title = useMemo(() => {
    if (!hasMaxLength) return children;
    return children.length > (rest.maxLength || 0) ? (
      <Tooltip label={children}>{`${children.slice(0, rest.maxLength)}...`}</Tooltip>
    ) : (
      children
    );
  }, [children, hasMaxLength, rest.maxLength]);

  return (
    <Heading
      className={cn(className, 'pri-title', `pri-title-${level}`, {
        [`pri-title-variant-${variant}`]: variant,
        'pri-title-bold': bold,
        [`pri-title-align-${align}`]: align,
      })}
    >
      {title}
    </Heading>
  );
};
