import { useContext } from 'react';

import { useSegmentAnalytics } from '~/hooks/useSegmentAnalytics';

import { PrimoAdminContext } from '~/providers/primo/PrimoAdminProvider';
import { PrimoEmployeeContext } from '~/providers/primo/PrimoEmployeeProvider';

export const AdminSegment: FunctionComponent = () => {
  const { employee, company, token } = useContext(PrimoAdminContext);

  useSegmentAnalytics({ token, employee, company });
  return null;
};

export const EmployeeSegment: FunctionComponent = () => {
  const { employee, company, token } = useContext(PrimoEmployeeContext);

  useSegmentAnalytics({ token, employee, company });
  return null;
};
