import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { toast } from 'react-toastify';
import { Step } from '~/components/shared/Stepper';
import { StepperContext } from '~/components/shared/Stepper/_components/StepperContext';
import { OrderStepperContext } from '~/features/steppers/OrderStepper/context';
import Flex from '~/components/shared/shaping/Flex';
import Tabs from '~/components/shared/Tabs';
import Tab from '~/components/shared/Tabs/Tab';
import { Text } from '~/components/shared/typography';
import { Form } from '~/components/shared/forms/Form';
import isDevice from '~/utils/products/isDevice';
import isAccessory from '~/utils/products/isAccessory';
import EquipmentCard from '~/components/featured/cards/EquipmentCard';
import type { OrderStepperContextData } from '~/features/steppers/OrderStepper/types';
import useComputeSelectedEquipments from './useComputeSelectedEquipments';
import useInitForm from './useInitForm';

const ChooseEquipmentsStep: FunctionComponent = () => {
  const { t } = useTranslation();
  const { setFooterProps } = useContext(StepperContext);
  const { catalog, setContextData, contextData } = useContext(OrderStepperContext);

  const form = useInitForm();

  const { watch, control } = form;

  const selection = watch('selection');
  const selectedEquipments = useComputeSelectedEquipments(cloneDeep(selection));
  const hasSelectedEquipments = selectedEquipments.length > 0;

  useEffect(() => {
    setFooterProps({
      nextLabel: t('buttons.next'),
      onNext: () => {
        if (!hasSelectedEquipments) {
          toast.error(<>{t('errors.no_selected_equipments')}</>);
          return false;
        }

        const newContextData: OrderStepperContextData = {
          ...contextData,
          equipments: [],
        };

        if (selectedEquipments.length > 0) newContextData.equipments?.push(...selectedEquipments);

        setContextData(newContextData);

        return true;
      },
    });
  }, [contextData, hasSelectedEquipments, setFooterProps, selectedEquipments, setContextData, t]);

  return (
    <Step size="lg">
      <Form form={form}>
        <Flex direction="column" gap={9}>
          <Text size="lg" weight="medium">
            {t('steppers.orders.select_equipments')}
          </Text>
          <Tabs>
            <Tab title={t('tabs.devices')}>
              <Flex gap={5} wrap="wrap">
                {catalog.filter(isDevice).map(device => (
                  <EquipmentCard
                    key={device.id}
                    equipment={device}
                    fromCatalog
                    quantitySelectProps={{
                      name: `selection.${device.id}`,
                      control,
                      max: 10,
                      min: 0,
                    }}
                    heightAuto
                  />
                ))}
              </Flex>
            </Tab>
            <Tab title={t('tabs.accessories')}>
              <Flex gap={5} wrap="wrap">
                {catalog.filter(isAccessory).map(accessory => (
                  <EquipmentCard
                    key={accessory.id}
                    equipment={accessory}
                    fromCatalog
                    quantitySelectProps={{
                      name: `selection.${accessory.id}`,
                      control,
                      max: 10,
                      min: 0,
                    }}
                    heightAuto
                  />
                ))}
              </Flex>
            </Tab>
          </Tabs>
        </Flex>
      </Form>
    </Step>
  );
};

export default ChooseEquipmentsStep;
