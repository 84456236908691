import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useBoolean } from 'react-use';
import LogoFleetIcon from '~/components/icons/logoFleet';
import LogoTypoIcon from '~/components/icons/logoTypo';
import Beta from '~/components/shared/Beta';
import Button from '~/components/shared/buttons/Button';
import CountrySelector from '~/components/shared/forms/CountrySelector';
import Bloc from '~/components/shared/shaping/Bloc';
import Filler from '~/components/shared/shaping/Filler';
import Flex from '~/components/shared/shaping/Flex';
import {
  APPS_LINK,
  CATALOG_LINK,
  DASHBOARD_LINK,
  EMPLOYEES_LINK,
  EQUIPMENTS_LINK,
  ORDERS_LINK,
  PROFILES_LINK,
  PROFILE_DETAILS_LINK,
  SETTINGS_LINK,
  SHIPMENTS_LINK,
} from '~/config/routes';
import { HasMDM } from '~/features/mdm';
import { HasProvisioningPlan } from '~/features/mdm/HasProvisioningPlan';
import BlockingCatalogModal from '~/features/modals/BlockingCatalogModal';
import { HrisProvider } from '~/graphql/schema';
import { useResolver } from '~/hooks/queries/useResolver';
import { PrimoAdminContext } from '~/providers/primo/PrimoAdminProvider';
import { SteppersContext } from '~/providers/steppers/AdminStepperProvider/index';
import { Nav, NavItem } from '../_parts';

export const NavPanel: FunctionComponent = () => {
  const { logout, isCatalogSetup, company, billingProfiles } = useContext(PrimoAdminContext);
  const { openOrderStepper, openOnboardingStepper } = useContext(SteppersContext);

  const [showBlockingCatalogModal, toggleShowBlockingCatalogModal] = useBoolean(false);
  const { t } = useTranslation();
  const settingsHrisBadgeCount = useMemo(() => (company?.hrisProvider === HrisProvider.Merge ? 1 : 0), [company]);
  const settingsBillingProfilesBadgeCount = useMemo(
    () => billingProfiles.filter(profile => !profile.checkoutCompleted).length,
    [billingProfiles],
  );
  const { executeResolver: getCompanyProfiles, result: companyProfiles } = useResolver(
    'useGetCompanyProfilesLazyQuery',
  );

  const handleOpenOrder = useCallback(() => {
    if (!isCatalogSetup) {
      toggleShowBlockingCatalogModal();
      return;
    }
    openOrderStepper({ employeeId: null });
  }, [isCatalogSetup, openOrderStepper, toggleShowBlockingCatalogModal]);

  const handleOpenOnboarding = useCallback(() => {
    if (!isCatalogSetup) {
      toggleShowBlockingCatalogModal();
      return;
    }
    openOnboardingStepper({ employeeId: null });
  }, [isCatalogSetup, openOnboardingStepper, toggleShowBlockingCatalogModal]);

  useEffect(() => {
    if (company?.id) {
      getCompanyProfiles({ companyId: company.id });
    }
  }, [company, getCompanyProfiles]);

  return (
    <Bloc className="notranslate" style={{ minWidth: 240, maxWidth: 240, height: '100vh' }} backgroundColor="crow">
      <Flex className="pri-p-6 pri-p-t-3" direction="column" fullHeight fullWidth>
        <Link to={DASHBOARD_LINK()}>
          <Flex direction="row" fullWidth>
            <LogoFleetIcon width={32} />
            <LogoTypoIcon width={90} className="pri-ml-1" />
          </Flex>
        </Link>
        <Flex direction="column" fullHeight>
          <Nav className="pri-px-4">
            <Flex className="pri-py-9" direction="column" gap={6}>
              <NavItem label={t('menus.dashboard')} to={DASHBOARD_LINK()} />
              <NavItem label={t('menus.employees')} to={EMPLOYEES_LINK()} />
              <NavItem label={t('menus.equipments')} to={EQUIPMENTS_LINK()} />
              <HasProvisioningPlan>
                <NavItem label={t('menus.apps')} to={APPS_LINK()} />
              </HasProvisioningPlan>
              <NavItem label={t('menus.orders')} to={ORDERS_LINK()} />
              <Beta>
                <NavItem label={t('menus.shipments')} to={SHIPMENTS_LINK()} />
              </Beta>
              <NavItem label={t('menus.catalog')} to={CATALOG_LINK()} />
              <NavItem
                label={t('menus.settings')}
                to={SETTINGS_LINK()}
                badge={settingsHrisBadgeCount + settingsBillingProfilesBadgeCount}
              />
              <HasMDM>
                {/** TODO: SHOULD BE REMOVED WHEN PROFILES WILL CONTAIN MORE THAN MDM SETTINGS */}
                <Beta>
                  <NavItem
                    label={t('menus.profiles')}
                    to={
                      companyProfiles?.getCompanyProfiles?.total === 1
                        ? PROFILE_DETAILS_LINK(companyProfiles.getCompanyProfiles.list[0].id)
                        : PROFILES_LINK()
                    }
                  />
                </Beta>
              </HasMDM>
            </Flex>
            <Flex direction="column" gap={3}>
              <Button onClick={handleOpenOnboarding} size="md">
                {t('buttons.add_employee')}
              </Button>
              <Button variant="light-outline" onClick={handleOpenOrder} size="md">
                {t('buttons.order_device')}
              </Button>
            </Flex>
            <Filler />
          </Nav>
        </Flex>
        <Flex direction="column" align="start" fullWidth gap={3}>
          <CountrySelector small dark />
          <Button className="pri-px-3" variant="light-outline" size="md" onClick={() => logout()}>
            {t('buttons.logout')}
          </Button>
        </Flex>
      </Flex>
      {showBlockingCatalogModal && <BlockingCatalogModal onClose={toggleShowBlockingCatalogModal} />}
    </Bloc>
  );
};
