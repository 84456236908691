import zod from 'zod';
import React, { useCallback, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import type { TFunction } from 'i18next';

import Flex from '~/components/shared/shaping/Flex';
import { Form } from '~/components/shared/forms/Form';
import Input from '~/components/shared/forms/Input';
import Button from '~/components/shared/buttons/Button';

import { SSOLoginContext } from './context';

const emailFormSchema = (t: TFunction) =>
  zod.object({
    email: zod.string().min(1, t('forms.validations.required')).email(t('forms.validations.invalid_email')),
  });

type EmailFormFields = zod.infer<ReturnType<typeof emailFormSchema>>;

const EmailForm: FunctionComponent = () => {
  const { t } = useTranslation();
  const { email, requestData, getChannels, getChannelsLoading } = useContext(SSOLoginContext);
  const form = useForm<EmailFormFields>({
    defaultValues: { email },
    resolver: zodResolver(emailFormSchema(t)),
  });
  const currentEmail = form.watch('email');

  const onSubmit = useCallback(
    (data: EmailFormFields) => {
      if (requestData) {
        getChannels(data.email);
      }
    },
    [requestData, getChannels],
  );

  return (
    <Form className="pri-p-3" form={form} onSubmit={form.handleSubmit(onSubmit)}>
      <Input
        type="email"
        name="email"
        label={t('forms.labels.email')}
        register={form.register}
        errors={form.formState.errors}
        disabled={!requestData}
        required
      />
      <Flex className="pri-otp-form-login-button">
        <Button
          type="submit"
          size="xl"
          className="flex-1"
          loading={getChannelsLoading}
          disabled={getChannelsLoading || !requestData || !currentEmail}
          fullWidth
        >
          {t('buttons.continue')}
        </Button>
      </Flex>
    </Form>
  );
};
export default EmailForm;
