import React from 'react';

const LaptopIcon: SvgComponent = ({ className, width = 24 }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.625 14.003H3.375C3.07674 14.0026 2.79079 13.884 2.57988 13.6731C2.36898 13.4622 2.25034 13.1762 2.25 12.878V5.00293C2.2503 4.70466 2.36892 4.41868 2.57983 4.20776C2.79075 3.99685 3.07673 3.87823 3.375 3.87793H14.625C14.9233 3.87827 15.2093 3.99691 15.4202 4.20781C15.6311 4.41872 15.7497 4.70467 15.75 5.00293V12.878C15.7496 13.1762 15.631 13.4621 15.4201 13.673C15.2092 13.8839 14.9233 14.0026 14.625 14.003ZM3.375 5.00293V12.878H14.625V5.00293H3.375Z"
      fill="#1E1E1E"
    />
    <path d="M1.125 15.1279H16.875V16.2529H1.125V15.1279Z" fill="#1E1E1E" />
  </svg>
);

export default LaptopIcon;
