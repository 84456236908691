import cn from 'classnames';
import type { ReactElement } from 'react';
import React from 'react';
import type { ButtonProps } from '~/components/shared/buttons/Button';
import Button from '~/components/shared/buttons/Button';

import './index.scss';

export type IconButtonProps = ButtonProps & {
  children: ReactElement<SvgComponent>;
};

const IconButton: FunctionComponent<IconButtonProps> = ({ children, className, ...props }) => (
  <Button className={cn('pri-icon-button', className)} {...props}>
    {children}
  </Button>
);

export default IconButton;
