import * as zod from 'zod';
import type { TFunction } from 'i18next';

export const shippingAddressFormSchema = (t: TFunction) =>
  zod.object({
    name: zod.string().min(1, t('forms.validations.required')),
    contactName: zod.string().nullish(),
    street: zod.string().min(1, t('forms.validations.required')),
    complement: zod
      .string()
      .transform(complement => complement || null)
      .nullish(),
    postalCode: zod.string().min(1, t('forms.validations.required')),
    city: zod.string().min(1, t('forms.validations.required')),
    country: zod.string().min(1, t('forms.validations.required')),
    phone: zod.string().min(1, t('forms.validations.required')),
  });

export type ShippingAddressFormFields = zod.infer<ReturnType<typeof shippingAddressFormSchema>>;
