import { useCallback, useEffect, useMemo } from 'react';
import { ok, refuse } from '@primo/operation-result';
import type { GetEnrollmentLinksQuery } from '~/graphql/schema';
import { useGetEnrollmentLinksLazyQuery } from '~/graphql/schema';
import type { UseQueryOptions } from '~/hooks/queries/types';

type UseGetEnrollmentLinksVariables = {
  employeeId: string;
  companyId: string;
  inventory: boolean;
};

const useQueryGetEnrollmentLinks = (queryOptions: UseQueryOptions<UseGetEnrollmentLinksVariables> = {}) => {
  const serializeEnrollmentLinksData = useCallback((data: GetEnrollmentLinksQuery | undefined) => {
    if (data?.getEnrollmentLinks?.__typename === 'QueryGetEnrollmentLinksSuccess') {
      return ok(data.getEnrollmentLinks.data);
    }
    if (data?.getEnrollmentLinks?.__typename === 'QueryGetEnrollmentLinksError')
      return refuse(data.getEnrollmentLinks.reasons);
    return refuse(['Unknown error']);
  }, []);

  const [query, { data: enrollmentLinksData, loading: enrollmentLinksLoading, called: enrollmentLinksCalled }] =
    useGetEnrollmentLinksLazyQuery();

  const enrollmentLinksResult = useMemo(
    () => serializeEnrollmentLinksData(enrollmentLinksData),
    [serializeEnrollmentLinksData, enrollmentLinksData],
  );

  const getEnrollmentLinks = useCallback(
    async (variables: UseGetEnrollmentLinksVariables) => {
      const result = await query({ variables, fetchPolicy: 'no-cache' });
      return serializeEnrollmentLinksData(result.data);
    },
    [query, serializeEnrollmentLinksData],
  );

  useEffect(() => {
    if (queryOptions.runOnInit && !enrollmentLinksCalled) getEnrollmentLinks({ ...queryOptions });
  }, [getEnrollmentLinks, queryOptions, enrollmentLinksCalled]);

  return useMemo(
    () => ({
      getEnrollmentLinks,
      enrollmentLinksResult,
      enrollmentLinksLoading,
      enrollmentLinksCalled,
    }),
    [getEnrollmentLinks, enrollmentLinksResult, enrollmentLinksLoading, enrollmentLinksCalled],
  );
};

export default useQueryGetEnrollmentLinks;
