import cn from 'classnames';
import React, { useMemo } from 'react';

import StarFilledIcon from '~/components/icons/starFilled';
import StarEmptyIcon from '~/components/icons/starEmpty';
import { DeviceCondition } from '~/graphql/schema';

import './index.scss';

export type DeviceConditionStarsProps = {
  condition: DeviceCondition;
  children?: never;
  size?: 'sm' | 'm';
};

const DeviceConditionStars: FunctionComponent<DeviceConditionStarsProps> = ({ className, condition, size = 'm' }) => {
  const numberOfFullStars = useMemo(
    () =>
      condition
        ? [DeviceCondition.Broken, DeviceCondition.Fair, DeviceCondition.Good, DeviceCondition.Excellent].indexOf(
            condition,
          )
        : 0,
    [condition],
  );
  const numberOfEmptyStars = 3 - numberOfFullStars;
  const width = useMemo(() => {
    switch (size) {
      case 'sm':
        return 12;
      case 'm':
      default:
        return 16;
    }
  }, [size]);

  return (
    <span className={cn('pri-condition', className)}>
      {Array(numberOfFullStars)
        .fill(0)
        .map((_, i) => (
          <StarFilledIcon width={width} key={`filled_${1 + i}`} />
        ))}
      {Array(numberOfEmptyStars)
        .fill(0)
        .map((_, i) => (
          <StarEmptyIcon width={width} key={`empty_${1 + i}`} />
        ))}
    </span>
  );
};

export default DeviceConditionStars;
