import { zodResolver } from '@hookform/resolvers/zod';
import React, { useCallback, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import type { Connection } from '~/pages/Login/context';
import { LoginContext } from '~/pages/Login/context';
import { PrimoAdminContext } from '~/providers/primo/PrimoAdminProvider';
import type { SignInFormFields } from '~/validations/login';
import { signInSchema } from '~/validations/login';
import { LOGIN_REQUEST_RESET_PASSWORD_LINK } from '~/config/routes';
import Flex from '~/components/shared/shaping/Flex';
import { Form } from '~/components/shared/forms/Form';
import Input from '~/components/shared/forms/Input';
import { Text } from '~/components/shared/typography';
import Button from '~/components/shared/buttons/Button';
import Bloc from '~/components/shared/shaping/Bloc';

type PasswordLoginPartProps = {
  connection: Connection;
};

const errorsMapping: Record<string, string> = {
  'Wrong email or password.': 'errors.login.wrong_email_or_password',
};

const PasswordLoginPart: FunctionComponent<PasswordLoginPartProps> = ({ connection }) => {
  const { t } = useTranslation();
  const { organizationId, redirectUri } = useContext(LoginContext);
  const { webAuth, setConnectionError } = useContext(PrimoAdminContext);
  const form = useForm<SignInFormFields>({
    mode: 'onSubmit',
    resolver: zodResolver(signInSchema(t)),
  });
  const { formState, register, handleSubmit } = form;
  const { errors } = formState;

  const onSubmit = useCallback(
    (data: SignInFormFields) => {
      if (connection?.realm) {
        webAuth.login(
          {
            password: data.password,
            username: data.email,
            realm: connection.realm,
            redirectUri,
            organization: organizationId,
          },
          err => {
            if (err) {
              const defaultError = `errors.login.${err.code}`;
              setConnectionError(err.description ? (errorsMapping[err.description] ?? defaultError) : defaultError);
            }
          },
        );
      }
    },
    [webAuth, connection, organizationId, redirectUri, setConnectionError],
  );

  if (!connection?.realm) {
    return null;
  }

  return (
    <Form form={form} className="pri-mt-6" onSubmit={handleSubmit(onSubmit)}>
      <Flex direction="column" gap={4}>
        <Flex direction="column" gap={1}>
          <Input name="email" label={t('forms.labels.email')} register={register} errors={errors} required />
          <Bloc>
            <Input
              type="password"
              name="password"
              label={t('forms.labels.password')}
              register={register}
              errors={errors}
              required
            />
            <Link to={LOGIN_REQUEST_RESET_PASSWORD_LINK()}>
              <Text weight="light">{t('links.forgot_password')}</Text>
            </Link>
          </Bloc>
        </Flex>
        <Flex>
          <Button className="pri-flex-1" type="submit" size="xl" fullWidth>
            {t('buttons.sign_in_with_password')}
          </Button>
        </Flex>
      </Flex>
    </Form>
  );
};

export default PasswordLoginPart;
