import React from 'react';

const IdeaIcon: SvgComponent = ({ className, width = 24, color = '#1E1E1E' }) => (
  <svg className={className} width={width} height={width} viewBox="0 0 20 20" fill="none">
    <path
      d="M6.87511 15H13.1251V16.25H6.87511V15ZM8.12511 17.5H11.8751V18.75H8.12511V17.5ZM10.0001 1.25C8.3425 1.25 6.75279 1.90848 5.58069 3.08058C4.40859 4.25269 3.75011 5.8424 3.75011 7.5C3.70784 8.40834 3.88184 9.31373 4.25779 10.1417C4.63374 10.9696 5.2009 11.6965 5.91261 12.2625C6.53761 12.8438 6.87511 13.175 6.87511 13.75H8.12511C8.12511 12.6 7.43136 11.9562 6.75636 11.3375C6.1722 10.8902 5.70665 10.3064 5.40047 9.6374C5.09429 8.96838 4.95682 8.23448 5.00011 7.5C5.00011 6.17392 5.52689 4.90215 6.46457 3.96447C7.40225 3.02678 8.67402 2.5 10.0001 2.5C11.3262 2.5 12.598 3.02678 13.5356 3.96447C14.4733 4.90215 15.0001 6.17392 15.0001 7.5C15.0427 8.23502 14.9042 8.96927 14.597 9.63833C14.2897 10.3074 13.8229 10.8908 13.2376 11.3375C12.5689 11.9625 11.8751 12.5875 11.8751 13.75H13.1251C13.1251 13.175 13.4564 12.8438 14.0876 12.2563C14.7988 11.6912 15.3657 10.9654 15.7417 10.1386C16.1176 9.31169 16.2919 8.40737 16.2501 7.5C16.2501 6.67924 16.0884 5.86651 15.7744 5.10823C15.4603 4.34994 14.9999 3.66095 14.4195 3.08058C13.8392 2.50022 13.1502 2.03984 12.3919 1.72575C11.6336 1.41166 10.8209 1.25 10.0001 1.25Z"
      fill={color}
    />
  </svg>
);

export default IdeaIcon;
