import React, { createContext, useMemo } from 'react';
import Loader from '~/components/shared/Loader';
import type { Company, GetActiveCompaniesQuery } from '~/graphql/schema';
import { useGetActiveCompaniesQuery } from '~/graphql/schema';

type AdminNavSectionContextData = {
  companies: Pick<Company, 'id' | 'name'>[];
};

export const AdminNavSectionContext = createContext<AdminNavSectionContextData>({
  companies: [],
});

const getCompanies = (data: GetActiveCompaniesQuery | undefined) => {
  if (data?.getCompanies?.__typename === 'QueryGetCompaniesSuccess') {
    return { companies: data.getCompanies.data, error: null };
  }
  return { companies: [], error: null };
};

export const AdminNavSectionProvider: FunctionComponent = ({ children }) => {
  const { data, loading } = useGetActiveCompaniesQuery();
  const { companies } = useMemo(() => getCompanies(data), [data]);

  const value = useMemo(() => ({ companies, loading }), [companies, loading]);

  return (
    <AdminNavSectionContext.Provider value={value}>{loading ? <Loader /> : children}</AdminNavSectionContext.Provider>
  );
};
