import React from 'react';

const WarningIcon: SvgComponent = ({ className, width = 24, color = '#1E1E1E' }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.8"
      d="M16 23C15.7033 23 15.4133 23.088 15.1666 23.2528C14.92 23.4176 14.7277 23.6519 14.6142 23.926C14.5006 24.2001 14.4709 24.5017 14.5288 24.7926C14.5867 25.0836 14.7296 25.3509 14.9393 25.5607C15.1491 25.7704 15.4164 25.9133 15.7074 25.9712C15.9983 26.0291 16.2999 25.9993 16.574 25.8858C16.8481 25.7723 17.0824 25.58 17.2472 25.3334C17.412 25.0867 17.5 24.7967 17.5 24.5C17.5 24.1022 17.342 23.7206 17.0607 23.4393C16.7794 23.158 16.3978 23 16 23ZM15 12H17V21H15V12Z"
      fill={color}
    />
    <path
      opacity="0.8"
      d="M29 30H3C2.82819 30 2.65929 29.9558 2.50956 29.8715C2.35983 29.7872 2.23434 29.6658 2.14518 29.519C2.05603 29.3721 2.00621 29.2047 2.00054 29.033C1.99487 28.8613 2.03353 28.691 2.1128 28.5386L15.1128 3.53861C15.1972 3.37625 15.3246 3.24017 15.4811 3.14519C15.6375 3.05022 15.817 3 16 3C16.183 3 16.3625 3.05022 16.5189 3.14519C16.6754 3.24017 16.8028 3.37625 16.8872 3.53861L29.8872 28.5386C29.9665 28.691 30.0051 28.8613 29.9995 29.033C29.9938 29.2047 29.944 29.3721 29.8548 29.519C29.7657 29.6658 29.6402 29.7872 29.4904 29.8715C29.3407 29.9558 29.1718 30 29 30ZM4.6507 28H27.3493L27.3513 27.9967L16.002 6.17142H15.998L4.6487 27.9967L4.6507 28Z"
      fill={color}
    />
  </svg>
);

export default WarningIcon;
