import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import { CATALOG_LINK } from '~/config/routes';
import type { ModalProps } from '~/components/shared/Modal';
import Modal from '~/components/shared/Modal';
import { Text } from '~/components/shared/typography';

const BlockingCatalogModal = ({ onClose }: Pick<ModalProps, 'onClose'>) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Modal
      title={t('modals.titles.fill_catalog')}
      onClose={onClose}
      onConfirm={() => {
        onClose?.();
        navigate(CATALOG_LINK());
      }}
      footer={{ cancelButton: true, confirmButton: t('buttons.go_to_catalog') }}
    >
      <Text weight="light">{t('modals.texts.fill_catalog')}</Text>
    </Modal>
  );
};

export default BlockingCatalogModal;
