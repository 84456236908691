import React from 'react';

const CheckmarkIcon: SvgComponent = ({ className, width = 16, color = '#D9CCBF' }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.32943 13.25L3.91276 8.83331L2.99609 9.66664L7.41276 14.0833L8.32943 15L17.1628 6.16664L16.2461 5.24998L8.32943 13.25Z"
      fill={color}
    />
  </svg>
);

export default CheckmarkIcon;
