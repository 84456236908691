import React from 'react';

const StorageDeviceIcon: SvgComponent = ({ className, width = 24 }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 20H26V22H28V28H4V22H14V20H4C3.46975 20.0006 2.9614 20.2115 2.58646 20.5865C2.21152 20.9614 2.00061 21.4698 2 22V28C2.00061 28.5302 2.21152 29.0386 2.58646 29.4135C2.9614 29.7885 3.46975 29.9994 4 30H28C28.5302 29.9994 29.0386 29.7885 29.4135 29.4135C29.7885 29.0386 29.9994 28.5302 30 28V22C29.9994 21.4698 29.7885 20.9614 29.4135 20.5865C29.0386 20.2115 28.5302 20.0006 28 20Z"
      fill="black"
    />
    <path
      d="M7 26C7.55228 26 8 25.5523 8 25C8 24.4477 7.55228 24 7 24C6.44772 24 6 24.4477 6 25C6 25.5523 6.44772 26 7 26Z"
      fill="black"
    />
    <path
      d="M30 8H22V14H16V22H24V16H30V8ZM22 20H18V16H22V20ZM28 14H24V10H28V14ZM18 10H10V2H18V10ZM12 8H16V4H12V8Z"
      fill="black"
    />
  </svg>
);

export default StorageDeviceIcon;
