import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Card from '~/components/shared/Card';
import CardItem from '~/components/shared/CardItem';
import { LeftArrowIcon } from '~/components/icons';

import { OffboardingStepperContext } from '../context';
import { StepperContext } from '~/components/shared/Stepper';
import Button from '~/components/shared/buttons/Button';
import AppIcon from '~/components/icons/app';
import Flex from '~/components/shared/shaping/Flex';
import { RoundedImage } from '~/components/shared/images';
import { Text } from '~/components/shared/typography';

const AppsRecap: FunctionComponent<{ employeeId?: string | null }> = ({ employeeId }) => {
  const { t } = useTranslation();
  const {
    companyExternalIntegrations,
    contextData: { employee, apps },
  } = useContext(OffboardingStepperContext);
  const { currentStep, setCurrentStep } = useContext(StepperContext);

  const deltaDevicesStep = employee?.devices?.length ? 1 : 0;
  const appStep = (employeeId ? 1 : 2) + deltaDevicesStep;

  if (!apps?.length || currentStep <= appStep) {
    return null;
  }

  return (
    <Card
      title={t('steppers.offboarding.deprovisioning')}
      icon={<AppIcon />}
      action={
        <Button
          size="lg"
          variant="dark-outline"
          onClick={() => {
            setCurrentStep(appStep);
          }}
        >
          <LeftArrowIcon width={16} className="pri-mr-3" />
          {t('buttons.edit')}
        </Button>
      }
    >
      {companyExternalIntegrations
        .filter(integration => apps.some(appName => integration.externalIntegration?.name === appName))
        .map(integration => (
          <CardItem key={integration.id}>
            <Flex justify="start" align="center" gap={4}>
              {integration.externalIntegration?.logoUrl && (
                <RoundedImage size="sm" src={integration.externalIntegration.logoUrl} />
              )}
              <Text>{t(`enums.external_integrations.${integration.externalIntegration?.name.toLowerCase()}`)}</Text>
            </Flex>
          </CardItem>
        ))}
    </Card>
  );
};

export default AppsRecap;
