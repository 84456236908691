import type { TFunction } from 'i18next';
import moment from 'moment';
import * as zod from 'zod';

export const schema = (hasProcedureDate: boolean, t: TFunction) =>
  zod.object({
    phone: zod.string().optional().nullable(),
    personalEmail: zod.string().email(t('forms.validations.invalid_email')).optional().or(zod.literal('')),
    scheduling: hasProcedureDate
      ? zod.enum(['NOW', 'SCHEDULED'], { errorMap: () => ({ message: t('forms.validations.invalid_scheduling') }) })
      : zod.any().optional(),
    procedureDate: hasProcedureDate
      ? zod
          .string()
          .min(1, t('forms.validations.required'))
          .refine(
            procedureDateToRefine => (procedureDateToRefine ? moment(procedureDateToRefine).isValid() : true),
            t('forms.validations.invalid_date_format'),
          )
      : zod.any().optional(),
    procedureTime: hasProcedureDate
      ? zod.string().regex(/^\d{2}:\d{2}$/, t('forms.validations.invalid_time'))
      : zod.any().optional(),
  });

export type Fields = zod.infer<ReturnType<typeof schema>>;
