import cn from 'classnames';
import React, { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import type { Control } from 'react-hook-form/dist/types';

import AddIcon from '~/components/icons/add';
import SubtractIcon from '~/components/icons/subtract';

import './index.scss';

type CommonQuantitySelectProps = {
  min?: number;
  max?: number;
};

type QuantitySelectPropsWithControl = CommonQuantitySelectProps & {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- We can't pass the type of the control through the component
  control: Control<any>;
  onChange?: (value: number) => void;
  quantity?: never;
};

type QuantitySelectPropsWithoutControl = CommonQuantitySelectProps & {
  name?: never;
  control?: never;
  onChange: (value: number) => void;
  quantity: number;
};

export type QuantitySelectProps = QuantitySelectPropsWithControl | QuantitySelectPropsWithoutControl;

const QuantitySelect: FunctionComponent<QuantitySelectProps> = ({
  name,
  className,
  onChange,
  min,
  max,
  control,
  quantity,
}) => {
  const renderComponent = useCallback(
    (onValueChange: QuantitySelectProps['onChange'], value: number) => (
      <div className={cn('pri-quantity-select', className)}>
        <button
          disabled={Boolean(typeof min !== 'undefined' && (value || 0) <= min)}
          type="button"
          onClick={() => {
            const newValue = (value || 0) - 1;
            onValueChange?.(newValue);
          }}
          aria-label="Less"
        >
          <SubtractIcon width={8} />
        </button>
        <div className="quantity-value">{value || 0}</div>
        <button
          disabled={Boolean(max && value >= max)}
          type="button"
          onClick={() => {
            const newValue = (value || 0) + 1;
            onValueChange?.(newValue);
          }}
          aria-label="More"
        >
          <AddIcon width={8} />
        </button>
      </div>
    ),
    [className, max, min],
  );

  return control ? (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange: internalOnChange, value } }) =>
        renderComponent(newValue => {
          internalOnChange(newValue);
          onChange?.(newValue);
        }, value)
      }
    />
  ) : (
    renderComponent(onChange, quantity || 0)
  );
};

export default QuantitySelect;
