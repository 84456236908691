import React, { useMemo, useRef } from 'react';
import cn from 'classnames';
import { useLocalStorage } from 'react-use';
import type { ReactElement, ReactNode } from 'react';

import Flex from '~/components/shared/shaping/Flex';
import Bloc from '~/components/shared/shaping/Bloc';
import { ChevronBottomIcon } from '~/components/icons';
import { Title } from '~/components/shared/typography';
import { useScrollHeight } from '~/hooks/useScrollHeight';
import type { ButtonProps } from '~/components/shared/buttons/Button';

import './style.scss';

export * from './title';

export type CardProps = {
  title?: ReactNode;
  icon?: ReactElement<SvgComponentProps>;
  border?: true;
  children: ReactChildren;
  actionPosition?: 'start' | 'center';
  action?: ReactElement<ButtonProps>;
} & (
  | { id: string; collapsable: true; defaultCollapse?: boolean }
  | { id?: string; collapsable?: never; defaultCollapse?: never }
);

const Card: FunctionComponent<CardProps> = ({
  className,
  children,
  id,
  title,
  border = false,
  icon = null,
  actionPosition = 'center',
  action = null,
  collapsable = false,
  defaultCollapse = false,
}) => {
  const [collapsed, setCollapse] = useLocalStorage(`collapsable-card-${id}`, defaultCollapse);
  const collapsableRef = useRef<HTMLDivElement>(null);
  const scrollHeight = useScrollHeight(collapsableRef);

  const style = useMemo(() => {
    if (!scrollHeight) {
      return undefined;
    }
    return !collapsed ? { maxHeight: scrollHeight } : { maxHeight: 0 };
  }, [collapsed, scrollHeight]);

  return (
    <Bloc
      rounded
      backgroundColor="white"
      className={cn('pri-card', className, { '--has-action': action, '--has-border': border })}
      id={id}
    >
      {title && (
        <Flex
          className="pri-card-header pri-py-3 pri-px-4"
          justify="between"
          align="center"
          gap={4}
          onClick={collapsable ? () => setCollapse(!collapsed) : undefined}
        >
          {typeof title === 'string' ? (
            <Flex justify="start" align="center" gap={2}>
              {icon}
              <Title level={3}>{title}</Title>
            </Flex>
          ) : (
            title
          )}
          {collapsable && (
            <ChevronBottomIcon className={cn('pri-card-chevron', { '--collapsed': collapsable && collapsed })} />
          )}
        </Flex>
      )}
      <div
        ref={collapsableRef}
        className={cn('pri-card-collapse', { '--collapsed': collapsable && collapsed })}
        style={collapsable ? style : undefined}
      >
        <Flex direction="column" className={cn('pri-card-body pri-px-4 pri-pt-4', { 'pri-pb-4': action })}>
          {children}
        </Flex>
        <Flex justify={actionPosition} align="center" className="pri-card-footer pri-px-4 pri-pb-4">
          {action}
        </Flex>
      </div>
    </Bloc>
  );
};

export default Card;
