import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import moment from 'moment';
import type { ButtonProps } from '~/components/shared/buttons/Button';
import Button from '~/components/shared/buttons/Button';
import Bloc from '~/components/shared/shaping/Bloc';

import './index.scss';

type ThrottledButtonProps = { timer: number; timerStartedAt?: Date | undefined; text: string; onFinish?: () => void };

const ThrottledButton: FunctionComponent<ButtonProps & ThrottledButtonProps> = ({
  timer,
  onFinish,
  text,
  className,
  disabled,
  timerStartedAt,
  ...props
}) => {
  const [seconds, setSeconds] = useState(timer);
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    let initialSeconds = timer;

    if (timerStartedAt) {
      const now = moment().utc();
      const elapsedTime = moment(now).diff(moment(timerStartedAt), 'seconds');
      initialSeconds = Math.max(timer - elapsedTime, 0);
    }

    setSeconds(initialSeconds);
    setIsRunning(true);

    const interval = setInterval(() => {
      setSeconds(prevSeconds => {
        if (prevSeconds <= 1) {
          clearInterval(interval);
          setIsRunning(false);
          // Ensure the onFinish callback is called after state updates are complete
          setTimeout(() => {
            if (onFinish) {
              onFinish();
            }
          }, 0);
          return 0;
        }
        return prevSeconds - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [timer, onFinish, timerStartedAt]);

  const progress = Math.round(((timer - seconds) / timer) * 100);

  return (
    <Button
      {...props}
      disabled={disabled || isRunning}
      className={cn('pri-throttled-button', className, isRunning ? 'pri-throttled-button-running' : '')}
    >
      {isRunning ? `${text} (${progress}%)` : text}
      {isRunning && (
        <Bloc className="timer-container">
          <Bloc className="timer-progress" style={{ width: `${progress}%` }} />
        </Bloc>
      )}
    </Button>
  );
};

export default ThrottledButton;
