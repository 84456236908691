import type { PopoverAlignment } from '@carbon/react';
import { Button as CarbonButton } from '@carbon/react';
import cn from 'classnames';
import React from 'react';

import './index.scss';
import Loader from '~/components/shared/Loader';
import Tooltip from '~/components/shared/Tooltip';

export type ButtonProps = {
  fullWidth?: true;
  disabled?: boolean;
  disabledTooltip?: string;
  tooltipAlign?: PopoverAlignment;
  variant?:
    | 'primary'
    | 'danger'
    | 'danger-outline'
    | 'dark-outline'
    | 'light-outline'
    | 'dark-no-outline'
    | 'link'
    | 'no-style';
  size?: 'xs' | 'md' | 'lg' | 'xl';
  onClick?: () => void;
  type?: 'submit' | 'reset' | 'button';
  noWrap?: boolean;
  loading?: boolean;
  form?: string;
};

const Button: FunctionComponent<ButtonProps> = ({
  className,
  children,
  disabled,
  disabledTooltip,
  onClick,
  loading,
  variant = 'primary',
  size = 'md',
  type = 'button',
  noWrap,
  fullWidth,
  tooltipAlign,
  form,
}) => (
  <Tooltip enabled={Boolean(disabled && disabledTooltip)} label={disabledTooltip} align={tooltipAlign}>
    <CarbonButton
      className={cn('pri-button', `pri-variant-${variant}`, `pri-size-${size}`, className, {
        disabled: disabled || loading,
        '--full-width': fullWidth,
        'pri-no-wrap': noWrap,
      })}
      disabled={disabled}
      onClick={onClick}
      type={type}
      form={form}
    >
      {loading ? <Loader spacing="sm" /> : children}
    </CarbonButton>
  </Tooltip>
);

export default Button;
