import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { APP_VERSION, DATADOG_APP_ID, DATADOG_CLIENT_TOKEN, ENV, DATADOG_TRACING_ORIGIN } from './config/env';

/**
 * We only want to run this in staging / prod environment
 */
if (DATADOG_APP_ID && DATADOG_CLIENT_TOKEN && DATADOG_TRACING_ORIGIN) {
  const service = `${ENV}-cockpit-ui`;

  datadogLogs.init({
    clientToken: DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.eu',
    service,
    version: APP_VERSION,
    forwardErrorsToLogs: true,
    env: ENV,
    sessionSampleRate: 100,
  });

  if (ENV === 'production') {
    datadogRum.init({
      applicationId: DATADOG_APP_ID,
      clientToken: DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.eu',
      service,
      env: ENV,
      version: APP_VERSION,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      allowedTracingUrls: [DATADOG_TRACING_ORIGIN],
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });

    datadogRum.startSessionReplayRecording();
  }
}
