import React from 'react';

const WebcamIcon: SvgComponent = ({ className, width = 24 }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M26,1H6A5,5,0,0,0,1,6V18a5,5,0,0,0,5,5h7v2H6.83a2,2,0,0,0-2,2v2a2,2,0,0,0,2,2H25a2,2,0,0,0,2-2V27a2,2,0,0,0-2-2H19V23h7a5,5,0,0,0,5-5V6A5,5,0,0,0,26,1ZM25,29H6.83V27H25Zm-8-4H15V23h2Zm12-7a3,3,0,0,1-3,3H6a3,3,0,0,1-3-3V6A3,3,0,0,1,6,3H26a3,3,0,0,1,3,3Z" />
    <path d="M16,5a7,7,0,1,0,7,7A7,7,0,0,0,16,5Zm0,12a5,5,0,1,1,5-5A5,5,0,0,1,16,17Z" />
    <path d="M16,9a3,3,0,1,0,3,3A3,3,0,0,0,16,9Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,16,13Z" />
  </svg>
);

export default WebcamIcon;
