import React from 'react';
import { InitialSetupStepperProvider } from './context';
import InitialSetupStepperContent from './stepper';
import { Stepper } from '~/components/shared/Stepper';

type InitialSetupStepperProps = {
  onClose: () => void;
};

const InitialSetupStepper: FunctionComponent<InitialSetupStepperProps> = ({ onClose }) => (
  <Stepper onClose={onClose}>
    <InitialSetupStepperProvider>
      <InitialSetupStepperContent />
    </InitialSetupStepperProvider>
  </Stepper>
);

export default InitialSetupStepper;
