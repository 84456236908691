import React from 'react';

const SubtractIcon: SvgComponent = ({ className, width = 24 }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 8 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0.5H8V1.5H0V0.5Z" fill="black" />
  </svg>
);

export default SubtractIcon;
