import React from 'react';
import Flex from '~/components/shared/shaping/Flex';

import Pill from '~/components/shared/Pill';
import DeleteIcon from '~/components/icons/delete';
import Button from '~/components/shared/buttons/Button';
import type { Option, Value } from './types';

import './index.scss';

type SelectedOptionsProps<Val extends Value> = {
  selectedOptions?: Option<Val> | Option<Val>[];
  onChange: (selectedOptions: Val[]) => void;
  setCustomOptions: (o: Option<Val>[]) => void;
  customOptions: Option<Val>[];
};

const SelectedOptionsPill = <Val extends Value>({
  customOptions,
  setCustomOptions,
  selectedOptions,
  onChange,
}: SelectedOptionsProps<Val>) =>
  Array.isArray(selectedOptions) ? (
    <Flex direction="row" gap={3} wrap="wrap" className="pri-select__multi-selected-options">
      {selectedOptions.map(option => (
        <Pill key={option.label} color="grey">
          <Flex align="center" gap={2}>
            {option.label}
            <Button
              variant="no-style"
              onClick={() => {
                onChange(selectedOptions.filter(opt => opt.value !== option.value).map(opt => opt.value));
                setCustomOptions(customOptions.filter(opt => opt.value !== option.value));
              }}
            >
              <DeleteIcon width={16} />
            </Button>
          </Flex>
        </Pill>
      ))}
    </Flex>
  ) : null;

export default SelectedOptionsPill;
