import { useContext, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { Truthy } from '~/utils/truthy';
import { OrderStepperContext } from '~/features/steppers/OrderStepper/context';
import type { OrderStepperContextData } from '~/features/steppers/OrderStepper/types';

export default (selection: Record<string, number>) => {
  const { catalog } = useContext(OrderStepperContext);
  const [result, setResult] = useState<NonNullable<OrderStepperContextData['equipments']>>([]);

  useDeepCompareEffect(() => {
    const computedResult = Object.entries(selection)
      .filter(([, v]) => !!v)
      .map(([k, v]) => {
        const item = catalog.find(product => product.id === k);
        if (item) return { item, quantity: v as number };
        return null;
      })
      .filter(Truthy);
    setResult(computedResult);
  }, [catalog, selection]);

  return result;
};
