import { WebAuth } from 'auth0-js';
import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocalStorage } from 'react-use';

import { isOk } from '@primo/operation-result';
import { AUTH0_AUDIENCE_API, AUTH0_CLIENT_ID, AUTH0_DOMAIN_NAME, AUTH0_SIGNUP_CLIENT_ID } from '~/config/env';
import type {
  Company,
  CompanyBillingProfile,
  CompanySetup,
  Employee,
  GetCompanyInitialSetupQuery,
  GetContextCompanyCatalogQuery,
  GetEmployeeCompanyQuery,
} from '~/graphql/schema';
import {
  useGetCompanyInitialSetupLazyQuery,
  useGetContextCompanyCatalogLazyQuery,
  useGetEmployeeCompanyLazyQuery,
  useGetPrimoContextMutation,
} from '~/graphql/schema';
import useFullLoadedOnce from '~/hooks/useFullLoadedOnce';

import usePrimoAccessToken from '~/hooks/PrimoContexts/useAccessToken';
import useDatadogRefreshUser from '~/hooks/PrimoContexts/useDatadogRefreshUser';
import useLogout from '~/hooks/PrimoContexts/useLogout';
import returnToURL from '~/hooks/PrimoContexts/utils/returnToURL';
import useQueryGetCompanyBillingProfiles from '~/hooks/queries/companyBillingProfiles/useQueryGetCompanyBillingProfiles';
import useQueryGetCompanySetup from '~/hooks/queries/companySetup/useQueryGetCompanySetup';

const webAuth = new WebAuth({
  domain: AUTH0_DOMAIN_NAME,
  clientID: AUTH0_CLIENT_ID,
  audience: AUTH0_AUDIENCE_API,
  scope: 'offline_access read:current_user',
  responseType: 'token',
});

const webAuthForSignup = new WebAuth({
  domain: AUTH0_DOMAIN_NAME,
  clientID: AUTH0_SIGNUP_CLIENT_ID,
  audience: AUTH0_AUDIENCE_API,
  scope: 'offline_access read:current_user',
  responseType: 'token',
});

export type PartialCompanyBillingProfile = Pick<
  CompanyBillingProfile,
  'name' | 'billingCenterUrl' | 'checkoutUrl' | 'checkoutCompleted' | 'id'
>;

export type PrimoAdminContextProps = {
  isAuthenticated: boolean;
  isLoading: boolean;
  isBetaActivated: boolean;
  isCatalogSetup: boolean;
  connectionError: string | undefined;
  employee: Pick<
    Employee,
    | 'id'
    | 'fullName'
    | 'companyId'
    | 'email'
    | 'picture'
    | 'initials'
    | 'isInternalEmployee'
    | 'firstName'
    | 'lastName'
    | 'phone'
    | 'isAdmin'
    | 'isOnlyEmployee'
  > | null;
  company: Pick<
    Company,
    | 'id'
    | 'name'
    | 'inlineAddress'
    | 'isConnectedToHris'
    | 'isSyncedWithHris'
    | 'isHrisSyncEnabled'
    | 'hasMDM'
    | 'hasProvisioning'
    | 'hrisProvider'
    | 'hrisLastSyncAt'
    | 'hrisName'
    | 'hrisSyncStatus'
    | 'canonicalName'
    | 'billingCenterUrl'
    | 'subscriptionCheckoutUrl'
    | 'mdmActions'
    | 'mdmPlatforms'
    | 'mdmLegacy'
    | 'mdmProvider'
    | 'hasNonAdminEmployee'
    | 'mdmSettings'
    | 'mdmInstalledApplications'
    | 'malwarebytesEnabled'
    | 'drataEnabled'
    | 'addresses'
    | 'hasProvisioningPlan'
    | 'hasProvisioning'
    | 'encryptionEnabled'
    | 'domains'
  > | null;
  companySetup: Pick<
    CompanySetup,
    'id' | 'notificationEmail' | 'companyId' | 'hasMdm' | 'appleBMAccess' | 'appleOrgId' | 'drataIntegration'
  > | null;
  webAuth: WebAuth;
  webAuthForSignup: WebAuth;
  toggleBeta: () => void;
  setImpersonateCompanyId: (companyId: string) => void;
  setAccessToken: (token: string) => void;
  setConnectionError: (error: string) => void;
  removeConnectionError: () => void;
  logout: (to?: string) => void;
  token?: string;
  companyInitialSetup?: {
    billingCheckoutCompleted: boolean;
    eulaAccepted: boolean;
    billingCheckoutUrl?: string | null;
  };
  billingProfiles: PartialCompanyBillingProfile[];
};

export const PrimoAdminContext = createContext<PrimoAdminContextProps>({
  isAuthenticated: false,
  isLoading: true,
  isBetaActivated: false,
  isCatalogSetup: false,
  connectionError: undefined,
  employee: null,
  company: null,
  companySetup: null,
  webAuth,
  webAuthForSignup,
  toggleBeta: () => undefined,
  setImpersonateCompanyId: () => undefined,
  setAccessToken: () => undefined,
  setConnectionError: () => undefined,
  removeConnectionError: () => undefined,
  logout: () => undefined,
  companyInitialSetup: undefined,
  billingProfiles: [],
});

const getCompany = (data: GetEmployeeCompanyQuery | undefined) => {
  if (data?.getCompany?.__typename === 'QueryGetCompanySuccess') {
    return { company: data.getCompany.data, error: null };
  }
  if (data?.getCompany?.__typename === 'QueryGetCompanyError') {
    return { company: null, error: data.getCompany.reasons.join('; ') };
  }
  return { company: null, error: null };
};

const getCatalog = (data: GetContextCompanyCatalogQuery | undefined) => {
  if (data?.getCompanyCatalog?.__typename === 'QueryGetCompanyCatalogSuccess') {
    return { catalog: data.getCompanyCatalog.data, error: null };
  }
  if (data?.getCompanyCatalog?.__typename === 'QueryGetCompanyCatalogError') {
    return { catalog: null, error: data.getCompanyCatalog.reasons.join('; ') };
  }
  return { catalog: null, error: null };
};

const getInitialSetup = (data: GetCompanyInitialSetupQuery | undefined) => {
  if (data?.getCompanyInitialSetup?.__typename === 'QueryGetCompanyInitialSetupSuccess') {
    return data.getCompanyInitialSetup.data;
  }
  return undefined;
};

const PrimoAdminProvider: FunctionComponent = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [isBetaActivated, setIsBetaActivated] = useLocalStorage('betaMode', false);
  const [impersonateCompanyId, setImpersonateCompanyId] = useState<string | undefined>(undefined);
  const [connectionError, setConnectionError, removeConnectionError] = useLocalStorage<string>('connectionError');

  const logoutCallBack = useCallback((returnTo?: string) => {
    webAuth.logout({ returnTo: returnToURL(returnTo) });
  }, []);

  const { logout } = useLogout(logoutCallBack);

  const { accessToken, hasAuthenticatedClient, setAccessToken } = usePrimoAccessToken(
    logout,
    setLoading,
    setConnectionError,
    removeConnectionError,
  );

  /** Primo context is use to sync and fetch the current employee from the access token */
  const [getPrimoAdminContext, { data: getPrimoAdminContextData, loading: getPrimoAdminContextLoading }] =
    useGetPrimoContextMutation({
      onError: () => setLoading(false),
      onCompleted: () => setLoading(false),
    });

  const [getEmployeeCompany, { data: employeeCompanyData, loading: getEmployeeCompanyLoading }] =
    useGetEmployeeCompanyLazyQuery();
  const [getCompanyCatalog, { data: companyCatalogData, loading: getCompanyCatalogLoading }] =
    useGetContextCompanyCatalogLazyQuery();
  const [getCompanyInitialSetup, { data: companyInitialSetupData, loading: getCompanyInitialSetupLoading }] =
    useGetCompanyInitialSetupLazyQuery();
  const { getCompanySetup, companySetupResult, companySetupLoading } = useQueryGetCompanySetup();
  const { getCompanyBillingProfiles, companyBillingProfilesResult } = useQueryGetCompanyBillingProfiles();

  /** Get employee from the result of the graphql query */
  const employee = useMemo(() => {
    if (getPrimoAdminContextData?.syncCurrentEmployee?.__typename === 'MutationSyncCurrentEmployeeSuccess') {
      return getPrimoAdminContextData.syncCurrentEmployee.data;
    }
    return null;
  }, [getPrimoAdminContextData]);

  /** Get employee from the result of the graphql query */
  const company = useMemo(() => getCompany(employeeCompanyData).company, [employeeCompanyData]);
  const companyInitialSetup = useMemo(() => getInitialSetup(companyInitialSetupData), [companyInitialSetupData]);
  const companySetup = useMemo(() => {
    if (isOk(companySetupResult)) return companySetupResult.data;
    return null;
  }, [companySetupResult]);
  const billingProfiles = useMemo(
    () => (isOk(companyBillingProfilesResult) ? companyBillingProfilesResult.data : []),
    [companyBillingProfilesResult],
  );

  /** Get the company catalog from the result of the graphql query */
  const catalog = useMemo(() => getCatalog(companyCatalogData).catalog, [companyCatalogData]);

  const toggleBeta = useCallback(() => {
    if (employee && employee.isInternalEmployee) setIsBetaActivated(!isBetaActivated);
  }, [employee, setIsBetaActivated, isBetaActivated]);

  /** Set datadog user when employee changes */
  useDatadogRefreshUser(employee);

  useEffect(() => {
    if (company) {
      getCompanyInitialSetup({
        variables: {
          companyId: company.id,
        },
      });
      getCompanySetup({ companyId: company.id });
      getCompanyBillingProfiles({ companyId: company.id });
    }
  }, [company, getCompanyInitialSetup, getCompanySetup, getCompanyBillingProfiles]);

  const fullLoadedOnce = useFullLoadedOnce([
    loading,
    getPrimoAdminContextLoading,
    getEmployeeCompanyLoading,
    getCompanyCatalogLoading,
    getCompanyInitialSetupLoading,
    companySetupLoading,
  ]);

  const value = useMemo(
    () => ({
      connectionError,
      isAuthenticated: !!employee,
      isLoading: !fullLoadedOnce,
      isBetaActivated: isBetaActivated || false,
      isCatalogSetup: (catalog || []).length > 0,
      employee,
      company,
      companySetup,
      webAuth,
      webAuthForSignup,
      token: accessToken,
      setImpersonateCompanyId,
      setAccessToken,
      setConnectionError,
      removeConnectionError,
      logout,
      toggleBeta,
      companyInitialSetup,
      billingProfiles,
    }),
    [
      connectionError,
      fullLoadedOnce,
      employee,
      isBetaActivated,
      company,
      companySetup,
      accessToken,
      setImpersonateCompanyId,
      setAccessToken,
      setConnectionError,
      removeConnectionError,
      logout,
      toggleBeta,
      catalog,
      companyInitialSetup,
      billingProfiles,
    ],
  );

  /** Check if user is correctly logged in when authenticated client is set */
  useEffect(() => {
    if (hasAuthenticatedClient) {
      getPrimoAdminContext();
    }
  }, [hasAuthenticatedClient, getPrimoAdminContext]);

  /** Fetch employee's company when employee is defined and got a company id */
  useEffect(() => {
    if (employee?.companyId) {
      getEmployeeCompany({
        variables: {
          companyId: impersonateCompanyId && employee.isInternalEmployee ? impersonateCompanyId : employee.companyId,
        },
      });
      getCompanyCatalog({
        variables: {
          companyId: impersonateCompanyId && employee.isInternalEmployee ? impersonateCompanyId : employee.companyId,
        },
      });
    }
  }, [employee, impersonateCompanyId, getEmployeeCompany, getCompanyCatalog]);

  return <PrimoAdminContext.Provider value={value}>{children}</PrimoAdminContext.Provider>;
};

export default PrimoAdminProvider;
