import { DatePicker as CarbonDatePicker, DatePickerInput } from '@carbon/react';
import cn from 'classnames';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import type { FieldValues, Path, Control } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import type { FieldErrors } from 'react-hook-form/dist/types/errors';
import { getI18n, useTranslation } from 'react-i18next';

import './index.scss';

export type DatePickerProps<FV extends FieldValues> = {
  label?: string;
  helperText?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  defaultValue?: string;
  dateFormat?: string;
  className?: string;
  minDate?: string | number;

  name: Path<FV>;
  control: Control<FV>;
  errors?: FieldErrors;
};

const DatePicker = <FV extends FieldValues>({
  className,
  dateFormat,
  label = '',
  errors,
  required,
  control,
  name,
  disabled,
  placeholder,
  ...props
}: DatePickerProps<FV>) => {
  const { t } = useTranslation();
  const id = useMemo(() => `date-picket-${Math.random().toFixed(10).slice(2, 10)}`, []);
  const error = errors?.[name];
  const message = error?.message?.toString() || '';
  const i18n = useMemo(getI18n, []);

  const sanitizeHour = useCallback(
    (input?: string | Date) => {
      if (!input) return undefined;
      const isEn = i18n.language === 'en';
      const parsedDate = typeof input === 'string' ? moment(input, isEn ? 'YYYY/MM/DD' : 'DD/MM/YYYY') : moment(input);
      return parsedDate.utc().add(2, 'hours').startOf('day');
    },
    [i18n.language],
  );

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, ref, onBlur, ...rest } }) => (
        <CarbonDatePicker
          allowInput
          className={cn('pri-date-picker', { 'pri-date-picker-required': required }, className)}
          datePickerType="single"
          dateFormat={(dateFormat ?? i18n.language === 'fr') ? 'd/m/Y' : 'Y/m/d'}
          onChange={val => {
            if (!val[0]) {
              return;
            }
            onChange(sanitizeHour(val[0])?.toISOString());
          }}
          locale={i18n.language}
          {...props}
          {...rest}
        >
          <DatePickerInput
            onBlur={e => {
              if (!e.target.value) {
                onChange(undefined);
              }
              onBlur();
            }}
            id={`input-${id}`}
            labelText={label}
            invalid={!!error}
            disabled={disabled}
            placeholder={placeholder}
            invalidText={message ? t(message, '') || message : ''}
            className={cn({ 'pri-date-picker-input-required': required })}
          />
        </CarbonDatePicker>
      )}
    />
  );
};

export default DatePicker;
