import { useMemo } from 'react';
import { GetEmployeePageDocument, OrderStatus, useCreateOnboardingMutation } from '~/graphql/schema';

const useCreateOnboarding = (employeeId?: string | null) => {
  const [createOnboarding, { loading: createOnboardingLoading }] = useCreateOnboardingMutation({
    fetchPolicy: 'no-cache',
    refetchQueries: [
      'GetViewEmployees', // to reload employees list in employees page using views
      'ListOnboardingsPage', // this is used to refetch current page if actions is called from the offboardings page
      'ListEmployeesPage', // this is used to refetch current page if actions is called from the employees page
      'GetPendingOnboardings', // to reload onboarding list in onboard modal
      'ListOrdersPage', // to reload orders list in orders page
      'GetCompanyBillingProfiles', // to reload billing profiles in company settings
      'ListOffboardingsPage',
      'GetEmployeePage',
      {
        query: GetEmployeePageDocument,
        variables: { employeeId, orderStatus: [OrderStatus.Placed, OrderStatus.Confirmed, OrderStatus.Shipped] },
      },
    ],
  });

  return useMemo(() => ({ createOnboarding, createOnboardingLoading }), [createOnboarding, createOnboardingLoading]);
};

export default useCreateOnboarding;
