import { useCallback, useContext } from 'react';
import type { OnboardingStepperContextData } from '../types';
import isDevice from '~/utils/products/isDevice';
import useAssignFromStock from './_queries/useAssignFromStock';
import { PrimoAdminContext } from '~/providers/primo/PrimoAdminProvider';

const useAssignEquipments = () => {
  const { company } = useContext(PrimoAdminContext);

  const { assignDevice, assignAccessory } = useAssignFromStock();

  const assignEquipments = useCallback(
    async (employeeId: string, contextData: OnboardingStepperContextData) => {
      const { equipments } = contextData;
      const { inventory: inventoryEquipments } = equipments || {};

      return Promise.all(
        (inventoryEquipments || []).map(({ item: assignedEquipment }) => {
          if (isDevice({ type: assignedEquipment.type || '' })) {
            return assignDevice({
              variables: {
                ownerId: employeeId,
                deviceId: assignedEquipment.id,
              },
            });
          }
          return assignAccessory({
            variables: {
              ownerId: employeeId,
              accessoryName: assignedEquipment.name || '',
              companyId: company?.id || '',
            },
          });
        }),
      );
    },
    [assignAccessory, assignDevice, company?.id],
  );

  return assignEquipments;
};

export default useAssignEquipments;
