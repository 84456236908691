import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { isRefusal } from '@primo/operation-result';
import { omit } from 'lodash';
import useGetPendingOnboardings from './_queries/useGetPendingOnboardings';
import useGetInventory from './_queries/useGetInventory';
import useGetCatalog from './_queries/useGetCatalog';
import type {
  CompanyAccessory,
  CompanyCatalog,
  CompanyDevice,
  CompanyPendingOnboarding,
  OnboardingStepperContextData,
} from '../types';
import useCloseOnboardingIfPossible from './_queries/useCloseOnboardingIfPossible';
import onboardEmployee from './onboardEmployee';
import useCreateOnboarding from './_queries/useCreateOnboarding';
import { PrimoAdminContext } from '~/providers/primo/PrimoAdminProvider';
import { StepperContext } from '~/components/shared/Stepper';
import useMutationCreateOrder from '~/hooks/queries/orders/useMutationCreateOrder';
import type { AddressItem, OrderShippingAddressChoice } from '~/graphql/schema';
import { OrderSource, OrderStatus } from '~/graphql/schema';
import useAssignEquipments from './useAssignEquipments';
import useQueryGetEmployee from '~/hooks/queries/employees/useQueryGetEmployee';
import useUpdateAddressIfNeeded from './useUpdateAddressIfNeeded';

export type OnboardingStepperContextProps = {
  loading: boolean;
  setupCalled: boolean;
  pendingOnboardings: CompanyPendingOnboarding[];
  inventory: {
    devices: CompanyDevice[];
    accessories: CompanyAccessory[];
  };
  catalog: CompanyCatalog[];
  contextData: OnboardingStepperContextData;
  setContextData: (contextData: OnboardingStepperContextData) => void;
  onFinish: (contextData?: OnboardingStepperContextData) => Promise<void>;
};

export const OnboardingStepperContext = createContext<OnboardingStepperContextProps>({
  loading: false,
  setupCalled: false,
  pendingOnboardings: [],
  inventory: {
    devices: [],
    accessories: [],
  },
  catalog: [],
  contextData: {},
  setContextData: () => {},
  onFinish: async () => {},
});

export const OnboardingStepperProvider: FunctionComponent<{
  employeeId?: string | null;
}> = ({ children, employeeId }) => {
  const { t } = useTranslation();
  const { company, employee: loggedUser } = useContext(PrimoAdminContext);
  const { toggleLoading, onClose } = useContext(StepperContext);
  const { pendingOnboardings, pendingOnboardingsLoading, pendingOnboardingsCalled } = useGetPendingOnboardings();
  const { inventory, inventoryLoading, inventoryCalled } = useGetInventory();
  const { catalog, catalogLoading, catalogCalled } = useGetCatalog();
  const [contextData, setContextData] = useState<OnboardingStepperContextData>({});
  const { createOrder } = useMutationCreateOrder();
  const { getEmployee, employeeCalled } = useQueryGetEmployee();
  const updateAddressIfNeeded = useUpdateAddressIfNeeded();

  const createOnboardingUse = useCreateOnboarding(employeeId);
  const assignEquipments = useAssignEquipments();
  const { closeOnboardingIfPossible } = useCloseOnboardingIfPossible();

  const onFinish = useCallback(
    async (finishContextData?: OnboardingStepperContextData) => {
      const context = finishContextData || contextData;
      const data = { ...context, employee: { ...context.employee, id: employeeId || undefined } };
      if (!company || !loggedUser || !data.employee) return;
      toggleLoading();
      const shippingAddressType = data.address?.choice.split('-')[0].toUpperCase() as OrderShippingAddressChoice;

      const onboardEmployeeResult = await onboardEmployee(company.id, data, createOnboardingUse);

      if (onboardEmployeeResult instanceof Error || !onboardEmployeeResult.employee?.id) {
        toggleLoading();
        toast.error(<>{t('errors.failed_to_onboard')}</>);
        return;
      }

      const {
        id: onboardingId,
        employee: { id: onboardedEmployeeId },
      } = onboardEmployeeResult;

      if (data.equipments && data.equipments.inventory.length > 0) {
        await assignEquipments(onboardedEmployeeId, data);
      }

      if (data.equipments && data.address && data.equipments.catalog.length > 0) {
        const createOrderResult = await createOrder({
          companyId: company.id,
          productsInOrder: data.equipments.catalog
            .filter(selectedProduct => selectedProduct.quantity > 0)
            .map(selectedProduct => ({
              productId: selectedProduct.item.id,
              quantity: selectedProduct.quantity,
            })),
          data: {
            shippingAddressType,
            initiatorId: loggedUser.id,
            companyId: company.id,
            employeeId: onboardedEmployeeId,
            status: OrderStatus.Placed,
            source: OrderSource.Cockpit,
            shippingAddress: omit(data.address.location, '__typename'),
            companyComment: data.companyComment || undefined,
            billingProfileId: data.billingProfileId,
          },
        });

        if (isRefusal(createOrderResult)) {
          toast.error(<>{t('errors.failed_to_create_order')}</>);
        }
      }

      await closeOnboardingIfPossible({ variables: { id: onboardingId } });

      if (data.address) {
        await updateAddressIfNeeded(shippingAddressType, data.address.location as AddressItem);
      }

      toggleLoading();
      onClose?.();
      toast.success(<>{t('steppers.onboarding.onboarding_success')}</>);
    },
    [
      assignEquipments,
      closeOnboardingIfPossible,
      employeeId,
      company,
      contextData,
      createOnboardingUse,
      createOrder,
      loggedUser,
      onClose,
      t,
      toggleLoading,
      updateAddressIfNeeded,
    ],
  );

  useEffect(() => {
    if (employeeId && !employeeCalled) {
      const asyncEffect = async () => {
        const employeeResult = await getEmployee({ employeeId });
        if (!employeeResult || isRefusal(employeeResult) || !employeeResult.data.employee) {
          toast.error(<>{t('errors.unknown_error')}</>);
          return;
        }
        setContextData({
          employee: {
            ...employeeResult.data.employee,
            personalEmail: employeeResult.data.employee.personalEmail || undefined,
          },
        });
      };
      asyncEffect();
    }
  }, [employeeCalled, employeeId, getEmployee, t]);

  const loading = pendingOnboardingsLoading || inventoryLoading || catalogLoading;
  const setupCalled = pendingOnboardingsCalled && inventoryCalled && catalogCalled && (!employeeId || employeeCalled);

  const value = useMemo(
    () => ({
      catalog,
      loading,
      setupCalled,
      inventory,
      pendingOnboardings,
      contextData,
      setContextData,
      onFinish,
    }),
    [catalog, loading, setupCalled, inventory, pendingOnboardings, contextData, setContextData, onFinish],
  );

  return <OnboardingStepperContext.Provider value={value}>{children}</OnboardingStepperContext.Provider>;
};
