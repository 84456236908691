import { useCallback, useEffect, useMemo } from 'react';
import { ok, refuse } from '@primo/operation-result';
import type { SendResetPasswordRequestMutation } from '~/graphql/schema';
import { useSendResetPasswordRequestMutation } from '~/graphql/schema';
import type { UseQueryOptions } from '~/hooks/queries/types';

type UseSendResetPasswordRequestVariables = {
  email: string;
};

const useMutationSendResetPasswordRequest = (
  queryOptions: UseQueryOptions<UseSendResetPasswordRequestVariables> = {},
) => {
  const serializeSendResetPasswordRequestData = useCallback(
    (data: SendResetPasswordRequestMutation | undefined | null) => {
      if (data?.sendResetPasswordRequest?.__typename === 'MutationSendResetPasswordRequestSuccess') {
        return ok(data.sendResetPasswordRequest.data);
      }
      if (data?.sendResetPasswordRequest?.__typename === 'MutationSendResetPasswordRequestError')
        return refuse(data.sendResetPasswordRequest.reasons);
      return refuse(['Unknown error']);
    },
    [],
  );

  const [
    mutation,
    {
      data: sendResetPasswordRequestData,
      loading: sendResetPasswordRequestLoading,
      called: sendResetPasswordRequestCalled,
    },
  ] = useSendResetPasswordRequestMutation();

  const sendResetPasswordRequestResult = useMemo(
    () => serializeSendResetPasswordRequestData(sendResetPasswordRequestData),
    [serializeSendResetPasswordRequestData, sendResetPasswordRequestData],
  );

  const sendResetPasswordRequest = useCallback(
    async (variables: UseSendResetPasswordRequestVariables) => {
      const result = await mutation({
        variables,
      });
      return serializeSendResetPasswordRequestData(result.data);
    },
    [mutation, serializeSendResetPasswordRequestData],
  );

  useEffect(() => {
    if (queryOptions.runOnInit && !sendResetPasswordRequestCalled) sendResetPasswordRequest({ ...queryOptions });
  }, [sendResetPasswordRequest, queryOptions, sendResetPasswordRequestCalled]);

  return useMemo(
    () => ({
      sendResetPasswordRequest,
      sendResetPasswordRequestResult,
      sendResetPasswordRequestLoading,
      sendResetPasswordRequestCalled,
    }),
    [
      sendResetPasswordRequest,
      sendResetPasswordRequestResult,
      sendResetPasswordRequestLoading,
      sendResetPasswordRequestCalled,
    ],
  );
};

export default useMutationSendResetPasswordRequest;
