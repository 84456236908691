import type { MouseEvent, ReactElement } from 'react';
import React from 'react';

import './style.scss';
import cn from 'classnames';
import LocationIcon from '~/components/icons/location';
import { inlineAddress } from '~/utils/inlineAddress';
import CardTile from '~/components/shared/CardTile';
import type { Address, AddressItem } from '~/graphql/schema';
import Bloc from '~/components/shared/shaping/Bloc';
import IconButton from '~/components/shared/buttons/IconButton';

type AddressCardTileProps<T extends Address | AddressItem> = {
  title?: string;
  className?: string;
  children?: never;
  address?: T | null;
  onClick?: (addressItem: T) => void;
  actionIcon?: ReactElement<SvgComponent>;
  onActionIconClick?: (e: MouseEvent, addressItem: T) => void;
};

const AddressCardTile = <T extends Address | AddressItem>({
  title,
  className,
  onClick,
  address,
  actionIcon,
  onActionIconClick,
}: AddressCardTileProps<T>) => (
  <Bloc style={{ position: 'relative' }}>
    <CardTile
      key={title || address?.name}
      className={cn(className, 'pri-fit-content pri-w-100')}
      spacing="lg"
      title={title || address?.name}
      prefixIcon={<LocationIcon />}
      subTitle={inlineAddress(address)}
      onClick={() => address && onClick?.(address)}
      noFiller={!!address}
    />
    {actionIcon && (
      <Bloc
        style={{ position: 'absolute', top: -10, right: -10, borderRadius: '50%', background: 'white' }}
        onClick={e => address && onActionIconClick?.(e, address)}
      >
        <IconButton variant="dark-outline" size="xs">
          {actionIcon}
        </IconButton>
      </Bloc>
    )}
  </Bloc>
);

export default AddressCardTile;
