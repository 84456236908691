import { useCallback, useEffect, useMemo } from 'react';
import { ok, refuse } from '@primo/operation-result';
import type { GetCompanySetupQuery } from '~/graphql/schema';
import { useGetCompanySetupLazyQuery } from '~/graphql/schema';
import type { UseQueryOptions } from '~/hooks/queries/types';

type UseGetCompanySetupVariables = {
  companyId: string;
};

const useQueryGetCompanySetup = (queryOptions: UseQueryOptions<UseGetCompanySetupVariables> = {}) => {
  const serializeDeviceData = useCallback((data: GetCompanySetupQuery | undefined) => {
    if (data?.getCompanySetup?.__typename === 'QueryGetCompanySetupSuccess') {
      return ok(data.getCompanySetup.data);
    }
    if (data?.getCompanySetup?.__typename === 'QueryGetCompanySetupError') return refuse(data.getCompanySetup.reasons);
    return refuse(['Unknown error']);
  }, []);

  const [query, { data: companySetupData, loading: companySetupLoading, called: companySetupCalled }] =
    useGetCompanySetupLazyQuery();

  const companySetupResult = useMemo(
    () => serializeDeviceData(companySetupData),
    [serializeDeviceData, companySetupData],
  );

  const getCompanySetup = useCallback(
    async (variables: UseGetCompanySetupVariables) => {
      const result = await query({ variables });
      return serializeDeviceData(result.data);
    },
    [query, serializeDeviceData],
  );

  useEffect(() => {
    if (queryOptions.runOnInit && !companySetupCalled) getCompanySetup({ companyId: queryOptions.companyId });
  }, [getCompanySetup, queryOptions, companySetupCalled]);

  return useMemo(
    () => ({
      getCompanySetup,
      companySetupResult,
      companySetupLoading,
      companySetupCalled,
    }),
    [getCompanySetup, companySetupResult, companySetupLoading, companySetupCalled],
  );
};

export default useQueryGetCompanySetup;
