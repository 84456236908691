import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import React from 'react';
import { Tab as CarbonTab } from '@carbon/react';
import Badge from '~/components/shared/Badge';

import './style.scss';

export type TabProps = {
  tabKey: string;
  title?: string;
  children: React.ReactNode;
  onClick?: () => void;
  selected?: boolean;
  className?: string;
  suffixIcon?: React.ReactNode;
  badge?: number | null;
};

/**
 * Note, the render as array is used because the Carbon Tab component infer the content as a tooltip,
 * it must not be an object and should not have null children
 */
const Tab = React.forwardRef<HTMLElement, Partial<TabProps>>((props, ref) => {
  const { t } = useTranslation();
  const { title, selected, className, tabKey, onClick, suffixIcon, badge } = props;

  return (
    <CarbonTab
      className={cn(className, 'pri-tab', { 'pri-tab-selected': selected })}
      ref={ref}
      onClick={onClick}
      title=""
    >
      {title ?? t(`tabs.${tabKey}`)}
      {!!badge && <Badge content={badge} />}
      {suffixIcon}
    </CarbonTab>
  );
});

export default Tab;
