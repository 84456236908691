import StepperWrapper from './_components/StepperWrapper';
import StepperStep from './_components/StepperStep';
import StepperSteps from './_components/StepperSteps';
import StepperRecap from './_components/StepperRecap';
import { StepperContext as Context } from './_components/StepperContext';

import './style.scss';

export const Stepper = StepperWrapper;
export const Steps = StepperSteps;
export const Step = StepperStep;
export const Recap = StepperRecap;
export const StepperContext = Context;
