import React from 'react';

const TrendIcon: SvgComponent = ({ className, width = 20 }) => (
  <svg className={className} width={width} height={width} viewBox="0 0 20 20" fill="none">
    <path
      d="M2.91875 17.5L6.9125 10L11.475 14.0562C11.6179 14.1832 11.7879 14.2757 11.9721 14.3266C12.1563 14.3775 12.3497 14.3855 12.5375 14.35C12.727 14.3145 12.9057 14.2357 13.0597 14.1197C13.2136 14.0037 13.3387 13.8536 13.425 13.6812L16.875 6.8125L15.7375 6.25L12.3063 13.125L7.74375 9.06875C7.6045 8.93925 7.43755 8.84323 7.2556 8.788C7.07364 8.73276 6.88148 8.71977 6.69375 8.75C6.50835 8.78076 6.33227 8.85293 6.17862 8.96114C6.02496 9.06934 5.89768 9.21081 5.80625 9.375L2.5 15.625V1.25H1.25V17.5C1.25 17.8315 1.3817 18.1495 1.61612 18.3839C1.85054 18.6183 2.16848 18.75 2.5 18.75H18.75V17.5H2.91875Z"
      fill="black"
    />
  </svg>
);

export default TrendIcon;
