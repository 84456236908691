import React from 'react';

const ErrorIcon: SvgComponent = ({ className, width = 16 }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="7.99805" cy="8" r="7" fill="#DA1E28" />
    <path
      d="M7.99805 3.625C7.13276 3.625 6.28689 3.88159 5.56743 4.36232C4.84796 4.84305 4.28721 5.52633 3.95608 6.32576C3.62494 7.12519 3.5383 8.00485 3.70711 8.85352C3.87592 9.70219 4.2926 10.4817 4.90446 11.0936C5.51631 11.7054 6.29586 12.1221 7.14453 12.2909C7.9932 12.4597 8.87286 12.3731 9.67229 12.042C10.4717 11.7108 11.155 11.1501 11.6357 10.4306C12.1165 9.71115 12.373 8.86529 12.373 8C12.373 6.83968 11.9121 5.72688 11.0916 4.90641C10.2712 4.08594 9.15837 3.625 7.99805 3.625ZM7.99805 11.75C7.25637 11.75 6.53134 11.5301 5.91466 11.118C5.29798 10.706 4.81733 10.1203 4.5335 9.43506C4.24967 8.74984 4.17541 7.99584 4.3201 7.26841C4.4648 6.54098 4.82195 5.8728 5.3464 5.34835C5.87085 4.8239 6.53903 4.46675 7.26646 4.32206C7.99389 4.17736 8.74789 4.25162 9.43311 4.53545C10.1183 4.81928 10.704 5.29993 11.1161 5.91661C11.5281 6.5333 11.748 7.25832 11.748 8C11.748 8.99456 11.353 9.94839 10.6497 10.6517C9.94644 11.3549 8.99261 11.75 7.99805 11.75Z"
      fill="white"
    />
    <path
      d="M7.68555 5.5H8.31055V8.9375H7.68555V5.5ZM7.99805 9.875C7.90534 9.875 7.81471 9.90249 7.73762 9.954C7.66054 10.0055 7.60046 10.0787 7.56498 10.1644C7.5295 10.25 7.52022 10.3443 7.5383 10.4352C7.55639 10.5261 7.60103 10.6097 7.66659 10.6752C7.73215 10.7408 7.81567 10.7854 7.9066 10.8035C7.99753 10.8216 8.09178 10.8123 8.17743 10.7768C8.26308 10.7413 8.33629 10.6813 8.3878 10.6042C8.43931 10.5271 8.4668 10.4365 8.4668 10.3438C8.4668 10.2194 8.41741 10.1002 8.3295 10.0123C8.2416 9.92439 8.12237 9.875 7.99805 9.875Z"
      fill="white"
    />
  </svg>
);

export default ErrorIcon;
