import { DevicePlatform } from '~/graphql/schema';

export const useNavigatorPlatorm = () => {
  const { platform } = navigator;

  if (/Android/.test(platform)) {
    return DevicePlatform.Android;
  }
  if (/iPhone|iPad|iPod/.test(platform)) {
    return DevicePlatform.Ios;
  }
  if (/Linux/.test(platform)) {
    return DevicePlatform.Linux;
  }
  if (/Mac/.test(platform)) {
    return DevicePlatform.Macos;
  }
  if (/Win/.test(platform)) {
    return DevicePlatform.Windows;
  }
  return DevicePlatform.Unknown;
};
