import type { HTMLAttributes } from 'react';
import React from 'react';
import cn from 'classnames';

import LaptopIcon from '~/components/icons/laptop';
import MobileIcon from '~/components/icons/mobile';
import TabletIcon from '~/components/icons/tablet';
import { DeviceType } from '~/graphql/schema';
import type { ViewDeviceType } from '~/graphql/schema';

export interface DeviceTypeIconProps extends HTMLAttributes<HTMLTableCellElement> {
  type?: DeviceType | ViewDeviceType | null;
  width?: number;
  mode?: 'list' | 'plain';
}

const DeviceTypeIconV2: FunctionComponent<DeviceTypeIconProps> = ({ type, className, width, mode = 'list' }) => {
  const classNames = cn(className, mode === 'plain' ? 'pri-drawer-title-icon' : '');

  switch (type) {
    case DeviceType.Laptop:
      return <LaptopIcon className={classNames} width={width} />;
    case DeviceType.Smartphone:
      return <MobileIcon className={classNames} width={width} />;
    case DeviceType.Tablet:
      return <TabletIcon className={classNames} width={width} />;
    default:
      return null;
  }
};

export default DeviceTypeIconV2;
