import React from 'react';

const ShippingMaterialIcon: SvgComponent = ({ className, width = 24 }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31 18L25 12L23.586 13.414L27.172 17H17V19H27.172L23.586 22.586L25 24L31 18ZM10 14C9.20888 14 8.43552 13.7654 7.77772 13.3259C7.11992 12.8864 6.60723 12.2616 6.30448 11.5307C6.00173 10.7998 5.92252 9.99556 6.07686 9.21964C6.2312 8.44372 6.61216 7.73098 7.17157 7.17157C7.73098 6.61216 8.44372 6.2312 9.21964 6.07686C9.99556 5.92252 10.7998 6.00173 11.5307 6.30448C12.2616 6.60723 12.8864 7.11992 13.3259 7.77772C13.7654 8.43552 14 9.20888 14 10C13.9988 11.0605 13.577 12.0772 12.8271 12.8271C12.0772 13.577 11.0605 13.9988 10 14ZM10 8C9.60454 7.99961 9.21784 8.11646 8.88878 8.33579C8.55971 8.55511 8.30305 8.86707 8.15124 9.23224C7.99943 9.5974 7.95928 9.99937 8.03587 10.3873C8.11246 10.7753 8.30235 11.1319 8.58153 11.412C8.86072 11.692 9.21667 11.8831 9.60439 11.9609C9.99212 12.0387 10.3942 11.9999 10.7599 11.8492C11.1255 11.6986 11.4383 11.4429 11.6587 11.1145C11.879 10.7862 11.9971 10.3999 11.998 10.0044C11.9986 9.47372 11.7885 8.96449 11.4139 8.58864C11.0392 8.21278 10.5307 8.00106 10 8Z"
      fill="black"
    />
    <path
      d="M21.0593 25L18.0579 28L4 13.9414V4H13.9417L20.9417 11L22.356 9.586L15.355 2.586C14.9801 2.211 14.4717 2.00022 13.9414 2H4C3.46957 2 2.96086 2.21071 2.58579 2.58579C2.21071 2.96086 2 3.46957 2 4V13.9414C2.00001 14.4718 2.21069 14.9804 2.5857 15.3555L16.6436 29.4146C17.0188 29.7895 17.5275 30.0001 18.0579 30.0001C18.5883 30.0001 19.097 29.7895 19.4722 29.4146L22.4736 26.4146L21.0593 25Z"
      fill="black"
    />
  </svg>
);

export default ShippingMaterialIcon;
