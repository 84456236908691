import React from 'react';

const CopyFileIcon: SvgComponent = ({ className, width = 24, color = '#1E1E1E' }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.55 11.025L15.975 6.45C15.75 6.15 15.375 6 15 6H9C8.175 6 7.5 6.675 7.5 7.5V21C7.5 21.825 8.175 22.5 9 22.5H19.5C20.325 22.5 21 21.825 21 21V12.075C21 11.7 20.85 11.325 20.55 11.025ZM15 7.5L19.425 12H15V7.5ZM9 21V7.5H13.5V12C13.5 12.825 14.175 13.5 15 13.5H19.5V21H9Z"
      fill={color}
    />
    <path d="M4.5 13.5H3V3C3 2.175 3.675 1.5 4.5 1.5H15V3H4.5V13.5Z" fill={color} />
  </svg>
);

export default CopyFileIcon;
