import type React from 'react';
import { useCallback } from 'react';
import type { Option, Value } from './types';
import { Truthy } from '~/utils/truthy';

export const useHandleOnKeyDown = <Val extends Value>(
  customOptions: Option<Val>[],
  setCustomOptions: (o: Option<Val>[]) => void,
  acceptCustomValues?: boolean,
) =>
  useCallback(
    (
      controllerOnChange: (val: (string | Val)[]) => void,
      event: React.KeyboardEvent,
      selectedOptions: undefined | Option<Val> | Option<Val>[],
    ) => {
      const input = event.target as HTMLInputElement;
      const inputtedValue = input.value;
      if (acceptCustomValues && event.key === 'Enter' && inputtedValue) {
        input.value = '';
        input.blur();
        const newOption = inputtedValue;
        setCustomOptions([...customOptions, { label: inputtedValue, value: inputtedValue as Val }]);
        setTimeout(
          () =>
            controllerOnChange(
              Array.isArray(selectedOptions)
                ? [...selectedOptions.map(o => o.value), newOption]
                : [selectedOptions?.value, newOption].filter(Truthy),
            ),
          100,
        );
      }
    },
    [acceptCustomValues, customOptions, setCustomOptions],
  );
