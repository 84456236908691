import { useContext, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { Truthy } from '~/utils/truthy';
import { OnboardingStepperContext } from '~/features/steppers/OnboardingStepper/context';
import type { OnboardingStepperContextData } from '~/features/steppers/OnboardingStepper/types';

export default (selection: { inventory: Record<string, number>; catalog: Record<string, number> }) => {
  const { inventory, catalog } = useContext(OnboardingStepperContext);
  const [result, setResult] = useState<NonNullable<OnboardingStepperContextData['equipments']>>({
    catalog: [],
    inventory: [],
  });

  useDeepCompareEffect(() => {
    const computedResult = {
      inventory: Object.entries(selection.inventory)
        .filter(([, v]) => !!v)
        .map(([k, v]) => {
          const item =
            inventory.devices.find(device => device.id === k) ||
            inventory.accessories.find(accessory => accessory.id === k);
          if (item) return { item, quantity: v as number };
          return null;
        })
        .filter(Truthy),
      catalog: Object.entries(selection.catalog)
        .filter(([, v]) => !!v)
        .map(([k, v]) => {
          const item = catalog.find(product => product.id === k);
          if (item) return { item, quantity: v as number };
          return null;
        })
        .filter(Truthy),
    };
    setResult(computedResult);
  }, [catalog, inventory.accessories, inventory.devices, selection.catalog, selection.inventory]);

  return result;
};
