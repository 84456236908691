import React from 'react';

const SuccessIcon: SvgComponent = ({ className, width = 16, color = '#10652E' }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="7.99805" cy="8" r="7" fill={color} />
    <path
      d="M7.16471 9.625L4.95638 7.41667L4.49805 7.83333L6.70638 10.0417L7.16471 10.5L11.5814 6.08333L11.123 5.625L7.16471 9.625Z"
      fill="white"
    />
  </svg>
);

export default SuccessIcon;
