import React from 'react';

const HeadsetIcon: SvgComponent = ({ className, width = 24, color = '#1E1E1E' }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25 10H24.94C24.6855 7.80743 23.6342 5.78481 21.9859 4.31674C20.3376 2.84866 18.2073 2.03751 16 2.03751C13.7927 2.03751 11.6624 2.84866 10.0141 4.31674C8.36578 5.78481 7.31445 7.80743 7.06 10H7C5.67392 10 4.40215 10.5268 3.46447 11.4645C2.52678 12.4022 2 13.6739 2 15C2 16.3261 2.52678 17.5979 3.46447 18.5355C4.40215 19.4732 5.67392 20 7 20H9V11C9 9.14349 9.7375 7.36302 11.0503 6.05026C12.363 4.73751 14.1435 4.00001 16 4.00001C17.8565 4.00001 19.637 4.73751 20.9497 6.05026C22.2625 7.36302 23 9.14349 23 11V21C22.9993 21.9162 22.6841 22.8044 22.1071 23.5161C21.5301 24.2278 20.7263 24.7199 19.83 24.91C19.5631 23.9738 18.9638 23.167 18.1446 22.641C17.3254 22.115 16.3425 21.9057 15.3801 22.0525C14.4177 22.1994 13.5419 22.6921 12.9168 23.4385C12.2917 24.1848 11.9602 25.1335 11.9846 26.1068C12.0089 27.08 12.3873 28.011 13.0489 28.7252C13.7105 29.4393 14.6099 29.8877 15.5785 29.9863C16.547 30.0848 17.5182 29.8267 18.3101 29.2604C19.102 28.6941 19.6602 27.8584 19.88 26.91C21.299 26.6996 22.5957 25.9878 23.5349 24.9036C24.4742 23.8194 24.994 22.4345 25 21V20C26.3261 20 27.5979 19.4732 28.5355 18.5355C29.4732 17.5979 30 16.3261 30 15C30 13.6739 29.4732 12.4022 28.5355 11.4645C27.5979 10.5268 26.3261 10 25 10ZM4 15C4 14.2044 4.31607 13.4413 4.87868 12.8787C5.44129 12.3161 6.20435 12 7 12V18C6.20435 18 5.44129 17.6839 4.87868 17.1213C4.31607 16.5587 4 15.7957 4 15ZM16 28C15.6044 28 15.2178 27.8827 14.8889 27.6629C14.56 27.4432 14.3036 27.1308 14.1522 26.7654C14.0009 26.3999 13.9613 25.9978 14.0384 25.6098C14.1156 25.2219 14.3061 24.8655 14.5858 24.5858C14.8655 24.3061 15.2219 24.1156 15.6098 24.0384C15.9978 23.9613 16.3999 24.0009 16.7654 24.1523C17.1308 24.3036 17.4432 24.56 17.6629 24.8889C17.8827 25.2178 18 25.6044 18 26C18 26.5304 17.7893 27.0392 17.4142 27.4142C17.0391 27.7893 16.5304 28 16 28ZM25 18V12C25.7956 12 26.5587 12.3161 27.1213 12.8787C27.6839 13.4413 28 14.2044 28 15C28 15.7957 27.6839 16.5587 27.1213 17.1213C26.5587 17.6839 25.7956 18 25 18Z"
      fill={color}
    />
  </svg>
);

export default HeadsetIcon;
