import React from 'react';

const LogoTypoIcon: SvgComponent = ({ className, width = 90, color = 'white' }) => (
  <svg
    className={className}
    width={width}
    height={width / (90 / 28)}
    viewBox="0 0 90 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.877441 27V0.267487H9.46587C11.214 0.267487 12.772 0.648347 14.1401 1.41007C15.5082 2.17179 16.5596 3.20011 17.2943 4.49503C18.0543 5.76457 18.4343 7.19914 18.4343 8.79876C18.4343 10.373 18.0543 11.8075 17.2943 13.1025C16.5596 14.3974 15.5082 15.4257 14.1401 16.1874C12.772 16.9238 11.214 17.2919 9.46587 17.2919H5.77969V27H0.877441ZM9.04785 12.5312C9.85856 12.5312 10.5679 12.3661 11.176 12.0361C11.8093 11.706 12.3033 11.249 12.658 10.665C13.0127 10.081 13.1901 9.44622 13.1901 8.76067C13.1901 8.07512 13.0127 7.44035 12.658 6.85637C12.3033 6.27238 11.8093 5.81535 11.176 5.48527C10.5679 5.15519 9.85856 4.99015 9.04785 4.99015H5.77969V12.5312H9.04785Z"
      fill={color}
    />
    <path d="M20.661 27V9.24998H32.4796V13.5918H25.2592V27H20.661Z" fill={color} />
    <path d="M35.6988 27V22.4277V13.5918V9.24998H40.4491V22.4277V27H35.6988Z" fill={color} />
    <path
      d="M43.8332 27V9.24998H61.4661C62.8595 9.24998 64.1263 9.58006 65.2663 10.2402C66.4064 10.875 67.2931 11.751 67.9264 12.8681C68.5851 13.9853 68.9145 15.2168 68.9145 16.5625V27H64.2783V16.6387C64.2783 15.75 63.9996 15.0264 63.4422 14.4678C62.8849 13.8838 62.1628 13.5918 61.2761 13.5918H58.654V27H54.0558V13.5918H48.4315V27H43.8332Z"
      fill={color}
    />
    <path
      d="M80.4837 27.2304C78.8116 27.2304 77.2662 26.8242 75.8475 26.0117C74.4287 25.1992 73.3013 24.0947 72.4653 22.6982C71.6546 21.3017 71.2492 19.7656 71.2492 18.0898C71.2492 16.414 71.6546 14.8779 72.4653 13.4814C73.3013 12.0849 74.4287 10.9804 75.8475 10.1679C77.2662 9.35541 78.8116 8.94916 80.4837 8.94916H80.5597C82.2571 8.94916 83.8152 9.35541 85.2339 10.1679C86.6527 10.9804 87.7674 12.0849 88.5781 13.4814C89.4141 14.8779 89.8322 16.414 89.8322 18.0898C89.8322 19.7656 89.4141 21.3017 88.5781 22.6982C87.7674 24.0947 86.6527 25.1992 85.2339 26.0117C83.8152 26.8242 82.2571 27.2304 80.5597 27.2304H80.4837ZM80.5597 22.622C81.3704 22.622 82.1178 22.4189 82.8018 22.0127C83.5112 21.581 84.0559 21.0224 84.4359 20.3369C84.8413 19.6259 85.0439 18.8769 85.0439 18.0898C85.0439 17.2773 84.8413 16.5283 84.4359 15.8427C84.0559 15.1318 83.5112 14.5732 82.8018 14.1669C82.1178 13.7607 81.3704 13.5576 80.5597 13.5576H80.4837C79.673 13.5576 78.9256 13.7607 78.2416 14.1669C77.5576 14.5732 77.0129 15.1318 76.6075 15.8427C76.2275 16.5283 76.0375 17.2773 76.0375 18.0898C76.0375 18.9023 76.2275 19.664 76.6075 20.375C77.0129 21.0605 77.5576 21.6064 78.2416 22.0127C78.9256 22.4189 79.673 22.622 80.4837 22.622H80.5597Z"
      fill={color}
    />
    <path
      d="M35.704 5.90422C36.3627 6.56437 37.1481 6.89445 38.0601 6.89445C38.9722 6.89445 39.7449 6.56437 40.3783 5.90422C41.037 5.24406 41.3663 4.46964 41.3663 3.58097C41.3663 2.66691 41.037 1.89249 40.3783 1.25772C39.7449 0.597566 38.9722 0.267487 38.0601 0.267487C37.1481 0.267487 36.3627 0.597566 35.704 1.25772C35.0707 1.89249 34.754 2.66691 34.754 3.58097C34.754 4.46964 35.0707 5.24406 35.704 5.90422Z"
      fill={color}
    />
  </svg>
);

export default LogoTypoIcon;
