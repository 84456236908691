import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'react-use';
import { Form } from '~/components/shared/forms/Form';
import Select from '~/components/shared/forms/Select';
import { PrimoAdminContext } from '~/providers/primo/PrimoAdminProvider';
import { Truthy } from '~/utils/truthy';

const flagMapping = {
  en: '🇺🇸',
  fr: '🇫🇷',
  cimode: '🤖',
};

type FlagMappingKeys = keyof typeof flagMapping;

type CountrySelectorProps = {
  dark?: boolean;
  small?: boolean;
};

const CountrySelector: FunctionComponent<CountrySelectorProps> = props => {
  const { isBetaActivated } = useContext(PrimoAdminContext);
  const { i18n, t } = useTranslation();
  const [localStorageLanguage, setLanguage] = useLocalStorage('language');
  const form = useForm();
  const { watch } = form;
  const language = watch('language');

  const resolvedLanguage = (localStorageLanguage || i18n.resolvedLanguage) as FlagMappingKeys;

  useEffect(() => {
    if (!language) return;
    i18n.changeLanguage(language);
    setLanguage(language);
  }, [i18n, language, setLanguage]);

  return (
    <Form form={form}>
      <Select
        control={form.control}
        name="language"
        options={['en', 'fr', isBetaActivated ? 'cimode' : null].filter(Truthy) as FlagMappingKeys[]}
        placeholder={`${flagMapping[resolvedLanguage]} ${t(`enums.languages.${resolvedLanguage}`)}`}
        defaultValue={resolvedLanguage}
        getOptionLabel={option => `${flagMapping[option]} ${t(`enums.languages.${option}`)}`}
        {...props}
      />
    </Form>
  );
};

export default CountrySelector;
