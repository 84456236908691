type TruthyArray<T> = T extends false | '' | 0 | null | undefined ? never : T;

/**
 * To use with .filter function in array, [].filter(Truthy) will remove all falsy values from array and type it correctly
 * @param value
 * @constructor
 */
export function Truthy<T>(value: T): value is TruthyArray<T> {
  return !!value;
}
