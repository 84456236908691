import { useCallback, useEffect, useMemo } from 'react';
import { ok, refuse } from '@primo/operation-result';
import type { GetCompanyBillingProfilesQuery } from '~/graphql/schema';
import { useGetCompanyBillingProfilesLazyQuery } from '~/graphql/schema';
import type { UseQueryOptions } from '~/hooks/queries/types';

type UseGetCompanyBillingProfilesVariables = {
  companyId: string;
};

const useQueryGetCompanyBillingProfiles = (
  queryOptions: UseQueryOptions<UseGetCompanyBillingProfilesVariables> = {},
) => {
  const serializeCompanyBillingProfilesData = useCallback((data: GetCompanyBillingProfilesQuery | undefined) => {
    if (data?.getCompanyBillingProfiles?.__typename === 'QueryGetCompanyBillingProfilesSuccess') {
      return ok(data.getCompanyBillingProfiles.data);
    }
    if (data?.getCompanyBillingProfiles?.__typename === 'QueryGetCompanyBillingProfilesError')
      return refuse(data.getCompanyBillingProfiles.reasons);
    return refuse(['Unknown error']);
  }, []);

  const [
    query,
    { data: companyBillingProfilesData, loading: companyBillingProfilesLoading, called: companyBillingProfilesCalled },
  ] = useGetCompanyBillingProfilesLazyQuery({ fetchPolicy: 'no-cache' });

  const companyBillingProfilesResult = useMemo(
    () => serializeCompanyBillingProfilesData(companyBillingProfilesData),
    [serializeCompanyBillingProfilesData, companyBillingProfilesData],
  );

  const getCompanyBillingProfiles = useCallback(
    async (variables: UseGetCompanyBillingProfilesVariables) => {
      const result = await query({ variables });
      return serializeCompanyBillingProfilesData(result.data);
    },
    [query, serializeCompanyBillingProfilesData],
  );

  useEffect(() => {
    if (queryOptions.runOnInit && !companyBillingProfilesCalled)
      getCompanyBillingProfiles({ companyId: queryOptions.companyId });
  }, [getCompanyBillingProfiles, queryOptions, companyBillingProfilesCalled]);

  return useMemo(
    () => ({
      getCompanyBillingProfiles,
      companyBillingProfilesResult,
      companyBillingProfilesLoading,
      companyBillingProfilesCalled,
    }),
    [
      getCompanyBillingProfiles,
      companyBillingProfilesResult,
      companyBillingProfilesLoading,
      companyBillingProfilesCalled,
    ],
  );
};

export default useQueryGetCompanyBillingProfiles;
