import { zodResolver } from '@hookform/resolvers/zod';
import React, { useCallback, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import type { OrganizationFormFields } from '~/validations/login';
import { chooseOrganizationSchema } from '~/validations/login';

import { LoginContext } from '../context';
import Input from '~/components/shared/forms/Input';
import Button from '~/components/shared/buttons/Button';
import Flex from '~/components/shared/shaping/Flex';
import { Form } from '~/components/shared/forms/Form';
import { Text, Title } from '~/components/shared/typography';
import { SIGNUP_ROUTE } from '~/config/routes';

const ChooseOrganizationPart: FunctionComponent = () => {
  const { t } = useTranslation();
  const { setOrganization } = useContext(LoginContext);
  const form = useForm<OrganizationFormFields>({
    mode: 'onSubmit',
    resolver: zodResolver(chooseOrganizationSchema(t)),
  });
  const {
    formState: { errors },
    register,
    handleSubmit,
  } = form;

  const onSubmit = useCallback(
    (e: OrganizationFormFields) => {
      setOrganization(e.organization);
    },
    [setOrganization],
  );

  return (
    <Flex direction="column">
      <Title level={3}>{t('titles.enter_company_information')}</Title>
      <Form form={form} className="pri-mt-6" onSubmit={handleSubmit(onSubmit)}>
        <Input name="organization" label={t('forms.labels.company')} register={register} errors={errors} required />
        <Flex direction="row">
          <Button type="submit" size="xl" className="flex-1 pri-mt-1" fullWidth>
            {t('buttons.confirm')}
          </Button>
        </Flex>
      </Form>
      <Flex className="pri-mt-4" direction="row" justify="center" align="baseline" gap={1}>
        <Text weight="light">{t('texts.no_account_yet')}</Text>
        <Link to={SIGNUP_ROUTE()}>
          <Text>{t('links.sign_up')}</Text>
        </Link>
      </Flex>
    </Flex>
  );
};

export default ChooseOrganizationPart;
