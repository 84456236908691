import React, { useContext } from 'react';

import { LoginContext } from '../context';
import ChooseOrganizationPart from './ChooseOrganizationPart';
import LoginPart from './LoginPart';
import Flex from '~/components/shared/shaping/Flex';
import Loader from '~/components/shared/Loader';

const LoginFlowPart: FunctionComponent = () => {
  const { organization, organizationId, loading } = useContext(LoginContext);

  if (loading) {
    return (
      <Flex fullHeight fullWidth justify="center" align="center">
        <Loader />
      </Flex>
    );
  }
  if (organizationId && organization) {
    return <LoginPart organization={organization} />;
  }
  return <ChooseOrganizationPart />;
};

export default LoginFlowPart;
