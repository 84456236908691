import React, { useContext } from 'react';

import { PrimoAdminContext } from '~/providers/primo/PrimoAdminProvider';

import { AdminNavSectionProvider } from './context';
import SelectCompanyPart from './parts/SelectCompanyPart';

import './index.scss';

const AdminNavSection: FunctionComponent = () => {
  const { employee, toggleBeta, isBetaActivated } = useContext(PrimoAdminContext);

  if (!employee?.isInternalEmployee) return null;

  return (
    <AdminNavSectionProvider>
      <div className="admin-nav-section">
        <SelectCompanyPart />
        <button type="button" className="beta-mode" onClick={toggleBeta}>
          <span>Beta</span>
          <input type="checkbox" readOnly checked={isBetaActivated} />
        </button>
      </div>
    </AdminNavSectionProvider>
  );
};

export default AdminNavSection;
