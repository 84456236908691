import React from 'react';

const ChargerIcon: SvgComponent = ({ className, width = 24 }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.857 21.514L10.143 20.486L12.234 17H7.23401L11.143 10.486L12.857 11.514L10.766 15H15.766L11.857 21.514Z"
      fill="black"
    />
    <path
      d="M30 7H29V4H27V7H26V13H27V24.5C27 24.8978 26.842 25.2794 26.5607 25.5607C26.2794 25.842 25.8978 26 25.5 26C25.1022 26 24.7206 25.842 24.4393 25.5607C24.158 25.2794 24 24.8978 24 24.5V16C24 15.7348 23.8946 15.4804 23.7071 15.2929C23.5196 15.1054 23.2652 15 23 15H19V5C19 4.73478 18.8946 4.48043 18.7071 4.29289C18.5196 4.10536 18.2652 4 18 4H5C4.73478 4 4.48043 4.10536 4.29289 4.29289C4.10536 4.48043 4 4.73478 4 5V26H2V28H21V26H19V17H22V24.5C22 25.4283 22.3687 26.3185 23.0251 26.9749C23.6815 27.6313 24.5717 28 25.5 28C26.4283 28 27.3185 27.6313 27.9749 26.9749C28.6313 26.3185 29 25.4283 29 24.5V13H30V7ZM17 26H6V6H17V26Z"
      fill="black"
    />
  </svg>
);

export default ChargerIcon;
