/* eslint-disable prefer-destructuring */
/* eslint-disable no-process-env */

export const NODE_ENV = process.env.NODE_ENV;
export const APP_VERSION = String(process.env.npm_package_version);
export const ENV = process.env.ENV ?? 'development';
export const PRODUCTION = NODE_ENV === 'production';

export const AUTH0_DOMAIN_NAME = String(process.env.PUBLIC_AUTH0_DOMAIN_NAME);
export const AUTH0_CLIENT_ID = String(process.env.PUBLIC_AUTH0_CLIENT_ID);
export const AUTH0_SIGNUP_CLIENT_ID = String(process.env.PUBLIC_AUTH0_SIGNUP_CLIENT_ID);
export const AUTH0_AUDIENCE_API = String(process.env.PUBLIC_AUTH0_AUDIENCE_API);
export const MERGE_TENANT_API_URL = String(process.env.PUBLIC_MERGE_TENANT_API_URL);

export const DATADOG_CLIENT_TOKEN = String(process.env.PUBLIC_DATADOG_CLIENT_TOKEN);
export const DATADOG_APP_ID = String(process.env.PUBLIC_DATADOG_APP_ID);
export const DATADOG_TRACING_ORIGIN = String(process.env.PUBLIC_DATADOG_TRACING_ORIGIN);

export const API_PATH = process.env.PUBLIC_API_PATH;
export const SEGMENT_WRITE_KEY = String(process.env.PUBLIC_SEGMENT_WRITE_KEY);

export const CSVBOX_LICENSE_KEY = process.env.PUBLIC_CSVBOX_LICENSE_KEY || null;
export const CSVBOX_LICENSE_KEY_DEVICES = process.env.PUBLIC_CSVBOX_LICENSE_KEY_DEVICES || null;
export const CSVBOX_LICENSE_KEY_EMPLOYEES = process.env.PUBLIC_CSVBOX_LICENSE_KEY_EMPLOYEES || null;
