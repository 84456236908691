import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import type { InitialSetupStepperContextData } from '../types';
import { StepperContext } from '~/components/shared/Stepper';
import useMutationAcceptEula from '~/hooks/queries/setup/useMutationAcceptEula';
import { PrimoAdminContext } from '~/providers/primo/PrimoAdminProvider';

export type InitialSetupStepperContextProps = {
  loading: boolean;
  setupCalled: boolean;
  contextData: InitialSetupStepperContextData;
  setContextData: (contextData: InitialSetupStepperContextData) => void;
  onFinish: (contextData?: InitialSetupStepperContextData) => Promise<void>;
};

export const InitialSetupStepperContext = createContext<InitialSetupStepperContextProps>({
  loading: false,
  setupCalled: false,
  contextData: {},
  setContextData: () => {},
  onFinish: async () => {},
});

export const InitialSetupStepperProvider: FunctionComponent = ({ children }) => {
  const { company } = useContext(PrimoAdminContext);
  const { toggleLoading, onClose } = useContext(StepperContext);
  const [contextData, setContextData] = useState<InitialSetupStepperContextData>({});

  const loading = false;
  const setupCalled = true;

  const { acceptEula } = useMutationAcceptEula();

  const onFinish = useCallback(async () => {
    if (!company) return;
    toggleLoading();

    await acceptEula({ companyId: company.id });

    onClose();
  }, [acceptEula, company, onClose, toggleLoading]);

  const value = useMemo(
    () => ({
      loading,
      setupCalled,
      contextData,
      setContextData,
      onFinish,
    }),
    [loading, setupCalled, contextData, setContextData, onFinish],
  );

  return <InitialSetupStepperContext.Provider value={value}>{children}</InitialSetupStepperContext.Provider>;
};
