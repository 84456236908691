import type { ReactElement } from 'react';
import React, { useContext } from 'react';
import cn from 'classnames';
import Flex from '~/components/shared/shaping/Flex';
import type { StepperStepProps } from './StepperStep';
import { StepperContext } from './StepperContext';
import { Title } from '~/components/shared/typography';

export interface StepperStepsProps {
  title?: string;
  children: (ReactElement<StepperStepProps> | undefined) | (ReactElement<StepperStepProps> | undefined)[];
}

const StepperSteps: React.FC<StepperStepsProps> = ({ title, children }) => {
  const { currentStep } = useContext(StepperContext);
  const childrenArray = Array.isArray(children) ? children : [children];
  const truthyChildren = childrenArray.filter(Boolean);
  const selectedStep = truthyChildren[currentStep - 1];

  return (
    <Flex className="pri-steps" direction="column" align="center">
      <Flex className={cn('pri-steps-container')} direction="column-reverse" align="center" justify="center" gap={9}>
        {selectedStep}
        {title && (
          <Title level={2} className="pri-steps-title">
            {title}
          </Title>
        )}
      </Flex>
    </Flex>
  );
};

export default StepperSteps;
