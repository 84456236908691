import React from 'react';
import cn from 'classnames';

import './style.scss';

const Filler: FunctionComponent = ({ className, children }) => (
  <div className={cn(className, 'pri-filler')}>{children}</div>
);

export default Filler;
