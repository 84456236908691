import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { AdminSegment, EmployeeSegment } from '~/features/analytics/Segment';
import ApolloAppProvider from '~/providers/api/graphql/ApolloAppProvider';

import RestAppProvider from '~/providers/api/rest/RestAppProvider';
import { EMPLOYEE_SESSION_BASE_ROUTE } from './config/routes';
import SmallScreenWarning from './features/layout/SmallScreenWarning';
import PrimoAdminProvider from './providers/primo/PrimoAdminProvider';
import PrimoEmployeeProvider from './providers/primo/PrimoEmployeeProvider';
import { AdminRouter } from './routers/adminRouter';
import { EmployeeRouter } from './routers/employeeRouter';

const App: FunctionComponent = () => (
  <ApolloAppProvider>
    <RestAppProvider>
      <BrowserRouter>
        <SmallScreenWarning />
        <Routes>
          <Route
            path={`${EMPLOYEE_SESSION_BASE_ROUTE()}/*`}
            element={
              <PrimoEmployeeProvider>
                <EmployeeSegment />
                <EmployeeRouter />
              </PrimoEmployeeProvider>
            }
          />

          <Route
            path="/*"
            element={
              <PrimoAdminProvider>
                <AdminSegment />
                <AdminRouter />
              </PrimoAdminProvider>
            }
          />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </RestAppProvider>
  </ApolloAppProvider>
);

export default App;
