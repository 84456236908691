import type { HTMLAttributes } from 'react';
import React from 'react';
import AndroidIcon from '~/components/icons/android';
import AppleIcon from '~/components/icons/apple';

import LinuxIcon from '~/components/icons/linux';
import WindowsIcon2024 from '~/components/icons/windows_2024';
import { Text } from '~/components/shared/typography';
import { DevicePlatform } from '~/graphql/schema';
import type { ViewDevicePlatform } from '~/graphql/schema';

export interface DevicePlatformIconProps extends HTMLAttributes<HTMLTableCellElement> {
  platform?: DevicePlatform | ViewDevicePlatform | null;
  width?: number;
}

const DevicePlatformIconV2: FunctionComponent<DevicePlatformIconProps> = ({ platform, className, width }) => {
  switch (platform) {
    case DevicePlatform.Android:
      return <AndroidIcon className={className} width={width} />;
    case DevicePlatform.Ios:
    case DevicePlatform.Macos:
      return <AppleIcon className={className} width={width} />;
    case DevicePlatform.Linux:
      return <LinuxIcon className={className} width={width} />;
    case DevicePlatform.Windows:
      return <WindowsIcon2024 className={className} width={width} />;
    default:
      return <Text>-</Text>;
  }
};

export default DevicePlatformIconV2;
