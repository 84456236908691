import type { MouseEventHandler } from 'react';
import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import type { FieldValues, Path } from 'react-hook-form';
import type { UseFormRegister } from 'react-hook-form/dist/types/form';

import Flex from '~/components/shared/shaping/Flex';
import { Text, Title } from '~/components/shared/typography';
import Tile from '~/components/shared/Tile';
import type { CompanyExternalIntegration, ExternalIntegration } from '~/graphql/schema';

import './style.scss';
import Pill from '~/components/shared/Pill';
import { RoundedImage } from '~/components/shared/images';
import Checkbox from '~/components/shared/forms/Checkbox';

type IntegrationCardProps<FV extends FieldValues> = {
  children?: never;
  className?: string;
  companyExternalIntegration: Pick<CompanyExternalIntegration, 'provisioningEnabled' | 'provisioningRedactedToken'> & {
    externalIntegration?: Pick<ExternalIntegration, 'name' | 'logoUrl'> | null;
  };
  selected?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  checkbox?: {
    value: string;
    name: Path<FV>;
    register: UseFormRegister<FV>;
  };
};

const IntegrationPill: FunctionComponent<{ enabled: boolean }> = ({ enabled }) => {
  const { t } = useTranslation();

  return enabled ? (
    <Pill size="sm" color="green">
      {t('badges.connected')}
    </Pill>
  ) : (
    <Pill size="sm" color="grey">
      {t('badges.not_configured')}
    </Pill>
  );
};

const IntegrationCard = <FV extends FieldValues>({
  className,
  companyExternalIntegration,
  checkbox,
  selected,
  onClick,
}: IntegrationCardProps<FV>) => {
  const { t } = useTranslation();

  if (!companyExternalIntegration.externalIntegration) {
    return null;
  }

  const { logoUrl, name } = companyExternalIntegration.externalIntegration;

  return (
    <Tile selected={selected} className={cn('pri-integration-card', className)} onClick={onClick}>
      <Flex direction="column" gap={2}>
        <Flex justify="between">
          <RoundedImage src={logoUrl} />
          {checkbox ? (
            <Checkbox name={checkbox.name} register={checkbox.register} value={checkbox.value} />
          ) : (
            <IntegrationPill enabled={!!companyExternalIntegration.provisioningEnabled} />
          )}
        </Flex>
        <Title level={5}>{t(`enums.external_integrations.${name?.toLowerCase()}`)}</Title>
        <Text size="sm" weight="light" variant="muted-light" className="pri-integration-card-text">
          {t(`enums.external_integrations.descriptions.${name?.toLowerCase()}`)}
        </Text>
      </Flex>
    </Tile>
  );
};

export default IntegrationCard;
