import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderStepperContext } from '../context';
import Card from '~/components/shared/Card';
import CardTile from '~/components/shared/CardTile';
import EquipmentTypeIconV2 from '~/components/featured/typeIcon/EquipmentTypeIcon';
import Price from '~/components/shared/Price';
import { Text } from '~/components/shared/typography';

const EquipmentsRecap: FunctionComponent = () => {
  const { t } = useTranslation();
  const {
    contextData: { equipments },
  } = useContext(OrderStepperContext);

  return (
    <Card title={t('steppers.orders.equipments')}>
      {(equipments || []).map(({ item, quantity }) =>
        Array.from({ length: quantity }).map((_, i) => (
          <CardTile
            key={`${item.id}-${i.toString()}`}
            prefixIcon={<EquipmentTypeIconV2 type={item.type} />}
            title={<Text maxLength={30}>{item.name || '-'}</Text>}
            suffixNode={
              <Text weight="light">
                {'priceNoVAT' in item ? <Price value={item.priceNoVAT} /> : t('forms.labels.inventory')}
              </Text>
            }
          />
        )),
      )}
    </Card>
  );
};

export default EquipmentsRecap;
