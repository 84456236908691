import { useMemo } from 'react';
import { isEqual } from 'lodash';
import useMutationCreateOrUpdateCompanyAddress from '~/hooks/queries/companies/useMutationCreateOrUpdateCompanyAddress';
import useMutationUpdateEmployee from '~/hooks/queries/employees/useMutationUpdateEmployee';
import { useCompany } from '~/hooks/useCompany';
import type { Address, AddressItem } from '~/graphql/schema';
import { OrderShippingAddressChoice } from '~/graphql/schema';

const useUpdateAddressIfNeeded = () => {
  const { createOrUpdateCompanyAddress } = useMutationCreateOrUpdateCompanyAddress();
  const { updateEmployee } = useMutationUpdateEmployee();
  const company = useCompany();

  return useMemo(
    () =>
      async (
        shippingAddressType: OrderShippingAddressChoice,
        address: Address | AddressItem,
        employeeId: Maybe<string>,
      ) => {
        if (shippingAddressType === OrderShippingAddressChoice.New && company) {
          await createOrUpdateCompanyAddress({
            companyId: company.id,
            address: address as AddressItem,
          });
        }
        if (shippingAddressType === OrderShippingAddressChoice.Home && employeeId) {
          await updateEmployee({
            employeeId,
            address: address as Address,
          });
        }
        if (shippingAddressType === OrderShippingAddressChoice.Office) {
          const concernedAddress = company?.addresses.find(({ id }) => id === (address as AddressItem).id);
          if (!isEqual(concernedAddress, address)) {
            await createOrUpdateCompanyAddress({
              companyId: company?.id || '',
              address: address as AddressItem,
            });
          }
        }
      },
    [company, createOrUpdateCompanyAddress, updateEmployee],
  );
};

export default useUpdateAddressIfNeeded;
