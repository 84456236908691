import React from 'react';

const KeyboardIcon: SvgComponent = ({ className, width = 24, color = '#1E1E1E' }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 13.5H2C1.73478 13.5 1.48043 13.3946 1.29289 13.2071C1.10536 13.0196 1 12.7652 1 12.5V5.5C1 5.23478 1.10536 4.98043 1.29289 4.79289C1.48043 4.60536 1.73478 4.5 2 4.5H14C14.2652 4.5 14.5196 4.60536 14.7071 4.79289C14.8946 4.98043 15 5.23478 15 5.5V12.5C15 12.7652 14.8946 13.0196 14.7071 13.2071C14.5196 13.3946 14.2652 13.5 14 13.5ZM2 5.5V12.5H14V5.5H2Z"
      fill={color}
    />
    <path
      d="M5 10.5H10.5V11.5H5V10.5ZM3 6.5H4V7.5H3V6.5ZM5 6.5H6V7.5H5V6.5ZM7 6.5H8V7.5H7V6.5ZM9 6.5H10V7.5H9V6.5ZM3 10.5H4V11.5H3V10.5ZM3 8.5H4V9.5H3V8.5ZM5 8.5H6V9.5H5V8.5ZM7 8.5H8V9.5H7V8.5ZM11 6.5H13V7.5H11V6.5ZM11 8.5H13V9.5H11V8.5ZM9 8.5H10V9.5H9V8.5ZM11.5 10.5H13V11.5H11.5V10.5Z"
      fill={color}
    />
  </svg>
);

export default KeyboardIcon;
