import React from 'react';
import cn from 'classnames';

import './style.scss';

type RoundedImageProps = {
  src: string;
  size?: 'sm' | 'md' | 'lg';
};

export const RoundedImage: FunctionComponent<RoundedImageProps> = ({ src, className, size }) => (
  <div
    className={cn('pri-rounded-image', `pri-size-${size}`, className)}
    style={{ backgroundImage: `url('${src}')` }}
  />
);
