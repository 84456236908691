import React from 'react';

const LocationIcon: SvgComponent = ({ className, width = 24, color = '#1E1E1E' }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 32 32"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 18C15.0111 18 14.0444 17.7068 13.2222 17.1573C12.3999 16.6079 11.759 15.827 11.3806 14.9134C11.0022 13.9998 10.9031 12.9945 11.0961 12.0245C11.289 11.0546 11.7652 10.1637 12.4645 9.46447C13.1637 8.7652 14.0546 8.289 15.0246 8.09607C15.9945 7.90315 16.9998 8.00216 17.9134 8.3806C18.8271 8.75904 19.6079 9.3999 20.1574 10.2221C20.7068 11.0444 21 12.0111 21 13C20.9985 14.3256 20.4712 15.5965 19.5339 16.5339C18.5965 17.4712 17.3256 17.9985 16 18ZM16 10C15.4067 10 14.8266 10.1759 14.3333 10.5056C13.8399 10.8352 13.4554 11.3038 13.2284 11.8519C13.0013 12.4001 12.9419 13.0033 13.0576 13.5853C13.1734 14.1672 13.4591 14.7018 13.8787 15.1213C14.2982 15.5409 14.8328 15.8266 15.4147 15.9424C15.9967 16.0581 16.5999 15.9987 17.1481 15.7716C17.6962 15.5446 18.1648 15.1601 18.4944 14.6667C18.8241 14.1734 19 13.5933 19 13C18.9991 12.2046 18.6828 11.4421 18.1204 10.8796C17.5579 10.3172 16.7954 10.0009 16 10Z"
      fill="black"
    />
    <path
      d="M16 30L7.56451 20.0513C7.51661 19.9942 7.21631 19.5998 7.21631 19.5998C5.77521 17.7017 4.99663 15.3832 5.00001 13C5.00001 10.0826 6.15894 7.28473 8.22184 5.22183C10.2847 3.15893 13.0826 2 16 2C18.9174 2 21.7153 3.15893 23.7782 5.22183C25.8411 7.28473 27 10.0826 27 13C27.0036 15.3822 26.2255 17.6998 24.7852 19.5973L24.7837 19.5998C24.7837 19.5998 24.4837 19.9942 24.439 20.0472L16 30ZM8.81251 18.395C8.81351 18.3957 9.04591 18.7032 9.09911 18.7694L16 26.9079L22.91 18.7579C22.9539 18.7027 23.1883 18.393 23.1888 18.3922C24.3661 16.8413 25.0023 14.9471 25 13C25 10.6131 24.0518 8.32387 22.364 6.63604C20.6761 4.94821 18.387 4 16 4C13.6131 4 11.3239 4.94821 9.63605 6.63604C7.94822 8.32387 7.00001 10.6131 7.00001 13C6.9978 14.9482 7.63449 16.8433 8.81251 18.395Z"
      fill="black"
    />
  </svg>
);

export default LocationIcon;
