import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useForm } from 'react-hook-form';
import { isOk } from '@primo/operation-result';
import type { ModalProps } from '~/components/shared/Modal';
import Modal from '~/components/shared/Modal';
import { Form } from '~/components/shared/forms/Form';
import Select from '~/components/shared/forms/Select';
import useMutationUpdateDeviceTags from '~/hooks/queries/devices/useMutationUpdateDeviceTags';
import useQueryGetDevicesTags from '~/hooks/queries/devices/useQueryGetDevicesTags';
import { useCompany } from '~/hooks/useCompany';

interface UpdateDeviceTagsModalProps extends Pick<ModalProps, 'onClose'> {
  device: {
    id: string;
    tags: string[];
  };
}

const FORM_ID = 'UpdateDeviceTagsModal';

export const UpdateDeviceTagsModal = ({ onClose, device }: UpdateDeviceTagsModalProps) => {
  const { t } = useTranslation();
  const company = useCompany();

  const { updateDeviceTags, updateDeviceTagsLoading } = useMutationUpdateDeviceTags();
  const { devicesTagsResult } = useQueryGetDevicesTags({ companyId: company?.id || '', runOnInit: true });

  const deviceTags = useMemo(() => {
    if (isOk(devicesTagsResult)) return devicesTagsResult.data;
    return [];
  }, [devicesTagsResult]);

  const form = useForm<{ tags: string[] }>({
    defaultValues: {
      tags: device.tags,
    },
  });

  const { control } = form;

  const onSubmit = useCallback(async () => {
    await updateDeviceTags({ deviceId: device.id, tags: form.getValues('tags') });
    onClose?.();
  }, [device.id, form, onClose, updateDeviceTags]);

  return (
    <Modal
      title={t('modals.titles.update_device_tags')}
      onConfirm={onSubmit}
      onClose={onClose}
      footer={{ confirmButton: true, cancelButton: true, confirmButtonLoading: updateDeviceTagsLoading }}
    >
      <Form form={form} noValidate id={FORM_ID}>
        <Select
          options={deviceTags}
          isMulti
          acceptCustomValues
          control={control}
          name="tags"
          placeholder={t('forms.labels.select_or_create_tag')}
          maxOptions={3}
          helperText={t('forms.texts.limited_to', { limit: 3 })}
        />
      </Form>
    </Modal>
  );
};

export default UpdateDeviceTagsModal;
