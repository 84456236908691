import { useCallback, useContext, useMemo } from 'react';
import type { GetCompanyAccessoriesQuery, GetCompanyInventoryQuery } from '~/graphql/schema';
import { useGetCompanyAccessoriesQuery, useGetCompanyInventoryQuery } from '~/graphql/schema';
import { PrimoAdminContext } from '~/providers/primo/PrimoAdminProvider';

const useGetInventory = () => {
  const { company } = useContext(PrimoAdminContext);
  const companyId = company?.id;

  const getCompanyInventory = useCallback((data: GetCompanyInventoryQuery | undefined) => {
    if (data?.getDevices?.__typename === 'QueryGetDevicesSuccess') {
      return data.getDevices.data.list ?? [];
    }
    return [];
  }, []);

  const getCompanyAccessories = useCallback((data: GetCompanyAccessoriesQuery | undefined) => {
    if (data?.getAccessories?.__typename === 'QueryGetAccessoriesSuccess') {
      return data.getAccessories.data.items ?? [];
    }
    return [];
  }, []);

  const {
    data: devicesData,
    loading: devicesLoading,
    called: devicesCalled,
  } = useGetCompanyInventoryQuery({
    variables: { companyId },
    fetchPolicy: 'no-cache',
  });

  const {
    data: accessoriesData,
    loading: accessoriesLoading,
    called: accessoriesCalled,
  } = useGetCompanyAccessoriesQuery({
    variables: { companyId },
    fetchPolicy: 'no-cache',
  });

  const devices = useMemo(() => getCompanyInventory(devicesData), [getCompanyInventory, devicesData]);
  const accessories = useMemo(() => getCompanyAccessories(accessoriesData), [getCompanyAccessories, accessoriesData]);

  return {
    inventory: {
      devices,
      accessories,
    },
    inventoryLoading: devicesLoading || accessoriesLoading,
    inventoryCalled: devicesCalled || accessoriesCalled,
  };
};

export default useGetInventory;
