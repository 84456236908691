import React from 'react';

import Flex from '~/components/shared/shaping/Flex';
import LogoFleet from '~/components/icons/logoFleet';
import LogoTypo from '~/components/icons/logoTypo';
import Logo3PlanesUnformedIcon from '~/components/icons/logo3planesUnformed';

import './index.scss';

const LoginBackground: FunctionComponent = ({ children, className }) => (
  <Flex justify="center" align="center" className="login-page">
    <div id="login-container-wrapper">
      <Logo3PlanesUnformedIcon className="rightFleet" />
      <div id="login-container" className={className}>
        <Flex direction="column">
          <Flex justify="center" className="pri-mb-6">
            <LogoFleet />
            <LogoTypo color="black" />
          </Flex>
          {children}
        </Flex>
      </div>
      <Logo3PlanesUnformedIcon className="leftFleet" />
    </div>
  </Flex>
);

export default LoginBackground;
