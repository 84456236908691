import React, { useMemo } from 'react';
import { ClassPrefix, TextArea, TextInput } from '@carbon/react';
import type { TextInputProps } from '@carbon/react/lib/components/TextInput/TextInput';

import cn from 'classnames';
import type { UseFormRegister } from 'react-hook-form/dist/types/form';
import type { FieldErrors, FieldError } from 'react-hook-form/dist/types/errors';
import type { FieldValues, Path } from 'react-hook-form';

import './index.scss';
import Flex from '~/components/shared/shaping/Flex';
import { Text } from '~/components/shared/typography';

export type InputProps<FV extends FieldValues> = {
  name: string;
  label?: string;
  labelHelper?: string;
  helperText?: TextInputProps['helperText'];
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  defaultValue?: string;
  type?: 'text' | 'password' | 'email' | 'number' | 'textarea';
  className?: string;

  register?: UseFormRegister<FV>;
  errors?: FieldErrors;
  error?: FieldError;
};

const Input = <FV extends FieldValues>(props: InputProps<FV>) => {
  const {
    type = 'text',
    label,
    required,
    register,
    errors,
    name,
    className,
    error,
    labelHelper,
    ...inputProps
  } = props;
  const id = useMemo(() => `input-${Math.random().toFixed(10).slice(2, 10)}`, []);
  const errorField = errors?.[name] ?? error;
  const message = errorField?.message?.toString() || '';

  const computedProps = useMemo(() => {
    const labelText =
      label && labelHelper ? (
        <Flex direction="row" gap={1} align="baseline">
          {label && <Text weight="light">{label}</Text>}
          {label && labelHelper && (
            <Text weight="light" italic size="sm">
              ({labelHelper})
            </Text>
          )}
        </Flex>
      ) : (
        label
      );
    return {
      className: cn(className, 'pri-input-text', { 'pri-input-required': required }),
      type,
      id,
      labelText,
      invalid: !!errorField,
      invalidText: message ?? '',
      ...register?.(name as Path<FV>, { required }),
      ...inputProps, // use inputProps to avoid `Warning: Invalid value for prop `register` on <input> tag.`
    };
  }, [errorField, id, label, message, name, inputProps, register, required, type, className, labelHelper]);

  return (
    <ClassPrefix prefix="carbon">
      {type === 'textarea' ? <TextArea {...computedProps} labelText={label ?? ''} /> : <TextInput {...computedProps} />}
    </ClassPrefix>
  );
};

export default Input;
