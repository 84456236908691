import { LOGIN_ROUTE, ROOT } from '~/config/routes';

const returnToURL = (to: string | null | undefined) => {
  const login = LOGIN_ROUTE();
  const loginRegExp = new RegExp(`^${login}`);
  const url = `${window.location.origin}${LOGIN_ROUTE()}`;

  if (!to || to === ROOT || to?.match(loginRegExp)) {
    return url;
  }

  const searchParams = new URLSearchParams({ to });
  return `${url}?${searchParams.toString()}`;
};

export default returnToURL;
