import type { ComponentProps } from 'react';
import React, { forwardRef, useMemo } from 'react';
import { Form as CarbonForm, FormContext as CarbonFormContext } from '@carbon/react';
import cn from 'classnames';
import type { FieldValues } from 'react-hook-form';
import type { UseFormReturn } from 'react-hook-form/dist/types';
import { FormProvider } from 'react-hook-form';

export type FormProps<FV extends FieldValues> = ComponentProps<'form'> & {
  form: UseFormReturn<FV>;
};

const BaseForm = <FV extends FieldValues>(
  { children, form, id, ...props }: FormProps<FV>,
  ref: React.ForwardedRef<HTMLFormElement>,
) => {
  const idProp = useMemo(() => id ?? `form-${Math.random().toFixed(10).slice(2, 10)}`, [id]);

  return (
    <FormProvider {...form}>
      <CarbonForm ref={ref} id={idProp} className={cn('pri-input-form')} {...props}>
        {children}
      </CarbonForm>
    </FormProvider>
  );
};

export const Form = forwardRef(BaseForm) as <FV extends FieldValues>(
  props: FormProps<FV>,
) => ReturnType<typeof BaseForm>;

export const FormContext = CarbonFormContext;
