import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useLocalStorage, useSearchParam } from 'react-use';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { LOGIN_CALLBACK_ROUTE } from '~/config/routes';

import type { GetCompanyLoginQuery, QueryGetCompanyLoginSuccess } from '~/graphql/schema';
import { useGetCompanyLoginLazyQuery } from '~/graphql/schema';

export type Connection = QueryGetCompanyLoginSuccess['data']['connections'][number];

export type LoginContextProps = {
  organizationId: string | undefined;
  organization: string | undefined;
  connections: Connection[];
  loading: boolean;
  invitation: string | undefined;
  redirectUri: string;
  setOrganization: (organization: string) => void;
  removeOrganization: () => void;
};

export const LoginContext = createContext<LoginContextProps>({
  organizationId: undefined,
  organization: undefined,
  connections: [],
  loading: false,
  invitation: undefined,
  redirectUri: `${window.location.origin}${LOGIN_CALLBACK_ROUTE()}`,
  setOrganization: () => undefined,
  removeOrganization: () => undefined,
});

const getCompanyLogin = (data: GetCompanyLoginQuery | undefined) => {
  if (data?.getCompanyLogin?.__typename === 'QueryGetCompanyLoginSuccess') {
    return { companyLogin: data.getCompanyLogin, error: null };
  }
  if (data?.getCompanyLogin?.__typename === 'QueryGetCompanyLoginError') {
    return { companyLogin: null, error: data.getCompanyLogin.reasons.join('; ') };
  }
  return { companyLogin: null, error: null };
};

export const LoginProvider: FunctionComponent = ({ children }) => {
  const { t } = useTranslation();
  const [, setSearchParams] = useSearchParams();
  const invitationOrganizationId = useSearchParam('organization');
  const invitationParam = useSearchParam('invitation');
  const [invitation, setInvitation] = useState(invitationParam);
  const [organization, setOrganization, removeOrganization] = useLocalStorage<string>('organization');

  const [getCompanyLoginQuery, { data, loading, error: serverError, called }] = useGetCompanyLoginLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const { companyLogin, error: queryError } = useMemo(() => getCompanyLogin(data), [data]);

  useEffect(() => {
    if ((!companyLogin || companyLogin.data === null || queryError) && called && !loading) {
      removeOrganization();
      toast.error(<>{t('errors.unknown_company')}</>);
    }
    if (serverError) {
      toast.error(<>{t('errors.internal_server_error')}</>);
    }
  }, [companyLogin, serverError, queryError, called, removeOrganization, loading, t]);

  const extendedRemoveOrganization = useCallback(() => {
    removeOrganization();
    setInvitation(null);
    setSearchParams(new URLSearchParams(), { replace: true });
  }, [removeOrganization, setSearchParams]);

  const value = useMemo(
    () => ({
      organizationId: organization && companyLogin?.data?.id,
      organization: organization && companyLogin?.data?.name,
      connections: (organization && companyLogin?.data?.connections) || [],
      loading: (!called && !!organization) || loading,
      invitation: invitation ?? undefined,
      redirectUri: `${window.location.origin}${LOGIN_CALLBACK_ROUTE()}`,
      setOrganization,
      removeOrganization: extendedRemoveOrganization,
    }),
    [organization, companyLogin, called, loading, invitation, setOrganization, extendedRemoveOrganization],
  );

  useEffect(() => {
    if (invitationOrganizationId) {
      setOrganization(invitationOrganizationId);
    }
  }, [invitationOrganizationId, setOrganization]);

  useEffect(() => {
    if (organization) {
      getCompanyLoginQuery({ variables: { search: organization } });
    }
  }, [organization, getCompanyLoginQuery]);

  return <LoginContext.Provider value={value}>{children}</LoginContext.Provider>;
};
