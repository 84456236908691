import React, { lazy, Suspense, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import analytics from '~/analytics';
import {
  EMPLOYEE_SESSION_DEVICE_RAWER_ROUTE,
  EMPLOYEE_SESSION_EQUIPMENT_ROUTE,
  EMPLOYEE_SESSION_LOGIN_CALLBACK_ROUTE,
  EMPLOYEE_SESSION_LOGIN_ROUTE,
} from '~/config/routes';
import EmployeeAuthenticatedGuard from '~/features/guards/EmployeeAuthenticatedGuard';
import PrimoEmployeeLayout from '~/features/layout/PrimoEmployeeLayout';
import EmployeeLoginCallbackPage from '~/pages/Callback/EmployeeLoginCallback';
import EmployeeLoginPage from '~/pages/EmployeeLogin';
import { EmployeeSteppersProvider } from '~/providers/steppers/EmployeeStepperProvider';

const EmployeeEquipmentPage = lazy(() => import('~/pages/EmployeeSession/EmployeeEquipment'));
const EmployeeDeviceDrawer = lazy(() => import('~/features/drawers/EmployeeDeviceDrawer'));

export const EmployeeRouter = () => {
  const location = useLocation();

  useEffect(() => {
    analytics?.page();
  }, [location]);

  return (
    <Routes>
      <Route path={`${EMPLOYEE_SESSION_LOGIN_CALLBACK_ROUTE()}/*`} element={<EmployeeLoginCallbackPage />} />
      <Route path={`${EMPLOYEE_SESSION_LOGIN_ROUTE()}/*`} element={<EmployeeLoginPage />} />
      <Route
        path="/*"
        element={
          <EmployeeAuthenticatedGuard>
            <EmployeeSteppersProvider>
              <PrimoEmployeeLayout>
                <Suspense fallback={null}>
                  <Routes>
                    <Route index element={<Navigate to={EMPLOYEE_SESSION_EQUIPMENT_ROUTE()} />} />
                    <Route path={EMPLOYEE_SESSION_EQUIPMENT_ROUTE()} element={<EmployeeEquipmentPage />}>
                      <Route path={EMPLOYEE_SESSION_DEVICE_RAWER_ROUTE()} element={<EmployeeDeviceDrawer />} />
                    </Route>
                  </Routes>
                </Suspense>
              </PrimoEmployeeLayout>
            </EmployeeSteppersProvider>
          </EmployeeAuthenticatedGuard>
        }
      />
    </Routes>
  );
};
