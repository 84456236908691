import React from 'react';

const ResellIcon: SvgComponent = ({ className, width = 24, color = 'black' }) => (
  <svg className={className} width={width} height={width} viewBox="0 0 44 44">
    <path
      d="M17.125 33.375C18.0225 33.375 18.75 32.6475 18.75 31.75C18.75 30.8525 18.0225 30.125 17.125 30.125C16.2275 30.125 15.5 30.8525 15.5 31.75C15.5 32.6475 16.2275 33.375 17.125 33.375Z"
      fill={color}
    />
    <path
      d="M28.5 33.375C29.3975 33.375 30.125 32.6475 30.125 31.75C30.125 30.8525 29.3975 30.125 28.5 30.125C27.6025 30.125 26.875 30.8525 26.875 31.75C26.875 32.6475 27.6025 33.375 28.5 33.375Z"
      fill={color}
    />
    <path
      d="M13.0467 11.2782C13.0099 11.094 12.9104 10.9282 12.7651 10.8092C12.6199 10.6901 12.4378 10.625 12.25 10.625H9V12.25H11.5838L14.7033 27.8468C14.7401 28.031 14.8396 28.1968 14.9849 28.3158C15.1301 28.4349 15.3122 28.5 15.5 28.5H30.125V26.875H16.1662L15.5163 23.625H30.125C30.3099 23.625 30.4892 23.562 30.6334 23.4463C30.7776 23.3306 30.878 23.1693 30.9182 22.9888L32.7612 14.6875H31.0976L29.4735 22H15.1912L13.0467 11.2782Z"
      fill={color}
    />
    <path
      d="M26.5386 16.6489L23.625 13.7352V20.375H22V13.7352L19.0864 16.6489L17.9375 15.5L22.8125 10.625L27.6875 15.5L26.5386 16.6489Z"
      fill={color}
    />
  </svg>
);

export default ResellIcon;
