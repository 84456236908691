import React from 'react';

const ArrowUpRightIcon: SvgComponent = ({ className, width = 16, color = '#1E0918' }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5 3V4H11.295L3 12.295L3.705 13L12 4.705V11H13V3H5Z" fill={color} />
  </svg>
);

export default ArrowUpRightIcon;
