import React from 'react';
import { OrderStepperProvider } from './context';
import OrderStepperContent from './stepper';
import { Stepper } from '~/components/shared/Stepper';

export type OrderStepperProps = {
  onClose: () => void;
  employeeId?: string | null;
};

const OrderStepper: FunctionComponent<OrderStepperProps> = ({ onClose, employeeId }) => (
  <Stepper onClose={onClose}>
    <OrderStepperProvider employeeId={employeeId}>
      <OrderStepperContent />
    </OrderStepperProvider>
  </Stepper>
);

export default OrderStepper;
