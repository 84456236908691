import { useCallback, useEffect, useMemo } from 'react';
import { ok, refuse } from '@primo/operation-result';
import type { CheckIfEmployeePersonalEmailExistQuery } from '~/graphql/schema';
import { useCheckIfEmployeePersonalEmailExistLazyQuery } from '~/graphql/schema';
import type { UseQueryOptions } from '~/hooks/queries/types';

type UseCheckExistingPersonalEmailVariables = {
  personalEmail: string;
  companyId: string;
};

const useQueryCheckExistingPersonalEmail = (
  queryOptions: UseQueryOptions<UseCheckExistingPersonalEmailVariables> = {},
) => {
  const serializeCheckEmailData = useCallback((data: CheckIfEmployeePersonalEmailExistQuery | undefined) => {
    if (data?.doesEmployeePersonalEmailExist?.__typename === 'QueryDoesEmployeePersonalEmailExistSuccess') {
      return ok(data.doesEmployeePersonalEmailExist.data);
    }
    if (data?.doesEmployeePersonalEmailExist?.__typename === 'QueryDoesEmployeePersonalEmailExistError')
      return refuse(data.doesEmployeePersonalEmailExist.reasons);
    return refuse(['Unknown error']);
  }, []);

  const [query, { data: employeeData, loading: existingPersonalEmailLoading, called: existingPersonalEmailCalled }] =
    useCheckIfEmployeePersonalEmailExistLazyQuery();

  const existingPersonalEmailResult = useMemo(
    () => serializeCheckEmailData(employeeData),
    [serializeCheckEmailData, employeeData],
  );

  const checkExistingPersonalEmail = useCallback(
    async (variables: UseCheckExistingPersonalEmailVariables) => {
      const result = await query({ variables });
      return serializeCheckEmailData(result.data);
    },
    [query, serializeCheckEmailData],
  );

  useEffect(() => {
    if (queryOptions.runOnInit && !existingPersonalEmailCalled)
      checkExistingPersonalEmail({ personalEmail: queryOptions.personalEmail, companyId: queryOptions.companyId });
  }, [checkExistingPersonalEmail, queryOptions, existingPersonalEmailCalled]);

  return useMemo(
    () => ({
      checkExistingPersonalEmail,
      existingPersonalEmailResult,
      existingPersonalEmailLoading,
      existingPersonalEmailCalled,
    }),
    [
      checkExistingPersonalEmail,
      existingPersonalEmailResult,
      existingPersonalEmailLoading,
      existingPersonalEmailCalled,
    ],
  );
};

export default useQueryCheckExistingPersonalEmail;
