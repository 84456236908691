import React, { createContext, useCallback, useMemo, useState } from 'react';
import { useBoolean } from 'react-use';

import type { EmployeeEnrollmentStepperProps } from '~/features/steppers/EmployeeEnrollmentStepper';
import EmployeeEnrollmentStepper from '~/features/steppers/EmployeeEnrollmentStepper';

type EmployeeEnrollmentStepperPartialProps = Omit<EmployeeEnrollmentStepperProps, 'onClose'>;

export type EmployeeSteppersContextProps = {
  openEmployeeEnrollmentStepper: (props: EmployeeEnrollmentStepperPartialProps) => void;
};

export const EmployeeSteppersContext = createContext<EmployeeSteppersContextProps>({
  openEmployeeEnrollmentStepper: () => {},
});

export const EmployeeSteppersProvider: FunctionComponent = ({ children }) => {
  const [showEmployeeEnrollmentStepper, setShowEmployeeEnrollmentStepper] = useBoolean(false);
  const [employeeEnrollmentStepperProps, setEmployeeEnrollmentStepperProps] =
    useState<EmployeeEnrollmentStepperPartialProps>({});

  const openEmployeeEnrollmentStepper = useCallback(
    (props: EmployeeEnrollmentStepperPartialProps) => {
      setEmployeeEnrollmentStepperProps(props);
      setShowEmployeeEnrollmentStepper(true);
    },
    [setShowEmployeeEnrollmentStepper],
  );
  const value = useMemo(() => ({ openEmployeeEnrollmentStepper }), [openEmployeeEnrollmentStepper]);

  return (
    <EmployeeSteppersContext.Provider value={value}>
      {children}
      {showEmployeeEnrollmentStepper && (
        <EmployeeEnrollmentStepper
          {...employeeEnrollmentStepperProps}
          onClose={() => {
            setShowEmployeeEnrollmentStepper(false);
            setEmployeeEnrollmentStepperProps({});
          }}
        />
      )}
    </EmployeeSteppersContext.Provider>
  );
};
