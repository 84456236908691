import React from 'react';

const PenIcon: SvgComponent = ({ className, width = 24, color = '#1E1E1E' }) => (
  <svg className={className} width={width} height={width} viewBox="0 0 20 20" fill="none">
    <path
      d="M17.0668 3.81681L18.75 2.13381L17.8662 1.25L16.1832 2.93306L15.5 2.25C15.3872 2.13146 15.2514 2.03708 15.101 1.97259C14.9506 1.9081 14.7887 1.87485 14.625 1.87485C14.4613 1.87485 14.2994 1.9081 14.149 1.97259C13.9986 2.03708 13.8628 2.13146 13.75 2.25L2.5 13.5V17.5H6.5L17.75 6.25C17.8685 6.13716 17.9629 6.00142 18.0274 5.85101C18.0919 5.7006 18.1251 5.53865 18.1251 5.375C18.1251 5.21135 18.0919 5.0494 18.0274 4.89899C17.9629 4.74858 17.8685 4.61284 17.75 4.5L17.0668 3.81681ZM6 16.25H3.75V14L14.625 3.125L16.875 5.375L6 16.25Z"
      fill={color}
    />
    <path d="M5.625 7.24097L10.3662 2.49982L11.25 3.3837L6.50888 8.12485L5.625 7.24097Z" fill={color} />
  </svg>
);

export default PenIcon;
