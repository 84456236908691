import translations from '@primo/locales';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { PRODUCTION } from './env';

const lng = localStorage.getItem('language');

i18n.use(initReactI18next).init({
  lng: lng ? JSON.parse(lng) : 'en',
  fallbackLng: 'en',
  debug: !PRODUCTION,
  resources: {
    en: { translation: translations.en },
    fr: { translation: translations.fr },
  },
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;

/**
 * When CountrySelector will be enabled :
 *
 * add `import LanguageDetector from 'i18next-browser-languagedetector';`
 * add `.use(LanguageDetector)` to `i18n`
 */
