import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage, useSearchParam } from 'react-use';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { isRefusal } from '@primo/operation-result';
import { ROOT, SIGNUP_CALLBACK_ROUTE, SIGNUP_ROUTE } from '~/config/routes';
import { PrimoAdminContext } from '~/providers/primo/PrimoAdminProvider';
import useMutationCreateFirstAdminInCompany from '~/hooks/queries/employees/useMutationCreateFirstAdminInCompany';
import type { PartialEmployeeSignupInput } from '~/pages/Signup/context';
import Logo3PlanesUnformedIcon from '~/components/icons/logo3planesUnformed';
import Flex from '~/components/shared/shaping/Flex';
import LogoFleet from '~/components/icons/logoFleet';
import LogoTypo from '~/components/icons/logoTypo';

import '~/pages/Signup/index.scss';
import Loader from '~/components/shared/Loader';

const SignupCallbackPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const to = useSearchParam('to');
  const [hash] = useState(window.location.hash);
  const { isAuthenticated, webAuthForSignup, setAccessToken, isLoading } = useContext(PrimoAdminContext);
  const [organization] = useLocalStorage<string>('organization');
  const [companyId, setCompanyId, removeCompanyId] = useLocalStorage<string>('companyId');
  const [employeeData] = useLocalStorage<PartialEmployeeSignupInput>('employeeData');
  const { createFirstAdminInCompany, createFirstAdminInCompanyLoading, createFirstAdminInCompanyCalled } =
    useMutationCreateFirstAdminInCompany();
  const { jobTitle } = employeeData ?? {};

  useEffect(() => {
    if (isAuthenticated) {
      navigate(to || ROOT, { replace: true });
    }
  }, [isAuthenticated, to, navigate]);

  useEffect(() => {
    if (isAuthenticated || isLoading || createFirstAdminInCompanyCalled || createFirstAdminInCompanyLoading) return;

    if (hash.match(/^#access_token/)) {
      const params = new URLSearchParams(hash.slice(1));
      const accessToken = params.get('access_token');
      if (!accessToken) {
        navigate(SIGNUP_ROUTE());
        toast.error(<span>{t(`errors.refusals.create_first_admin_in_company.generic`)}</span>);
        return;
      }

      const areSignupRequiredFieldsValid = !!jobTitle && !!companyId && !!organization;
      if (!areSignupRequiredFieldsValid) {
        navigate(SIGNUP_ROUTE());
        toast.error(<span>{t(`errors.refusals.create_first_admin_in_company.generic`)}</span>);
        return;
      }

      // Get user info from auth0
      webAuthForSignup.client.userInfo(accessToken, async (err, authResult) => {
        if (err) {
          navigate(SIGNUP_ROUTE());
          toast.error(<span>{t(`errors.refusals.create_first_admin_in_company.generic`)}</span>);
          return;
        }

        const { email, sub: auth0Id, given_name: firstName, family_name: lastName } = authResult;
        const areEmployeeRequiredFieldsValid =
          !!email && !!firstName && !!lastName && !!organization && !!companyId && !!jobTitle;
        if (!areEmployeeRequiredFieldsValid) {
          navigate(SIGNUP_ROUTE());
          toast.error(<span>{t(`errors.refusals.create_first_admin_in_company.generic`)}</span>);
          return;
        }

        const result = await createFirstAdminInCompany({
          auth0Id,
          companyId,
          employeeData: { firstName, lastName, jobTitle, email },
        });
        if (isRefusal(result)) {
          navigate(SIGNUP_ROUTE());
          result.reason.forEach(reason => {
            toast.error(<span>{t(`errors.refusals.create_first_admin_in_company.${reason.toLowerCase()}`)}</span>);
          });
          return;
        }

        // as the auth0 user has been updated by createFirstAdminInCompany, we have to refresh the token with
        // the new information to authenticate him
        webAuthForSignup.checkSession(
          { scope: 'openid email profile', redirectUri: `${window.location.origin}${SIGNUP_CALLBACK_ROUTE()}` },
          (error, auth0SessionResult) => {
            if (error) {
              navigate(SIGNUP_ROUTE());
              toast.error(<span>{t(`errors.refusals.create_first_admin_in_company.generic`)}</span>);
              return;
            }

            setAccessToken(auth0SessionResult.accessToken);
            removeCompanyId();
          },
        );
      });
    }
  }, [
    t,
    hash,
    navigate,
    jobTitle,
    companyId,
    setAccessToken,
    setCompanyId,
    organization,
    isAuthenticated,
    webAuthForSignup,
    createFirstAdminInCompany,
    removeCompanyId,
    createFirstAdminInCompanyLoading,
    createFirstAdminInCompanyCalled,
    isLoading,
  ]);

  return (
    <Flex justify="center" align="center" className="signup-page">
      <div id="signup-container-wrapper">
        <Logo3PlanesUnformedIcon className="rightFleet" />
        <div id="signup-container">
          <Flex direction="column">
            <Flex justify="center" className="pri-mb-11">
              <LogoFleet />
              <LogoTypo color="black" />
            </Flex>
            <Flex fullHeight fullWidth justify="center" align="center">
              <Loader />
            </Flex>
          </Flex>
        </div>
        <Logo3PlanesUnformedIcon className="leftFleet" />
      </div>
    </Flex>
  );
};

export default SignupCallbackPage;
