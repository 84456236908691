import React, { createContext, useMemo, useState } from 'react';
import type { AxiosInstance } from 'axios';
import axios from 'axios';

export type RestExpressAppContextProps = {
  axiosInstance: AxiosInstance | null;
  setToken: (token: string | null) => void;
  hasAuthenticatedClient: boolean;
};

export const RestAppContext = createContext<RestExpressAppContextProps>({
  axiosInstance: null,
  setToken: () => undefined,
  hasAuthenticatedClient: false,
});

const RestAppProvider: FunctionComponent = ({ children }) => {
  const [token, setToken] = useState<string | null>(null);

  const axiosInstance = useMemo(() => {
    if (token) {
      return axios.create({
        baseURL: '/rest',
        headers: { authorization: `Bearer ${token}` },
      });
    }
    return null;
  }, [token]);

  const value = useMemo(
    () => ({
      setToken,
      hasAuthenticatedClient: !!token,
      axiosInstance,
    }),
    [axiosInstance, token, setToken],
  );

  return <RestAppContext.Provider value={value}>{children}</RestAppContext.Provider>;
};

export default RestAppProvider;
