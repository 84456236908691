import React from 'react';

import Pill from '~/components/shared/Pill';
import Flex from '~/components/shared/shaping/Flex';
import AddTagPill from '~/components/featured/pills/devicePills/AddTagPill';
import type { PillProps } from '~/components/shared/Pill';
import type { Device } from '~/graphql/schema';

export interface DeviceTagsPillsProps {
  device: Pick<Device, 'id' | 'tags'>;
  displayAddTag?: boolean;
  pillsSize?: PillProps['size'];
}

export const DeviceTagsPills: FunctionComponent<DeviceTagsPillsProps> = ({ device, displayAddTag, pillsSize }) => (
  <Flex gap={2} align="center" wrap="nowrap">
    <Flex gap={2} wrap="wrap">
      {[...device.tags]
        .sort((a, b) => a.localeCompare(b))
        .map(tag => (
          <Pill color="grey" key={tag} size={pillsSize}>
            {tag}
          </Pill>
        ))}
    </Flex>
    {displayAddTag && <AddTagPill device={device} />}
  </Flex>
);

export default DeviceTagsPills;
