import React from 'react';
import classNames from 'classnames';
import { ClassPrefix, ComposedModal } from '@carbon/react';

export const ModalRaw: FunctionComponent = ({ className, children }) => (
  <ClassPrefix prefix="carbon">
    <ComposedModal open className={classNames(className, 'pri-modal')} preventCloseOnClickOutside>
      {children}
    </ComposedModal>
  </ClassPrefix>
);
