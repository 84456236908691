import * as zod from 'zod';
import { DevicePlatform, DeviceType } from '~/graphql/schema';

export const createDeviceForEnrollmentSchema = zod.object({
  name: zod.string().min(1),
  type: zod.nativeEnum(DeviceType),
  platform: zod.nativeEnum(DevicePlatform),
  serialNumber: zod.string().min(1).optional(),
  ownerId: zod.string().min(1),
});

export const updateDeviceForEnrollmentSchema = zod.object({
  platform: zod.nativeEnum(DevicePlatform).optional(),
  name: zod.string().min(1).optional(),
  serialNumber: zod.string().min(1).optional(),
});

export type UpdateDeviceForEnrollmentProps = zod.infer<typeof updateDeviceForEnrollmentSchema>;
export type CreateDeviceForEnrollmentProps = zod.infer<typeof createDeviceForEnrollmentSchema>;
