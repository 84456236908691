import React from 'react';

const FilterIcon: SvgComponent = ({ className, width = 24, color = '#1E1E1E' }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30 8H25.9C25.4 5.7 23.4 4 21 4C18.6 4 16.6 5.7 16.1 8H2V10H16.1C16.6 12.3 18.6 14 21 14C23.4 14 25.4 12.3 25.9 10H30V8ZM21 12C19.3 12 18 10.7 18 9C18 7.3 19.3 6 21 6C22.7 6 24 7.3 24 9C24 10.7 22.7 12 21 12ZM2 24H6.1C6.6 26.3 8.6 28 11 28C13.4 28 15.4 26.3 15.9 24H30V22H15.9C15.4 19.7 13.4 18 11 18C8.6 18 6.6 19.7 6.1 22H2V24ZM11 20C12.7 20 14 21.3 14 23C14 24.7 12.7 26 11 26C9.3 26 8 24.7 8 23C8 21.3 9.3 20 11 20Z"
      fill={color}
    />
  </svg>
);

export default FilterIcon;
