import { toast } from 'react-toastify';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isRefusal } from '@primo/operation-result';
import { CompanyLoginConnectionStrategy } from '~/graphql/schema';
import { PrimoAdminContext } from '~/providers/primo/PrimoAdminProvider';
import { SignupContext } from '~/pages/Signup/context';
import useMutationCreateFirstAdminInCompany from '~/hooks/queries/employees/useMutationCreateFirstAdminInCompany';

interface UseSignupWithPasswordProps {
  email: string | null;
  password: string | null;
  setIsLoading: (isLoading: boolean) => void;
}

const useSignupWithPasswordCallback = ({ email, password, setIsLoading }: UseSignupWithPasswordProps) => {
  const { t } = useTranslation();
  const { webAuthForSignup, webAuth } = useContext(PrimoAdminContext);
  const { companyId, employeeData, connections, organization, redirectToLogin, removeCompanyId } =
    useContext(SignupContext);
  const { createFirstAdminInCompany, createFirstAdminInCompanyLoading } = useMutationCreateFirstAdminInCompany();
  const currentConnection = useMemo(
    () => connections.find(connection => connection.strategy === CompanyLoginConnectionStrategy.Auth0),
    [connections],
  );

  useEffect(() => {
    setIsLoading(createFirstAdminInCompanyLoading);
  }, [createFirstAdminInCompanyLoading, setIsLoading]);

  return useCallback(async () => {
    const connectionProvider = currentConnection?.realm;

    const areSignupRequiredFieldsValid =
      !!email && !!password && !!employeeData && !!companyId && !!organization && !!connectionProvider;

    if (!areSignupRequiredFieldsValid) {
      return toast.error(<span>{t(`errors.refusals.create_first_admin_in_company.generic`)}</span>);
    }

    webAuthForSignup.signup(
      {
        email,
        password,
        connection: connectionProvider,
      },
      async (err, res) => {
        if (err) {
          toast.error(
            <span>
              {t(`errors.refusa
          ls.create_first_admin_in_company.generic`)}
            </span>,
          );
          return;
        }

        const auth0Id = res?.Id;
        const result = await createFirstAdminInCompany({
          auth0Id: `auth0|${auth0Id}`,
          companyId,
          employeeData: { ...employeeData, email },
        });
        if (isRefusal(result)) {
          result.reason.forEach(reason => {
            toast.error(<span>{t(`errors.refusals.create_first_admin_in_company.${reason.toLowerCase()}`)}</span>);
          });
          return;
        }

        webAuth.login(
          {
            password,
            username: email,
            realm: connectionProvider,
            redirectUri: redirectToLogin,
            organization,
          },
          error => {
            if (error) toast.error(<span>{t(`errors.login.${error.code}`)}</span>);
            removeCompanyId();
          },
        );
      },
    );
    return null;
  }, [
    t,
    email,
    password,
    employeeData,
    companyId,
    currentConnection,
    createFirstAdminInCompany,
    organization,
    webAuthForSignup,
    webAuth,
    redirectToLogin,
    removeCompanyId,
  ]);
};

export default useSignupWithPasswordCallback;
