import React from 'react';

const BuildingIcon: SvgComponent = ({ className, width = 24 }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 1.5H12C11.6023 1.5004 11.221 1.65856 10.9398 1.93978C10.6586 2.221 10.5004 2.6023 10.5 3V10.5H3C2.6023 10.5004 2.221 10.6586 1.93978 10.9398C1.65856 11.221 1.5004 11.6023 1.5 12V22.5H22.5V3C22.4995 2.60232 22.3414 2.22105 22.0602 1.93984C21.7789 1.65864 21.3977 1.50046 21 1.5ZM6.75 21V15.75H9.75V21H6.75ZM21 21H11.25V15C11.25 14.8011 11.171 14.6103 11.0303 14.4697C10.8897 14.329 10.6989 14.25 10.5 14.25H6C5.80109 14.25 5.61032 14.329 5.46967 14.4697C5.32902 14.6103 5.25 14.8011 5.25 15V21H3V12H12V3H21V21Z"
      fill="#094067"
    />
    <path
      d="M13.5 6H15V7.5H13.5V6ZM18 6H19.5V7.5H18V6ZM13.5 10.5H15V12H13.5V10.5ZM18 10.5H19.5V12H18V10.5ZM13.5 15H15V16.5H13.5V15ZM18 15H19.5V16.5H18V15Z"
      fill="#094067"
    />
  </svg>
);

export default BuildingIcon;
