import { useForm } from 'react-hook-form';
import { useContext } from 'react';
import { OrderStepperContext } from '~/features/steppers/OrderStepper/context';

export default () => {
  const { contextData } = useContext(OrderStepperContext);

  return useForm({
    defaultValues: {
      from: 'catalog',
      selection: {
        ...(contextData.equipments || []).reduce((acc, { item, quantity }) => ({ ...acc, [item.id]: quantity }), {}),
      },
    },
  });
};
