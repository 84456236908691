import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Title } from '~/components/shared/typography';

import { OnboardingStepperContext } from '../context';

const TitleRecap: FunctionComponent = () => {
  const { t } = useTranslation();
  const {
    contextData: { employee },
  } = useContext(OnboardingStepperContext);

  return <Title level={2}>{t('titles.employee_onboarding', { firstName: employee?.firstName ?? '' })}</Title>;
};

export default TitleRecap;
