import type { ReactElement } from 'react';
import React from 'react';
import cn from 'classnames';
import Flex from '~/components/shared/shaping/Flex';
import type { StepperStepsProps } from './StepperSteps';
import StepperFooter from '~/components/shared/Stepper/_components/StepperFooter';

export interface StepperProps {
  children: ReactElement<StepperStepsProps> | [ReactElement<StepperStepsProps>, ReactElement | undefined];
}

const Stepper: React.FC<StepperProps> = ({ children }) => (
  <Flex className={cn('pri-stepper')} direction="column">
    <Flex className="pri-stepper-container">{children}</Flex>
    <StepperFooter />
  </Flex>
);

export default Stepper;
