import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import Flex from '~/components/shared/shaping/Flex';
import Bloc from '~/components/shared/shaping/Bloc';
import LogoFleetIcon from '~/components/icons/logoFleet';
import LogoTypoIcon from '~/components/icons/logoTypo';
import { EMPLOYEE_SESSION_EQUIPMENT_LINK } from '~/config/routes';
import CountrySelector from '~/components/shared/forms/CountrySelector';
import Button from '~/components/shared/buttons/Button';
import { Nav, NavItem } from '../_parts';
import Filler from '~/components/shared/shaping/Filler';
import DeviceIcon from '~/components/icons/deviceIcon';
import { PrimoEmployeeContext } from '~/providers/primo/PrimoEmployeeProvider';

export const EmployeeNavPanel: FunctionComponent = () => {
  const { logout } = useContext(PrimoEmployeeContext);

  const { slug } = useParams();
  const { t } = useTranslation();

  return (
    <Bloc className="notranslate" style={{ minWidth: 240, maxWidth: 240, height: '100vh' }} backgroundColor="crow">
      <Flex className="pri-p-6 pri-p-t-3" direction="column" fullHeight fullWidth>
        <Link to={EMPLOYEE_SESSION_EQUIPMENT_LINK(slug || '')}>
          <Flex direction="row" fullWidth>
            <LogoFleetIcon width={32} />
            <LogoTypoIcon width={90} className="pri-ml-1" />
          </Flex>
        </Link>
        <Flex direction="column" fullHeight>
          <Nav className="pri-px-4">
            <Flex className="pri-py-9" direction="column" gap={6}>
              <NavItem
                icon={<DeviceIcon width={16} />}
                label={t('menus.employee_session.equipment')}
                to={EMPLOYEE_SESSION_EQUIPMENT_LINK(slug || '')}
              />
            </Flex>
            <Filler />
          </Nav>
        </Flex>
        <Flex direction="column" align="start" fullWidth gap={3}>
          <CountrySelector small dark />
          <Button className="pri-px-3" variant="light-outline" size="md" onClick={() => logout()}>
            {t('buttons.logout')}
          </Button>
        </Flex>
      </Flex>
    </Bloc>
  );
};
