import { useCloseOnboardingIfPossibleMutation } from '~/graphql/schema';

const useCloseOnboardingIfPossible = () => {
  const [closeOnboardingIfPossible, { loading: closeOnboardingIfPossibleLoading }] =
    useCloseOnboardingIfPossibleMutation({
      fetchPolicy: 'no-cache',
      refetchQueries: [
        'ListOnboardingsPage',
        'GetViewEmployees',
        'ListEmployeesPage',
        'GetPendingOnboardings',
        'ListOrdersPage',
      ],
    });

  return { closeOnboardingIfPossible, closeOnboardingIfPossibleLoading };
};

export default useCloseOnboardingIfPossible;
